import React from "react";
import Button from "../Inputs/Button";
import MaxContent from "../../layout/MaxContent";

interface StrapiData {
  data: {
    Label: string;
    Url: string;
    Secondary: boolean;
    idButton?: string;
    NewTab?: boolean;
    NoFollow?: boolean;
  };
}

const ButtonBasic: React.FC<StrapiData> = ({ data }) => {

  return (
    <MaxContent size="content">
      <div className="max-w-[325px]">
        <Button
          label={data.Label}
          href={data.Url}
          secondary={data.Secondary}
          id={data.idButton ? data.idButton : "cta-button"}
          noFollow={data.NoFollow}
          newTab={data.NewTab}
        />
      </div>
    </MaxContent>
  );
};

export default ButtonBasic;
