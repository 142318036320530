import React from "react";
import MaxContent from "../../layout/MaxContent";

interface StrapiData {
  data: {
    Html: string;
  };
}

const Html: React.FC<StrapiData> = ({ data }) => {
  return (
    <MaxContent size="content">
      <div className="font-DM text-body-md text-placeholder">
        <div dangerouslySetInnerHTML={{ __html: data.Html! }} />
      </div>
    </MaxContent>
  );
};

export default Html;
