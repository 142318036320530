import React from "react";
const BigStar =
  "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/TrustPilot/TrustPilotBigStar.svg";
const Star =
  "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/TrustPilot/TrustPilotStar.svg";

const TrustPilotStars = ({ notShowImg }: any) => {
  return (
    <div className="flex items-center">
      {[0, 0, 0, 0, 0].map((_, index) => (
        <div
          key={`star-${index}`}
          className="relative w-[13px] h-[13px]  mr-[1px] bg-[#00B878] flex items-center justify-center"
        >
          <img
            src={Star}
            className={"relative left-[1px] block"}
            alt={"Trust pilot logo"}
          />
        </div>
      ))}

      {!notShowImg && (
        <>
          <img
            src={BigStar}
            className={"ml-[8px] relative  h-[20px] w-[20px]"}
            alt={"Trust pilot logo"}
          />
          <p className="font-MW ml-1 text-[15px] leading-[30px]">Trustpilot</p>
        </>
      )}
      {/* <img src={Text} className={"w-[70.15px] h-[15.29px] mt-2"} alt={"Trust pilot logo"} /> */}
    </div>
  );
};

export default TrustPilotStars;
