import React from "react";
import { getCookie, getReferrerUrl, setCookie } from "../Hooks";
import { ButtonDS } from "get-life-storybook-ts";
import { IconNameT } from "get-life-storybook-ts/lib/components/Icons/GlobalIcon";

interface ButtonInterface {
  label: string;
  href: string;
  secondary?: boolean;
  newTab?: boolean;
  noFollow?: boolean;
  className?: string;
  wrapperClassname?: string;
  id?: string;
  rightIcon?: IconNameT;
  leftIcon?: IconNameT;
  ghost?: boolean;
  size?: "50" | "40" | "32";
}

const Button: React.FC<ButtonInterface> = ({
  label,
  href,
  secondary,
  noFollow,
  newTab,
  className,
  wrapperClassname,
  id,
  rightIcon,
  leftIcon,
  ghost = false,
  size
}) => {
  const recoverParam = (
    query: URLSearchParams,
    name: string,
    expires?: Date
  ) => {
    let value = query.get(name);
    setCookie(name, value !== "null" && value !== null ? value : null, expires);
  };

  const saveNewEntryPage = () => setCookie("entryPage", window.location.href);

  const saveNewReferrerUrl = () => {
    // If an anchor link has an href with the full url (https://life5.es/calcular-seguro-vida/)
    // instead of a pathname (/calcular-seguro-vida/) don't save it as a referrer url
    if(document.referrer.includes(process.env.GATSBY_SITE_URL)) return;
    setCookie("referrerUrl", document.referrer);
  }
  const DOMAIN = process.env.GATSBY_APP_LOCALE;

  React.useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      let utm = [
        // "gtf_campaign",
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "tid",
        "utm_content",
        "utm_term",
        "utm_test",
        "gclid",
        "fbclid",
        "externalReference"
      ];

      const entryPage = getCookie("entryPage");
      const referrerUrl = getReferrerUrl();

      if (entryPage === null) saveNewEntryPage();

      if (referrerUrl === null) saveNewReferrerUrl();

      const newQuery = new URLSearchParams(window.location.search);

      const QueryStringUTMs = Object.fromEntries(newQuery.entries());
      const hasNewUTMs = utm.some((utm) => QueryStringUTMs.hasOwnProperty(utm));

      const threeMonthsDate = new Date(
        new Date().setMonth(new Date().getMonth() + 3)
      );
      const utmsToBeKeeped = ["gclid", "fbclid"];

      if (hasNewUTMs) {
        utm.forEach((value: string) => {
          if (utmsToBeKeeped.includes(value)) {
            if (newQuery.get(value)) {
              return recoverParam(newQuery, value, threeMonthsDate);
            }
            return;
          }
          recoverParam(newQuery, value);
        });
        saveNewEntryPage();
        saveNewReferrerUrl();
      }

      setCookie("gtf_campaign", DOMAIN === "es" ? "3MESES1E" : null);
    }
  }, []);

  return (
    <div className={`flex w-full ${wrapperClassname}`}>
      <ButtonDS
        label={label}
        buttonType={secondary ? "tertiary" : "primary"}
        href={href ?? "/"}
        className={`w-full ${className}`}
        rel={noFollow ? "nofollow" : undefined}
        target={newTab ? "_blank" : undefined}
        id={id ? id : undefined}
        rightIcon={rightIcon}
        leftIcon={leftIcon}
        ghost={ghost}
        size={size}
      />
    </div>
  );
};

export default Button;
