import React from "react";
import { StrapiImage } from "../Types";
import { GatsbyImage } from "gatsby-plugin-image";

interface BannerImageI {
  data: {
    DesktopImage: StrapiImage;
    DesktopImageAlt: string;
    DesktopImageURL: string;
    MobileImage: StrapiImage;
    MobileImageAlt: string;
    MobileImageURL: string;
  };
}

const BannerImage = ({ data }: BannerImageI) => {
  const {
    DesktopImage,
    DesktopImageAlt,
    DesktopImageURL,
    MobileImage,
    MobileImageAlt,
    MobileImageURL,
  } = data;

  return (
    <section className="overflow-hidden md:h-[350px] h-[216px]">
      {DesktopImage ? (
        <GatsbyImage
          image={DesktopImage?.file?.childImageSharp?.gatsbyImageData}
          alt={DesktopImageAlt}
          className="w-full h-full md:block hidden"
          objectPosition={"center"}
        />
      ) : (
        <img
          src={DesktopImageURL}
          alt={DesktopImageAlt}
          className="w-full h-full object-cover object-center md:block hidden"
        />
      )}
      {MobileImage ? (
        <GatsbyImage
          image={MobileImage?.file?.childImageSharp?.gatsbyImageData}
          alt={MobileImageAlt}
          className="w-full h-full md:hidden block"
          objectPosition={"center"}
        />
      ) : (
        <img
          src={MobileImageURL}
          alt={MobileImageAlt}
          className="w-full h-full object-cover object-center md:hidden block"
        />
      )}
    </section>
  );
};

export default BannerImage;
