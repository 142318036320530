import React from "react";
import MaxContent from "../../layout/MaxContent";
import Markdown from '../Markdown/Markdown'
import { StrapiTitleSelect } from '../Types'
import { getTitleHTMLTag } from "../../helpers/tools";

interface FeatureContent {
  data: {
    Items: FeatureContentType[];
    Header: string;
    SelectTitleType: {
      TitleTypeSelection: string
    }
  };
}

interface FeatureContentType extends StrapiTitleSelect {
  Title: string;
  Text: string;
  ContentTitleType: string
  FeatureIcon: {
    alternativeText?: string;
    file: {
      url: any;
    };
  };
  ImageURL?: string;
  AlternativeText?: string;
};

const Feature_Content = ({ data }: FeatureContent) => {
  const dataArray = data.Items;

  let title = getTitleHTMLTag(data.Header, data.SelectTitleType?.TitleTypeSelection,"text-purple font-bold text-2xl md:text-h2-md my-6 md:text-center text-left")

  return (
    <MaxContent size="full">
      <div className="bg-inactive-gray w-full">
        <MaxContent size="content">
          <div className="pt-20 pb-24 ">
            {data.Header && title }

            <div className="flex flex-col md:grid gap-[40px] md:grid-cols-2 justify-between ">
              {dataArray.map((card) => (
                <div className="w-full pt-6 pb-16 md:justify-between min-h-max border-0 border-b-[1px] border-legal-gray "
                  key={card.Title}>

                  <div className="relative mr-3 w-16">
                    {card.FeatureIcon?.file?.url ? (
                      <img
                        src={card.FeatureIcon?.file?.url}
                        className="w-[60px] h-[60px]"
                        alt={card.FeatureIcon?.alternativeText}
                      />
                    ) : card.ImageURL ? (
                      <img
                        src={card.ImageURL}
                        className="w-[60px] h-[60px]"
                        alt={card.AlternativeText}
                      />
                    ) : null}
                  </div>

                  { getTitleHTMLTag(card.Title, card.ContentTitleType,"font-DM font-bold text-h5-sm md:text-h5-md lg:text-h5-lg text-black my-3 text-left mt-5") }
                  
                  <div className="font-DM text-left text-p-sm md:text-p-md text-placeholder md-content">
                    <Markdown>{card.Text}</Markdown>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </MaxContent>
      </div>
    </MaxContent>
  );
};
export default Feature_Content;
