import React, { useState } from "react";
import MaxContent from "../../layout/MaxContent";
import { a, useSpring, config } from "react-spring";
import Markdown from "../Markdown/Markdown";
import { getTitleHTMLTag } from "../../helpers/tools";

interface USPIconsData {
  data: USPProps;
}

interface USPProps {
  Items: USPIconType[];
  AnimatedBubbles: boolean;
}

interface USPIconType {
  Title: string;
  TitleTypeSelect: string;
  Text: string;
  Icon: {
    alternativeText?: string;
    file: {
      url: any;
    };
  };
  ImageURL?: string;
  AlternativeText?: string;
}

const USPIconRow: React.FC<USPIconsData> = ({ data }) => {
  return (
    <MaxContent size="content">
      <div className="">
        <section className="relative flex  flex-col md:flex-row items-stretch justify-around   md:gap-[40px]  ">
          {data.Items.map((card, index) => (
            <div
              className=" flex items-center   w-full md:my-4 py-2
            border-b-2 md:border-b-2 border-purple 
            md:border-t-4"
            >
              {card.Icon?.file?.url ? (
                <img
                  loading="lazy"
                  src={card.Icon?.file?.url}
                  alt={card.Icon?.alternativeText || ""}
                />
              ) : card.ImageURL ? (
                <img
                  loading="lazy"
                  src={card.ImageURL}
                  alt={card.AlternativeText}
                />
              ) : null}
              {
                data.AnimatedBubbles && (
                  <>
                    <AnimatedBubble x={40 * index} y={-40} size={20} />
                    <AnimatedBubble x={0 * index} y={10 * index} size={20} />
                    <AnimatedBubble
                      x={10 * index - 10 * index}
                      y={10 * index - 10 * index}
                      size={25}
                    />
                  </>
                )
              }

              <IconContent
                Title={card.Title}
                Text={card.Text}
                TitleTypeSelect={card.TitleTypeSelect}
              />
            </div>
          ))}
        </section>
      </div>
    </MaxContent>
  );
};

interface IconContentProps {
  Title: string;
  Text?: string;
  TitleTypeSelect?: string;
}

export const IconContent = ({
  Title,
  Text,
  TitleTypeSelect,
}: IconContentProps) => {
  let title = getTitleHTMLTag(
    Title,
    TitleTypeSelect,
    "font-bold text-purple text-[14px] leading-[26px] lg:text-[20px] lg:leading-[30px]"
  );
  return (
    <div className="text-left ml-5 pb-6  h-full">
      {Title && title}
      {Text && (
        <div className="text-[18px] leading-[28px] lg:text-[16px] lg:leading-[26px] mt-2 md-content">
          <Markdown>{Text}</Markdown>
        </div>
      )}
    </div>
  );
};

const AnimatedBubble: React.FC<{
  x: number;
  y: number;
  size: number;
  selected?: boolean;
}> = ({ x, y, size, selected }) => {
  const [reverse, setReverse] = useState<boolean>(false);

  const getRand = () => {
    // 50% chance negative, clamped between -50 & 50
    return Math.floor((Math.random() - 0.5) * 50);
  };

  const spring = useSpring({
    config: { ...config.molasses, duration: 2000 + getRand() * 2 },
    from: { transform: `translate3d(${x}px,${y}px,0)` },
    to: [
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)` },
      { transform: `translate3d(${x}px,${y}px,0)` },
    ],
    onRest: () => {
      setReverse(!reverse);
    },
    loop: true,
    reverse: reverse,
  });

  return (
    <a.div
      className={`absolute rounded-full opacity-20 ${
        selected ? "bg-red" : "bg-purple"
      }`}
      style={{ ...spring, width: size, height: size }}
    />
  );
};

export default USPIconRow;
