import React, { ReactElement, useEffect, useState } from "react";
import Button from "../Inputs/Button";
import MaxContent from "../../layout/MaxContent";
import Markdown from "../Markdown/Markdown";
import { getTitleHTMLTag } from "../../helpers/tools";

interface WhatsIncludedData {
  data: WhatsIncludedProps;
}

interface WhatsIncludedProps {
  Title: string;
  SelectTitleType: any;
  SubTitle: string;
  TabItem: IncludedProps[];
}

interface IncludedProps {
  TabTitle: string;
  ContentTitle: string;
  ContentTitleType: string;
  ContentSummary: string;
  PointColumns: string;
  Point1: string;
  Point2: string;
  Point3: string;
  Point4: string;
  Point5: string;
  Point6: string;
  Point7: string;
  Point8: string;
  Point9: string;
  Point10: string;
  Point11: string;
  Point12: string;
  BtnUrl: string;
  BtnLabel: string;
}

const WhatsIncluded = ({ data }: WhatsIncludedData) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [contentTitle, setContentTitle] = useState<ReactElement>();

  let Title = getTitleHTMLTag(data.Title, data.SelectTitleType?.TitleTypeSelection, 'text-purple h2-default mb-5 md:mb-2 md:text-center text-left justify-center' )

  return (
    <MaxContent size="content">
      <div className="pt-14 pb-[70px] md:pt-[70px] mx-auto">
        { data.Title && Title }
        <div className="font-MW mb-3 md:text-center text-[20px] leading-[30px] md-content">
          <Markdown>{data.SubTitle}</Markdown>
        </div>

        {/* tabs starts here */}
        <div className="md:border-b border-b-greyBorder overflow-x-scroll tabscontainer">
          <ul className="flex flex-row mx-auto w-fit mt-12 md:mt-[70px] rounded-t border border-greyBorder ">
            {data.TabItem.map((item, i) => (
              <li
                key={item.TabTitle}
                className={`font-bold p-6 cursor-pointer font-MW border-x border-t border-b-3 border-greyBorder  ${
                  selectedTab === i ? "text-purple border-b-[var(--primary-color)]" : ""
                }`}
                onClick={() => setSelectedTab(i)}
              >
                <Markdown>{item.TabTitle}</Markdown>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-10 md:mt-20">
          {data.TabItem.map((item, i) =>
            selectedTab === i ? (
              <div key={item.ContentTitle} className="test">
                <div className="max-w-[800px] mb-12">
                  <ContentTitle
                    Title={item.ContentTitle}
                    ContentTitleType={item.ContentTitleType}
                    selectedTab={selectedTab}
                    Index={i}
                  />

                  {selectedTab === i && item.ContentSummary && (
                    <div className="md-content">
                      <Markdown>{item.ContentSummary}</Markdown>
                    </div>
                  )}
                </div>
                <div
                  className={`grid grid-rows-1 gap-5 md:gap-10 ${
                    selectedTab === i && item.PointColumns === "One"
                      ? "md:grid-cols-1"
                      : selectedTab === i && item.PointColumns === "Two"
                      ? "md:grid-cols-2"
                      : ""
                  }`}
                >
                  {item.Point1 && selectedTab === i ? (
                    <div className="md-content">
                      {" "}
                      <Markdown>{item.Point1}</Markdown>
                    </div>
                  ) : null}

                  {item.Point2 && selectedTab === i ? (
                    <div className="md-content">
                      <Markdown>{item.Point2}</Markdown>
                    </div>
                  ) : null}
                  {item.Point3 && selectedTab === i ? (
                    <div className="md-content">
                      <Markdown>{item.Point3}</Markdown>
                    </div>
                  ) : null}
                  {item.Point4 && selectedTab === i ? (
                    <div className="md-content">
                      <Markdown>{item.Point4}</Markdown>
                    </div>
                  ) : null}
                  {item.Point5 && selectedTab === i ? (
                    <div className="md-content">
                      <Markdown>{item.Point5}</Markdown>
                    </div>
                  ) : null}
                  {item.Point6 && selectedTab === i ? (
                    <div className="md-content">
                      <Markdown>{item.Point6}</Markdown>
                    </div>
                  ) : null}

                  {item.Point7 && selectedTab === i ? (
                    <div className="md-content">
                      <Markdown>{item.Point7}</Markdown>
                    </div>
                  ) : null}

                  {item.Point8 && selectedTab === i ? (
                    <div className="md-content">
                      <Markdown>{item.Point8}</Markdown>
                    </div>
                  ) : null}
                  {item.Point9 && selectedTab === i ? (
                    <div className="md-content">
                      <Markdown>{item.Point9}</Markdown>
                    </div>
                  ) : null}
                  {item.Point10 && selectedTab === i ? (
                    <div className="md-content">
                      <Markdown>{item.Point10}</Markdown>
                    </div>
                  ) : null}
                  {item.Point11 && selectedTab === i ? (
                    <div className="md-content">
                      <Markdown>{item.Point12}</Markdown>
                    </div>
                  ) : null}
                  {item.Point12 && selectedTab === i ? (
                    <div className="md-content">
                      <Markdown>{item.Point12}</Markdown>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null
          )}
          {data.TabItem.map((item, i) =>
            selectedTab === i ? (
              <div
                className="md:max-w-[285px] mt-12 md:mt-14"
                key={item.BtnUrl + i}
              >
                <Button label={item.BtnLabel} href={item.BtnUrl} />
              </div>
            ) : null
          )}
        </div>
      </div>
    </MaxContent>
  );
};

type ContentTitleType = {
  Title: string;
  Index: number;
  selectedTab: number;
  ContentTitleType: any;
};

export const ContentTitle = ({
  Title,
  Index,
  selectedTab,
  ContentTitleType,
}: ContentTitleType) => {
  let innerTitle;
  switch (ContentTitleType) {
    case "H2":
      innerTitle = (
        <h2 className="h5-default font-bold mb-3 text-h5-sm md:text-h5-md lg:text-h5-lg">
          {selectedTab === Index ? Title : null}
        </h2>
      );

      break;
    case "H3":
      innerTitle = (
        <h3 className="h5-default font-bold mb-3 text-h5-sm md:text-h5-md lg:text-h5-lg">
          {selectedTab === Index ? Title : null}
        </h3>
      );

      break;
    case "H4":
      innerTitle = (
        <h4 className="h5-default font-bold mb-3 text-h5-sm md:text-h5-md lg:text-h5-lg">
          {selectedTab === Index ? Title : null}
        </h4>
      );

      break;
    case "H5":
      innerTitle = (
        <h5 className="h5-default font-bold mb-3 text-h5-sm md:text-h5-md lg:text-h5-lg">
          {selectedTab === Index ? Title : null}
        </h5>
      );

      break;
    case "H6":
      innerTitle = (
        <h6 className="h5-default font-bold mb-3 text-h5-sm md:text-h5-md lg:text-h5-lg">
          {selectedTab === Index ? Title : null}
        </h6>
      );

      break;
    default:
      innerTitle = (
        <h5 className="h5-default font-bold mb-3 text-h5-sm md:text-h5-md lg:text-h5-lg">
          {selectedTab === Index ? Title : null}
        </h5>
      );
  }

  return <>{innerTitle}</>;
};

export default WhatsIncluded;
