import React from "react";
import {
  BackgroundColorI,
  ChecklistIconColorI,
  IconListTypeI,
  TitleType,
} from "../Types";
import CalculatorModuleExample from "../CalculatorModule/CalculatorExample";
import TrustPilotDS from "../TrustPilot/TrustPilotDS";
import { getTitleHTMLTag } from "../../helpers/tools";
import { GlobalIcon, IconNameT } from "get-life-storybook-ts";
import SimpleMarkdown from "../SimpleMarkdown/SimpleMarkdown";

interface HeroCalculatorContentI {
  data: {
    BackgroundColor: BackgroundColorI;
    CalculatorModule: {
      Url: string;
    }[];
    TrustpilotScore: string;
    HasArrow: boolean;
    HasParagraphs: boolean;
    HasSerifTitle: boolean;
    HasSubtitle: boolean;
    HeroSansTitle: string;
    HasTrustpilotRating: string;
    HeroSerifTitle: string;
    HeroTitleHeading: {
      TitleTypeSelection: TitleType;
    };
    ModuleType: "Hero_module" | "Content_module";
    ExcellentText: string;
    Paragraph: string;
    Subtitle: string;
    SubtitleHeading: {
      TitleTypeSelection: TitleType;
    };
    noSignUp?: boolean;
    TrustpilotLink?: string;
    HasChecklist: boolean;
    CheckList: {
      CheckListItem: string;
      id: string;
    }[];
    HasIconList: boolean;
    ChecklistIconColor: ChecklistIconColorI;
    IconList: IconListTypeI[];
  };
}

const HeroCalculatorContent = ({ data }: HeroCalculatorContentI) => {
  const {
    BackgroundColor,
    CalculatorModule,
    TrustpilotScore,
    HasArrow,
    HasParagraphs,
    HasSerifTitle,
    HasSubtitle,
    HeroSansTitle,
    HasTrustpilotRating,
    HeroSerifTitle,
    HeroTitleHeading,
    ModuleType,
    ExcellentText,
    Paragraph,
    Subtitle,
    SubtitleHeading,
    TrustpilotLink,
    HasChecklist,
    CheckList,
    HasIconList,
    ChecklistIconColor,
    IconList,
  } = data;

  const isHero = ModuleType === "Hero_module" ? true : false;

  const backgroundColor = {
    Blue_Gray: "#F5F7FA",
    Light_Blue: "#ECF0FF",
    White: "#FFFFFF",
  }[BackgroundColor.BackgroundColor || "White"];

  const calculatorBackgroundColor = {
    Blue_Gray: "#FFFFFF",
    Light_Blue: "#F5F7FA",
    White: "#F5F7FA",
  }[BackgroundColor.BackgroundColor || "White"];

  const ChecklistColor = {
    Avo_Green: "#018565",
    Deep_life_blue: "#3430E3",
  }[ChecklistIconColor?.IconColor ? ChecklistIconColor.IconColor : "Avo_Green"];

  const IconListBackgroundColor = {
    Blue_Gray: "#ECF0FF",
    Light_Blue: "#FFFFFF",
    White: "#ECF0FF",
  }[BackgroundColor.BackgroundColor || "White"];

  const IconListIcon: { [key: string]: IconNameT } = {
    Shield: "ShieldCheckIcon",
    Home: "HomeIcon",
    Heart: "HeartIcon",
    Calculator: "CalculatorOutlineIcon",
    Health: "HealthIcon",
    Money: "MoneyIcon",
    Check: "CheckedIcon",
  };

  const prepareDataForCalculator = () => {
    return CalculatorModule.map((cm) => {
      return {
        Url: cm.Url,
        noSignUp: data?.noSignUp,
        backgroundColor: calculatorBackgroundColor,
      };
    });
  };

  let titleC = getTitleHTMLTag(
    <>
      <span className="font-DM font-bold md:text-[48px] text-[32px] md:leading-[48px] leading-[41.66px] tracking-[-0.02em]">
        {HeroSansTitle}
      </span>
      {HasSerifTitle ? (
        <span className="font-MW font-light md:text-[45px] text-[30px] md:leading-[56.57px] leading-[37.71px] tracking-[-0.02em]">
          {HeroSerifTitle}
        </span>
      ) : null}
    </>,
    HeroTitleHeading?.TitleTypeSelection,
    "text-[#424242] flex flex-col"
  );

  let subtitleC = getTitleHTMLTag(
    Subtitle,
    SubtitleHeading?.TitleTypeSelection,
    "text-[var(--primary-color)] font-medium md:text-[22px] text-[18px] md:leading-[28.64px] leading-[23.44px] tracking-[-0.02em]"
  );

  return (
    <div className="overflow-hidden" style={{ backgroundColor }}>
      <div className="grid md:grid-cols-12 gap-[24px] md:gap-[32px] items-center max-w-content mx-auto py-[64px] xl:px-[0px] px-[24px] grid-flow-dense">
        <div
          className={`relative md:space-y-[32px] space-y-[24px] ${
            isHero
              ? "md:col-start-1 md:col-end-7"
              : "md:col-start-7 md:col-end-13"
          }`}
        >
          {HasTrustpilotRating ? (
            <TrustPilotDS
              CurrentRateLabel={TrustpilotScore}
              OutOfLabel={ExcellentText}
              className="!text-left"
              TrustpilotLink={TrustpilotLink}
            />
          ) : null}
          <div className="md:space-y-[24px] space-y-[16px]">
            <div className="relative">
              {titleC}
              {HasArrow && HasParagraphs ? (
                <span
                  className={`hidden lg:block absolute ${
                    isHero
                      ? "right-[-185px] top-[calc(-50%_-_20px)]"
                      : "left-[-205px] top-[calc(-50%_-_24px)]"
                  }`}
                  style={{
                    transform: `scale(65%) ${
                      isHero
                        ? "rotate(190deg)"
                        : "rotate(180deg) rotateY(180deg)"
                    }`,
                  }}
                >
                  <GlobalIcon iconName="LoopingArrow" />
                </span>
              ) : null}
            </div>
            {HasSubtitle ? subtitleC : null}
          </div>
          {HasParagraphs ? (
            <div className="text-[#424242] font-normal md:text-[22px] text-[18px] md:leading-[28.64px] leading-[23.44px] tracking-[-0.02em] [&_p]:mb-0 md:[&_p:not(:last-child)]:mb-[24px] [&_p:not(:last-child)]:mb-[12px] [&_p]:text-[#424242] [&_p]:font-normal md:[&_p]:text-[22px] [&_p]:text-[18px] md:[&_p]:leading-[28.64px] [&_p]:leading-[23.44px] [&_p]:tracking-[-0.02em]">
              <SimpleMarkdown>{Paragraph}</SimpleMarkdown>
            </div>
          ) : HasArrow ? (
            <div className="relative">
              <span
                className={`hidden lg:block absolute ${
                  isHero
                    ? "right-[-185px] top-[-140px]"
                    : "left-[-185px] top-[-140px]"
                }`}
                style={{
                  transform: `scale(65%) ${
                    isHero ? "rotateY(180deg) rotate(15deg)" : "rotate(15deg)"
                  }`,
                }}
              >
                <GlobalIcon iconName="LoopingArrow" />
              </span>
            </div>
          ) : null}
          {HasChecklist ? (
            <ul className="text-[#424242] font-normal text-[18px] leading-[28.8px] tracking-[-0.02em] space-y-[16px]">
              {CheckList.map((c) => {
                const { CheckListItem, id } = c;
                return (
                  <li
                    key={id}
                    className="flex flex-row items-center gap-[16px]"
                  >
                    <GlobalIcon
                      iconName="FilledCheckIcon"
                      size="XS"
                      color={ChecklistColor}
                    />
                    <span>{CheckListItem}</span>
                  </li>
                );
              })}
            </ul>
          ) : null}
          {HasIconList ? (
            <ul className="text-[#424242] font-normal text-[18px] leading-[28.8px] tracking-[-0.02em] space-y-[16px]">
              {IconList.map((i) => {
                const { IconType, Text, id } = i;
                return (
                  <li
                    key={id}
                    className="flex flex-row items-center gap-[16px]"
                  >
                    <span
                      className="rounded-full md:p-[8px] p-[4px] md:[&_svg]:w-[24px] md:[&_svg]:h-[24px] [&_svg]:w-[18px] [&_svg]:h-[18px]"
                      style={{ backgroundColor: IconListBackgroundColor }}
                    >
                      <GlobalIcon
                        iconName={IconListIcon[IconType]}
                        size="XS"
                        color={"#3430E3"}
                      />
                    </span>
                    <span>{Text}</span>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
        <div
          className={`max-w-[448px] md:max-w-none relative ${
            isHero
              ? "md:col-start-8 md:col-end-13"
              : "md:col-start-1 md:col-end-6"
          }`}
        >
          <CalculatorModuleExample data={prepareDataForCalculator()} />
        </div>
      </div>
    </div>
  );
};

export default HeroCalculatorContent;
