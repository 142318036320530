import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

/*
 * rehypeRaw adds support for HTML tags
 * remarkGfm adds support for Github flavoured Markdown (adds support for Strikethroughs, Tables, etc)
 */
const SimpleMarkdown: React.FC<any> = ({ children }) => {
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw, remarkGfm]}
      children={children}
      transformImageUri={(uri) => `${uri}`}
      components={{
        u: ({ node, ...props }) => <u className="underline" {...props} />,
        table: ({ node, ...props }) => (
          <div className="overflow-x-auto my-[30px] md:my-10">
            <table className="table-fixed w-full min-w-[400px]" {...props} />
          </div>
        ),
      }}
    />
  );
};

export default SimpleMarkdown;
