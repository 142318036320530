import { numberWithCommas } from "../String/numberWithCommas";

export type RangeProps = {
    minCapital: number;
    maxCapital: number;
    step: number
}

export type OptionsI = { label: string; value: number; redirect: boolean };

export const createOptionsRange = ({
  minCapital,
  maxCapital,
  step,
}: RangeProps): OptionsI[] => {
  let length = (maxCapital - minCapital) / step + 1;
  const options: OptionsI[] = Array.from({ length: length }, (v, i) => ({
    label: `${numberWithCommas(minCapital + step * i)} €`,
    value: minCapital + step * i,
    redirect: false,
  }));
  return options;
};
