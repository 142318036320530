import React from "react";
import MaxContent from "../../../layout/MaxContent";
import Markdown from "../../Markdown/Markdown";
import { getTitleHTMLTag } from "../../../helpers/tools";

const BlueTick =
  "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/blue-tick.svg";
const BlueTickAlt =
  "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/blue-tick-alt.svg";
const RedCross =
  "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/red-cross.svg";
const GetLiftAlt =
  "https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life5_Logo_White.svg";

interface CompareData {
  Title: string;
  SelectTitleType: any;
  Headers: Array<{ Competitor: string }>;
  CompareRows: Array<{ Description: string }>;
  Values: Array<{ Value: string }>;
}

interface CompareProps {
  data: CompareData;
}

const CompareTable: React.FC<CompareProps> = ({ data }) => {
  // flattern array to get column headers
  const headers = [GetLiftAlt].concat(data.Headers.map((h) => h.Competitor));
  const rows = data.CompareRows.map((row) => row.Description);

  let title = getTitleHTMLTag(
    data.Title,
    data.SelectTitleType?.TitleTypeSelection,
    "h2-default text-purple"
  );

  // parse csv and replace with values to render
  const columns = data.Values.map((list) => {
    return list.Value.replace(/\s/g, "")
      .split(",")
      .map((v) => {
        if (v === "true") return true;
        if (v === "false") return false;
        return v;
      });
  });

  const header = headers.map((text, index) => {
    if (index == 0) {
      return (
        <th key={`compare-header-${index}`} className="bg-purple">
          <div className="flex justify-center p-[16px]">
            <img src={GetLiftAlt} alt="GetLiftAlt" className="min-w-[80px]" />
          </div>
        </th>
      );
    }
    return (
      <th
        key={`compare-header-${index}`}
        className="bg-white border-b border-purple text-purple md:text-[20px] leading-[30px] p-[16px]"
      >
        {text}
      </th>
    );
  });

  return (
    <MaxContent size="content">
      {data.Title && (
        <div className="flex justify-center w-full mb-[30px] md:mb-[60px] mt-[50px] md:mt-[80px]">
          {title}
        </div>
      )}

      <div className="overflow-x-auto max-w-full">
        <table className="table-auto w-full mb-[50px] md:mb-[80px]">
          <thead>
            <tr>
              <th className="border-b border-purple bg-inactive-gray"></th>
              {header}
            </tr>
          </thead>

          <tbody>
            {rows.map((description, index) => {
              return (
                <tr key={`compare-row-${index}`}>
                  <td className="font-bold text-[18px] leading-[28px] bg-inactive-gray p-3">
                    {" "}
                    <Markdown>{description}</Markdown>
                  </td>

                  {columns.map((col, subIndex) => {
                    let cell = col[index];

                    // first column is for GL
                    if (subIndex == 0) {
                      return (
                        <TableCell
                          value={cell}
                          alt={true}
                          key={`compare-cell-${index}-${subIndex}`}
                        />
                      );
                    } else {
                      return (
                        <TableCell
                          value={cell}
                          key={`compare-cell-${index}-${subIndex}`}
                        />
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </MaxContent>
  );
};

const TableCell = ({ value, alt }: any) => {
  let style =
    "bg-white  border-b border-[#F7F7F7] text-center text-[25px] leading-[35px] font-bold p-5 ";
  let altStyle =
    "bg-purple border-b border-white     text-center text-[25px] leading-[35px] font-bold p-5 text-white";

  if (typeof value === "boolean") {
    if (alt) {
      return (
        <td className={altStyle}>
          <img
            src={BlueTickAlt}
            className="w-10 h-10 mx-auto"
            alt="BlueTickAlt"
          />
        </td>
      );
    } else {
      return (
        <td className={style}>
          <img
            src={value ? BlueTick : RedCross}
            className="w-10 h-10 mx-auto"
            alt="BlueTick"
          />
        </td>
      );
    }
  }

  return <td className={alt ? altStyle : style}>{value}</td>;
};

export default CompareTable;
