import React from "react";
import MaxContent from "../../layout/MaxContent";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../Inputs/Button";
import { StrapiImage, TitleType } from "../Types";
import TrustPilotStaticWidget from "../TrustPilot/TrustPilotStaticWidget";
import AnimatedBubble from "../AnimatedBubble/AnimatedBubble";
import Markdown from "../Markdown/Markdown";
import { getTitleHTMLTag } from "../../helpers/tools";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const tick =
  "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/blue-tick.svg";

interface StrapiData {
  data: HeaderProps;
}

interface HeaderProps {
  Title: string;
  SelectTitleType: {
    TitleTypeSelection: TitleType;
  };
  SubTitle: string;
  Text: string;
  HeaderItems: HeaderPointProps[];
  HeadersButtons: HeaderButtonType[];
  TrustPilotScore: string;
  Icon: StrapiImage;
  ExcellentText: string;
  ExcellentLink: string;
  AnimatedBubbles: boolean;
  ImageURL?: string;
  AlternativeText?: string;
  HasLogo: boolean;
  DesktopLogoAlt?: string;
  MobileLogoAlt?: string;
  MobileLogo?: StrapiImage;
  DesktopLogo?: StrapiImage;
}

export type HeaderButtonType = {
  Label: string;
  Url: string;
  Secondary?: boolean;
  NoFollow?: boolean;
  NewTab?: boolean;
};

const Header: React.FC<StrapiData> = ({ data }) => {
  let title = getTitleHTMLTag(
    data.Title + " ",
    data.SelectTitleType?.TitleTypeSelection || "H1",
    "h1-default text-purple mb-3 w-full md:w-auto"
  );

  const { HasLogo, DesktopLogo, DesktopLogoAlt, MobileLogo, MobileLogoAlt } =
    data;

  return (
    <MaxContent size="full">
      <div className="bg-inactive-gray w-full overflow-hidden">
        <MaxContent size="content">
          <div className="flex flex-col-reverse md:grid-cols-2 md:grid bg-inactive-gray">
            <div className="text-left mt-2 lg:mt-20 max-w-md">
              {HasLogo ? (
                DesktopLogo?.file?.url.endsWith(".svg") ? (
                  <img
                    src={DesktopLogo.file.url}
                    className={"mb-[20px] w-fit hidden md:block"}
                  />
                ) : (
                  <GatsbyImage
                    className={"mb-[20px] w-fit hidden md:block"}
                    image={DesktopLogo?.file?.childImageSharp?.gatsbyImageData}
                    alt={DesktopLogoAlt}
                  />
                )
              ) : null}
              {data.Title ? title : null}
              {data.SubTitle && (
                <div className="h4-default text-purple mb-2 lg:mb-4 blue-md-content">
                  <ReactMarkdown>{data.SubTitle}</ReactMarkdown>
                </div>
              )}
              {data.Text && (
                <div className="text-placeholder mb-2 lg:mb-3 blue-md-content">
                  <Markdown>{data.Text}</Markdown>
                </div>
              )}

              <ul className="flex flex-col md:flex-col font-normal gap-2 md:gap-3 mt-5 lg:mt-4">
                {data.HeaderItems.map((point, i) => (
                  <HeaderPoint Point={point.Point} key={`header-point-${i}`} />
                ))}
              </ul>

              <div className="flex flex-col mt-7 md:max-w-[325px]">
                {data.HeadersButtons.length > 0 &&
                  data.HeadersButtons.map((btn) => (
                    <div className="mb-2" key={btn.Label}>
                      <Button
                        label={btn.Label}
                        href={btn.Url}
                        secondary={btn.Secondary}
                        noFollow={btn.NoFollow}
                        newTab={btn.NewTab}
                      />
                    </div>
                  ))}
              </div>

              <div className="flex flex-col justify-start mt-10 mb-[60px] md:mb-[90px]">
                {/* <TrustPilotWidget /> */}
                <TrustPilotStaticWidget score={data?.TrustPilotScore} />
              </div>
            </div>

            <div className="relative flex items-center mt-10 md:mt-20">
              {data.AnimatedBubbles && (
                <>
                  <AnimatedBubble x={10} y={150} size={20} />
                  <AnimatedBubble x={50} y={-150} size={80} />
                  <AnimatedBubble x={100} y={90} size={40} />
                  <AnimatedBubble x={400} y={200} size={60} />
                  <AnimatedBubble x={400} y={-250} size={70} />
                  <AnimatedBubble x={570} y={-100} size={30} />
                  <AnimatedBubble x={530} y={220} size={80} />
                  <AnimatedBubble x={-30} y={200} size={40} />
                </>
              )}
              <div className="flex flex-1 flex-col">
                {HasLogo ? (
                  MobileLogo?.file?.url.endsWith(".svg") ? (
                    <img
                      src={MobileLogo.file.url}
                      className={"mb-[20px] w-fit md:hidden"}
                    />
                  ) : (
                    <GatsbyImage
                      className={"mb-[20px] w-fit md:hidden"}
                      image={MobileLogo?.file?.childImageSharp?.gatsbyImageData}
                      alt={MobileLogoAlt}
                    />
                  )
                ) : null}
                {data.Icon?.file.childImageSharp?.gatsbyImageData ? (
                  <GatsbyImage
                    image={data.Icon?.file.childImageSharp?.gatsbyImageData}
                    alt={data.Icon?.alternativeText}
                    className="header-mask h-[300px] md:h-[620px] max-w-[300px] mx-auto md:max-w-none"
                  />
                ) : data.ImageURL ? (
                  <div className="header-mask h-[300px] md:h-[620px] max-w-[300px] mx-auto md:max-w-none">
                    <img
                      src={data.ImageURL}
                      alt={data.AlternativeText}
                      className="object-cover object-center w-full h-full"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </MaxContent>
      </div>
    </MaxContent>
  );
};

export interface HeaderPointProps {
  Point: string;
}

export const HeaderPoint: React.FC<HeaderPointProps> = ({ Point }) => {
  return (
    <li className="flex items-center">
      <img src={tick} alt="tick" className="inline-block w-[18px] h-[18px]" />
      <span className="ml-3 font-MW text-[16px] leading-[20px] md:text-[18px] md:leading-[22.63px]">
        {Point}
      </span>
    </li>
  );
};
export default Header;
