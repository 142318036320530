import React from "react";
import { BackgroundColorI, StrapiImage, TitleColorI, TitleType } from "../Types";
import { getTitleHTMLTag } from "../../helpers/tools";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../Inputs/Button";
import { GlobalIcon } from "get-life-storybook-ts";

interface ComparisonCardsI {
  data: {
    BackgroundColor: BackgroundColorI;
    HasSubtitle: boolean;
    HasTitle: boolean;
    SansTitle: string;
    SerifTitle: string;
    Subtitle: string;
    TitleHeading: {
      TitleTypeSelection: TitleType;
    };
    TitleColor: TitleColorI;
    OthersCompaniesCard: {
      CardTitle: string;
      CardTitleHeading: {
        TitleTypeSelection: TitleType;
      };
      ItemsList: {
        Icon: string;
        Label: string;
      };
    };
    OurCompanyCard: {
      GhostButtonID: string;
      GhostButtonLabel: string;
      GhostButtonURL: string;
      GhostNewTab: boolean;
      GhostNoFollow: boolean;
      HasGhostButton: boolean;
      HasPrimaryButton: boolean;
      ItemsList: {
        Icon: string;
        Label: string;
      };
      Logo: StrapiImage;
      LogoAlt: string;
      LogoURL: string;
      PrimaryButtonLabel: string;
      PrimaryButtonID: string;
      PrimaryButtonURL: string;
      PrimaryHasArrow: boolean;
      PrimaryNewTab: boolean;
      PrimaryNoFollow: boolean;
    };
  };
}

const ComparisonCards = ({ data }: ComparisonCardsI) => {
  const {
    BackgroundColor,
    HasSubtitle,
    HasTitle,
    SansTitle,
    SerifTitle,
    Subtitle,
    TitleHeading,
    OthersCompaniesCard,
    OurCompanyCard,
    TitleColor
  } = data;

  const backgroundColor = {
    Blue_Gray: "#F5F7FA",
    Light_Blue: "#ECF0FF",
    White: "#FFFFFF",
  }[BackgroundColor?.BackgroundColor || "White"];

  const cardBackgroundColor = {
    Blue_Gray: "#FFFFFF",
    Light_Blue: "#FFFFFF",
    White: "#F5F7FA",
  }[BackgroundColor?.BackgroundColor || "White"];

  const titleColor = {
    Dark_Gray: "#424242",
    Deep_Life_Blue: "#3430E3"
  }[TitleColor?.TitleColor || "Dark_Gray"];

  const titleC = HasTitle
    ? getTitleHTMLTag(
        <>
          <span className="font-DM font-bold md:text-[36px] text-[30px] md:leading-[41.66px] leading-[28.64px] tracking-[-0.02em]">
            {SansTitle}{" "}
          </span>
          <span className="font-MW font-light md:text-[32px] text-[27px] md:leading-[37.71px] leading-[25.14px] tracking-[-0.02em]">
            {SerifTitle}
          </span>
        </>,
        TitleHeading?.TitleTypeSelection,
        `text-[${titleColor}] md:text-center`
      )
    : null;

  const subtitleC = HasSubtitle
    ? getTitleHTMLTag(
        Subtitle,
        "p",
        "text-[#424242] md:text-center font-DM font-normal md:text-[22px] text-[18px] md:leading-[28.64px] leading-[23.44px] tracking-[-0.02em]"
      )
    : null;

  return (
    <section className="overflow-hidden" style={{ backgroundColor }}>
      <div className="flex flex-col max-w-content mx-auto py-[64px] xl:px-[0px] px-[24px] md:gap-[64px] gap-[32px]">
        <div className="flex flex-col md:gap-[16px] gap-[8px]">
          {titleC}
          {subtitleC}
        </div>
        <div className="flex flex-wrap justify-center items-center gap-[32px]">
          <OtherCompanyCard
            data={OthersCompaniesCard}
            backgroundColor={cardBackgroundColor}
          />
          <CompanyCard
            data={OurCompanyCard}
            backgroundColor={cardBackgroundColor}
          />
        </div>
      </div>
    </section>
  );
};

const OtherCompanyCard = ({ data, backgroundColor }) => {
  const { CardTitle, CardTitleHeading, ItemsList } = data;

  const titleC = getTitleHTMLTag(
    CardTitle,
    CardTitleHeading.TitleTypeSelection,
    "text-[#686868] md:text-center font-DM font-bold md:text-[30px] text-[24px] md:leading-[41.66px] leading-[28.64px] tracking-[-0.02em]"
  );

  return (
    <div
      className="w-[352px] px-[24px] pt-[24px] pb-[48px] rounded-[24px] flex flex-col justify-center gap-[16px] shadow-[0px_1px_1px_0px_rgba(0,_0,_0,_0.07),_0px_2px_1px_0px_rgba(0,_0,_0,_0.06),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)]"
      style={{ backgroundColor }}
    >
      {titleC}
      <ItemList items={ItemsList} />
    </div>
  );
};

const CompanyCard = ({ data, backgroundColor }) => {
  const {
    GhostButtonID,
    GhostButtonLabel,
    GhostButtonURL,
    GhostNewTab,
    GhostNoFollow,
    HasGhostButton,
    HasPrimaryButton,
    ItemsList,
    Logo,
    LogoAlt,
    LogoURL,
    PrimaryButtonLabel,
    PrimaryButtonID,
    PrimaryButtonURL,
    PrimaryHasArrow,
    PrimaryNewTab,
    PrimaryNoFollow,
  } = data;

  return (
    <div
      className="w-[352px] p-[24px] rounded-[24px] flex flex-col gap-[16px] border-2 border-[#8E8BF8] shadow-[0px_24px_38px_0px_rgba(0,_0,_0,_0.07),_0px_9px_46px_0px_rgba(0,_0,_0,_0.06),_0px_11px_15px_0px_rgba(0,_0,_0,_0.10)]"
      style={{ backgroundColor }}
    >
      <div className="flex justify-center py-[24px]">
        {Logo ? (
          <GatsbyImage
            image={Logo?.file?.childImageSharp?.gatsbyImageData}
            alt={LogoAlt}
            className="w-[114px]"
          />
        ) : (
          <img src={LogoURL} alt={LogoAlt} className="w-[114px]" />
        )}
      </div>
      <ItemList items={ItemsList} />
      {HasPrimaryButton || HasGhostButton ? (
        <div className="mt-[24px] flex flex-col gap-[8px]">
          {HasPrimaryButton ? (
            <Button
              label={PrimaryButtonLabel}
              href={PrimaryButtonURL}
              ghost={false}
              newTab={PrimaryNewTab}
              noFollow={PrimaryNoFollow}
              id={PrimaryButtonID}
              rightIcon={PrimaryHasArrow ? "ArrowRightIcon" : undefined}
              size="40"
            />
          ) : null}
          {HasGhostButton ? (
            <Button
              label={GhostButtonLabel}
              href={GhostButtonURL}
              ghost={true}
              newTab={GhostNewTab}
              noFollow={GhostNoFollow}
              id={GhostButtonID}
              size="40"
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const ItemList = ({ items }) => {
  return (
    <div className="flex flex-col gap-[16px]">
      {items.map((item, idx) => {
        const { Label, Icon } = item;

        return (
          <span
            className="flex flex-row items-center gap-[8px]"
            key={`${Label}_${idx}`}
          >
            <ItemListIcon icon={Icon} />
            <span className="flex-1 text-[#555555] font-normal text-[18px] leading-[28.8px] tracking-[-0.02em]">
              {Label}
            </span>
          </span>
        );
      })}
    </div>
  );
};

const ItemListIcon = ({ icon }) => {
  const icons = {
    Red_cross: "XIcon",
    Green_check: "CheckedIcon",
  };

  const iconsColor = {
    Red_cross: "#e53737",
    Green_check: "#018565",
  };
  if (!icons[icon]) return null;

  return (
    <GlobalIcon iconName={icons[icon]} size="XS" color={iconsColor[icon]} />
  );
};

export default ComparisonCards;
