

function MakeSlug(Text: string) {
  const slug = Text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/_/g, "-")
    .replace(/[^\w-]+/g, "");
  if (slug === "home-page") return "/";
  else return slug;
}

export function DeSlug(url: any) {
  const newBreadCrumbs = [
    {
      name: "Home",
      link: "/",
    },
  ];

  const parts = url.split("/");
  let cumulativeLink = "/";
  for (let index = 0; index < parts.length; index++) {
    const element = parts[index];
    if (element === "") {
      continue;
    } else {
      const noDash = element.replaceAll("-", " ");
      const label = noDash[0].toUpperCase() + noDash.substring(1);
      cumulativeLink = cumulativeLink + element + "/";
      newBreadCrumbs.push({
        name: label,
        link: cumulativeLink,
      });
    }
  }

  return newBreadCrumbs
}

export default MakeSlug