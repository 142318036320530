import React from "react";
import {
  BirthDateInput,
  Button,
  CheckboxAtom,
  DropDown,
  GlobalIcon,
  Input,
  SingleSelect,
  TextWithInfoIcon,
  Typography
} from "get-life-storybook-ts";

import CalculatorModuleController, {
  StrapiDataCalculator,
} from "./Calculator.controller";


interface OverlayI {
  isMobile?: boolean;
}

const CalculatorModule: React.FC<StrapiDataCalculator> = ({data}) => {
  const {
    actualStep,
    capitalOptions,
    display,
    formDataFirst,
    formDataSecond,
    formError,
    handleBlur,
    handleButton,
    handleChange,
    isMobile,
    optionsIntention,
    setActualStep,
    setDisplay,
    setFormDataFirst,
    setFormDataSecond
  } = CalculatorModuleController({data});

  const checkBoxQuery = (
    <>
      <div className="flex flex-col">
        <Typography
          variant="BodyMedium"
          text="Acepto el tratamiento de mis datos y ser contactado para recibir la información solicitada."
          color="ligth-gray"
        />
        <Typography
          variant="LinkMedium"
          text="Ver política de privacidad"
          color="ligth-gray"
          target="_blank"
          href="https://life5.es/politica-privacidad/"
        />
      </div>
    </>
  );

  const OverlayProtection: React.FC<OverlayI> = ({ isMobile }) => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <GlobalIcon iconName="PuzzlePieceIcon" size={isMobile ? "M" : "L"} className="justify-start"/>
          <Typography
            variant="Body"
            text="En Life5 tenemos distintos tipos de cobertura de acuerdo a tus necesidades:"
          />
        </div>
        <div className="flex flex-row gap-4">
          <GlobalIcon iconName="HumourIcon" size={isMobile ? "M" : "L"} />
  
          <div className="flex flex-col gap-1">
            <Typography
              variant="H3"
              weight="700"
              text="Seguro de vida para familia"
              color="purple"
            />
            <Typography
              variant="Body"
              text="Enfocado en cubrir a tu familia si llegarás a faltar el día de mañana."
            />
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <GlobalIcon iconName="SaveMoneyIcon" size={isMobile ? "M" : "L"} />
  
          <div className="flex flex-col gap-1">
            <Typography
              variant="H3"
              weight="700"
              text="Seguro de vida para hipotecas y/o préstamos"
              color="purple"
            />
            <Typography
              variant="Body"
              text="Enfocado en cubrir tus deudas si llegarás a faltar el día de mañana."
            />
          </div>
        </div>
      </div>
    );
  };

  const OverlayCapital: React.FC<OverlayI> = ({ isMobile }) => {
    return (
      <div className="flex flex-col gap-4">
        <GlobalIcon iconName="CalculatorIcon" size={isMobile ? "M" : "L"} />
        <Typography
          variant="Body"
          text="El capital de cobertura es el dinero bajo el que te aseguras y el dinero que recibirá la persona o entidad que desees."
        />
        <Typography
          variant="Body"
          text="Puedes cambiarlo más adelante para que puedas comparar y elegir lo que más se ajuste a tus necesidades."
        />
      </div>
    );
  };

  const OverlayData: React.FC<OverlayI> = ({ isMobile }) => {
    return (
      <div className="flex flex-col gap-4">
        <Typography
          variant="Body"
          text="En Life5 tu información es confidencial, cuidamos tus datos y no los compartimos con otros."
        />
        <div className="flex flex-row gap-4">
          <GlobalIcon iconName="EmailIcon" size={isMobile ? "M" : "L"} />
  
          <div className="flex flex-col gap-1">
            <Typography
              variant="H3"
              weight="700"
              text="Tu email"
              color="purple"
            />
            <Typography
              variant="Body"
              text="Para enviarte todos los pasos del proceso. Somos transparentes, queremos que tengas toda la documentación pertinente."
            />
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <GlobalIcon iconName="CallIcon" size={isMobile ? "M" : "L"} />
  
          <div className="flex flex-col gap-1">
            <Typography
              variant="H3"
              weight="700"
              text="Tu teléfono"
              color="purple"
            />
            <Typography
              variant="Body"
              text="Para poder ayudarte y asesorarte, nuestro objetivo es que encuentres el mejor producto para ti."
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`w-full md:max-w-[450px] ${
          actualStep === 1 && display ? "min-h-[562px]" : "min-h-[528px]"
        } flex flex-col bg-[#F5F7FA] pt-6 px-4 pb-8 gap-8 rounded-lg shadow-lg`}
      >
        <div className="flex flex-col justify-center items-center gap-6">
          <div className="flex flex-row gap-4 w-full">
            <Typography
              variant="BodySmall"
              text="Paso 1"
              color={actualStep === 1 ? "purple" : "ligth-gray"}
              onClick={() => setActualStep(1)}
              className="cursor-pointer"
            />
            <Typography variant="BodySmall" text=">" color="ligth-gray" />
            <Typography
              variant="BodySmall"
              text="Paso 2"
              color={actualStep === 2 ? "purple" : "ligth-gray"}
              className={`${
                actualStep === 1 
                  ? "cursor-pointer"
                  : "cursor-not-allowed"
              }`}
              onClick={() => {
                if (actualStep === 1) {
                  setActualStep(2);
                }
              }}
            />
            <Typography variant="BodySmall" text=">" color="ligth-gray" />
            <Typography
              variant="BodySmall"
              text="Tu precio"
              color="ligth-gray"
            />
          </div>
          {actualStep === 1 && (
            <>
              {!display && (
                <>
                <BirthDateInput
                    label="Fecha de Nacimiento"
                    initialValue={formDataFirst.birthDate}
                    setter={(birthDate: string) =>
                      setFormDataFirst({
                        ...formDataFirst,
                        birthDate,
                      })
                    }
                    birthDateError={formError?.birthDate}
                  />
                  <div className="flex flex-col w-full">
                    <Typography
                      variant="Body"
                      text="¿Qué quieres proteger?"
                      color="gray-text"
                    />
                    <div className="flex flex-col gap-4">
                      <SingleSelect
                        name="intention_select"
                        initialValue={formDataFirst.intention}
                        onValueChange={(e: any) =>
                          handleChange({
                            setValue: setFormDataFirst,
                            value: e,
                            field: "intention",
                            formData: formDataFirst,
                          })
                        }
                        options={optionsIntention}
                        defaultVariant="Standard"
                        defaultSize="S"
                      />
                      {
                        formError?.intention?.message &&
                        <Typography text={formError?.intention?.message} variant="BodySmall" weight="400" className="text-red" />
                      }
                    </div>
                    <TextWithInfoIcon
                      content={"¿Por qué hacemos esta pregunta?"}
                      overlayPrice={<OverlayProtection isMobile={isMobile} />}
                      overlayTitle={
                        <Typography
                          variant="H3"
                          weight="700"
                          text="¿Por qué preguntamos sobre qué quieres proteger?"
                        />
                      }
                      overlayWidth={false}
                      infoIcon
                      position="left"
                      color="ligth-gray"
                      weight="400"
                      className="mt-2"
                    />
                  </div>
                </>
              )}
              <div className="flex flex-col w-full">
                <Typography
                  variant="Body"
                  text="Capital de cobertura"
                  color="gray-text"
                />
                <div className="flex flex-col gap-4">
                  <DropDown
                    isSimple
                    bgWhite
                    initialValue={(formDataFirst.capital || "") as string}
                    handleDisplay={(displayItem: boolean) =>
                      setDisplay(displayItem)
                    }
                    onValueChange={(e: any) => 
                      handleChange({
                        setValue: setFormDataFirst,
                        value: e,
                        field: "capital",
                        formData: formDataFirst,
                      })
                    }
                    options={capitalOptions}
                    disabled={capitalOptions.length === 0}
                    className="w-full"
                    placeholder="Seleccione una opción"
                    initialError={formError?.capital}
                  />
                </div>
                {capitalOptions.length > 0 && (
                  <TextWithInfoIcon
                    content={"Aconsejamos 8 veces tu salario anual"}
                    overlayPrice={<OverlayCapital isMobile={isMobile} />}
                    overlayTitle={
                      <Typography
                        variant="H3"
                        weight="700"
                        text="¿Qué es el capital de cobertura?"
                      />
                    }
                    overlayWidth={false}
                    infoIcon
                    position="left"
                    color="ligth-gray"
                    weight="400"
                    className="mt-2"
                  />
                )}
              </div>
               </>
          )}

{actualStep === 2 && (
            <>
              <div className="flex flex-col w-full">
                <Typography variant="Body" text="Email" color="gray-text" />
                <div className="flex flex-row gap-4">
                  <Input
                    initialValue={formDataSecond.email}
                    inputMode="email"
                    autoFocus
                    onBlur={() =>
                      handleBlur({ field: "email", formData: formDataSecond })
                    }
                    onValueChange={(e: any) =>
                      handleChange({
                        setValue: setFormDataSecond,
                        value: e,
                        field: "email",
                        formData: formDataSecond,
                      })
                    }
                    placeholder="Escribe tu correo electrónico"
                    initialError={formError?.email}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full">
                <Typography variant="Body" text="Teléfono" color="gray-text" />
                <div className="flex flex-row gap-4">
                  <Input
                    initialValue={formDataSecond.phone}
                    inputMode="number"
                    pattern="\d*"
                    onBlur={() =>
                      handleBlur({ field: "phone", formData: formDataSecond })
                    }
                    onValueChange={(e: any) =>
                      handleChange({
                        setValue: setFormDataSecond,
                        value: e,
                        field: "phone",
                        formData: formDataSecond,
                      })
                    }
                    placeholder="Escribe tu número de teléfono"
                    initialError={formError?.phone}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full">
                <TextWithInfoIcon
                  content={"¿Por qué te pedimos estos datos?"}
                  overlayPrice={<OverlayData isMobile={isMobile} />}
                  overlayTitle={
                    <Typography
                      variant="H3"
                      weight="700"
                      text="¿Por qué te pedimos estos datos?"
                    />
                  }
                  overlayWidth={false}
                  infoIcon
                  position="left"
                  color="ligth-gray"
                  weight="400"
                  className="mt-2"
                />
              </div>
              <div className="flex flex-col w-full">
                <CheckboxAtom
                  label={checkBoxQuery}
                  onValueChange={(checkedConditions: boolean) =>
                    handleChange({
                      setValue: setFormDataSecond,
                      value: checkedConditions,
                      field: "checkedConditions",
                      formData: formDataSecond,
                    })
                  }
                  isLegal
                  errorCheck={formError?.checkedConditions}
                />
              </div>
            </>
          )}
          <div className="flex flex-col w-full">
            <Button
              label={actualStep === 1 ? "Continuar" : "Ver mi precio"}
              onClick={() => handleButton()}
              iconPosition="right"
              icon={<GlobalIcon iconName="ArrowRightIcon" color="white" />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CalculatorModule;
