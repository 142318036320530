import React, { createContext } from "react";

interface LocationContextI {
  pathname?: string;
  search?: string;
  hash?: string;
  href?: string;
  origin?: string;
  protocol?: string;
  host?: string;
  hostname?: string;
  port?: string;
  state?: string;
  key?: string;
}

export const LocationContext = createContext<LocationContextI>({});
