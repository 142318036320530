import React from "react";
import Markdown from '../Markdown/Markdown'
import MaxContent from "../../layout/MaxContent";

interface StrapiData {
  data: {
    Text: string;
  };
}

const Text: React.FC<StrapiData> = ({ data }) => {
  return (
    <MaxContent size="content">
      <div className="font-DM text-body-md text-placeholder">
        <Markdown>{data.Text}</Markdown>
      </div>
    </MaxContent>
  );
};

export default Text;
