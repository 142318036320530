import React from "react";
import { BannerBackgroundColorI, TitleType } from "../Types";
import TrustPilotDS from "../TrustPilot/TrustPilotDS";
import { getTitleHTMLTag } from "../../helpers/tools";
import Button from "../Inputs/Button";

interface BannerMinimalistI {
  data: {
    BannerBackgroundColor: BannerBackgroundColorI;
    ButtonId: string;
    ButtonLabel: string;
    ButtonUrl: string;
    TrustpilotScore: string;
    HasSubtitle: boolean;
    HasTrustpilot: boolean;
    NewTab: boolean;
    NoFollow: boolean;
    ExcellentText: string;
    Subtitle: string;
    Title: string;
    TitleHeading: {
      TitleTypeSelection: TitleType;
    };
    TrustpilotLink?: string;
  };
}

const BannerMinimalist = ({ data }: BannerMinimalistI) => {
  const {
    BannerBackgroundColor,
    ButtonId,
    ButtonLabel,
    ButtonUrl,
    TrustpilotScore,
    HasSubtitle,
    HasTrustpilot,
    NewTab,
    NoFollow,
    ExcellentText,
    Subtitle,
    Title,
    TitleHeading,
    TrustpilotLink
  } = data;

  const backgroundColor = {
    Blue_Gray: "#F5F7FA",
    Light_Blue: "#ECF0FF",
    White: "#FFFFFF",
    Deep_Life_Blue: "#3430E3",
  }[BannerBackgroundColor?.BackgroundColor || "White"];

  const titleColor =
    {
      Deep_Life_Blue: "#FFFFFF",
    }[BannerBackgroundColor?.BackgroundColor] || "#3430E3";

  const textColor =
    {
      Deep_Life_Blue: "#FFFFFF",
    }[BannerBackgroundColor?.BackgroundColor] || "#424242";

  const buttonIsSecondary =
    BannerBackgroundColor?.BackgroundColor === "Deep_Life_Blue";

  const titleC = getTitleHTMLTag(
    <span style={{ color: titleColor }}>{Title}</span>,
    TitleHeading?.TitleTypeSelection || "H2",
    `text-center md:text-left font-bold md:text-[32px] text-[22px] md:leading-[41.66px] leading-[28.64px] tracking-[-0.02em]`
  );

  const subtitleC = HasSubtitle
    ? getTitleHTMLTag(
        <span style={{ color: textColor }}>{Subtitle}</span>,
        "p",
        `text-center md:text-left font-normal md:text-[22px] text-[18px] md:leading-[28.64px] leading-[23.44px] tracking-[-0.02em]`
      )
    : null;

  return (
    <div className="overflow-hidden" style={{ backgroundColor }}>
      <div className="grid md:grid-cols-12 grid-cols-1 gap-[32px] items-center max-w-content mx-auto xl:px-[0px] px-[24px] md:py-[40px] py-[32px]">
        <div className="flex flex-col gap-[8px] md:col-span-8">
          {titleC}
          {subtitleC}
          {HasTrustpilot ? (
            <TrustPilotDS
              CurrentRateLabel={TrustpilotScore}
              OutOfLabel={ExcellentText}
              className={`md:!text-left !text-center md:mt-[8px]`}
              color={textColor}
              TrustpilotLink={TrustpilotLink}
            />
          ) : null}
        </div>
        <div className="flex items-center justify-center md:justify-end md:col-span-4">
          <Button
            id={ButtonId}
            label={ButtonLabel}
            href={ButtonUrl}
            noFollow={NoFollow}
            newTab={NewTab}
            rightIcon="ArrowRightIcon"
            wrapperClassname="!w-fit"
            className="text-center"
            secondary={buttonIsSecondary}
          />
        </div>
      </div>
    </div>
  );
};

export default BannerMinimalist;
