import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import MaxContent from '../../layout/MaxContent' 

interface StrapiData {
  data: {
    SingleImage: {
      alternativeText: string
      file: {
        childImageSharp: {
          gatsbyImageData: any
        }
      }
    }
  }
}

const Image: React.FC<StrapiData> = ({ data }) => {
  return (
    <MaxContent size='content'> 
      <GatsbyImage 
        image={ data.SingleImage?.file?.childImageSharp.gatsbyImageData } 
        alt={data?.SingleImage?.alternativeText} />
    </MaxContent> 
  )
}

export default Image