import React from "react";

import {
  BackgroundColorI,
  ButtonsAreaLayoutI,
  ChecklistIconColorI,
  IconListTypeI,
  ImageSizingI,
  SecondaryButtonIconTypesI,
  SecondaryButtonTypesI,
  StrapiImage,
  TitleType,
} from "../Types";
import { GatsbyImage } from "gatsby-plugin-image";
import { getTitleHTMLTag } from "../../helpers/tools";
import TrustPilotDS from "../TrustPilot/TrustPilotDS";
import Button from "../Inputs/Button";
import { GlobalIcon, IconNameT } from "get-life-storybook-ts";
import SimpleMarkdown from "../SimpleMarkdown/SimpleMarkdown";
import { useBreadCrumbs } from "../Hooks";
import { Link } from "gatsby";

interface HeroPrimaryI {
  data: {
    BackgroundColor: BackgroundColorI;
    CheckList: {
      CheckListItem: string;
      id: string;
    }[];
    ChecklistIconColor: ChecklistIconColorI;
    DesktopImage: StrapiImage;
    DesktopImageAlt: string;
    HasChecklist: boolean;
    HasIconList: boolean;
    HasLongParagraph: boolean;
    HasPrimaryButton: boolean;
    HasSecondaryButton: boolean;
    HasSerifTitle: boolean;
    HasShortParagraphs: boolean;
    HeroSansTitle: string;
    HeroSerifTitle: string;
    HeroTitleHeading: {
      TitleTypeSelection: TitleType;
    };
    IconList: IconListTypeI[];
    ImageHasRadius: boolean;
    ImageSizing: ImageSizingI;
    LongParagraph: string; // richtext
    MobileImage: StrapiImage;
    MobileImageAlt: string;
    MobileImageURL: string;
    PrimaryButtonID: string;
    PrimaryButtonLabel: string;
    PrimaryButtonNewTab: boolean;
    PrimaryButtonNoFollow: boolean;
    PrimaryButtonURL: string;
    SecondaryButtonHasIcon: boolean;
    SecondaryButtonID: string;
    SecondaryButtonIconTypes: SecondaryButtonIconTypesI;
    SecondaryButtonTypes: SecondaryButtonTypesI;
    ShortParagraphs: {
      Paragraph: string;
      id: string;
    }[];
    SecondaryButtonURL: string;
    SecondaryButtonNoFollow: boolean;
    SecondaryButtonNewTab: boolean;
    SecondaryButtonLabel: string;
    DesktopImageURL: string;
    HasBreadcrumb: boolean;
    AboveTrustpilotScore: string;
    AboveExcellentText: string;
    BelowTrustpilotScore: string;
    BelowExcellentText: string;
    HasAboveTrustpilotRating: boolean;
    HasBelowTrustpilotRating: boolean;
    ButtonsAreaLayout: ButtonsAreaLayoutI;
    TrustpilotLink?: string;
  };
}

const HeroPrimary = ({ data }: HeroPrimaryI) => {
  const {
    BackgroundColor,
    CheckList,
    ChecklistIconColor,
    DesktopImage,
    DesktopImageAlt,
    HasChecklist,
    HasIconList,
    HasLongParagraph,
    HasSerifTitle,
    HasShortParagraphs,
    HeroSansTitle,
    HeroSerifTitle,
    HeroTitleHeading,
    IconList,
    ImageHasRadius,
    ImageSizing,
    LongParagraph,
    MobileImage,
    MobileImageAlt,
    MobileImageURL,
    ShortParagraphs,
    DesktopImageURL,
    HasBreadcrumb,
    AboveTrustpilotScore,
    AboveExcellentText,
    BelowTrustpilotScore,
    BelowExcellentText,
    HasAboveTrustpilotRating,
    HasBelowTrustpilotRating,
    TrustpilotLink,
  } = data;

  const { breadcrumbs } = useBreadCrumbs();

  const backgroundColor = {
    Blue_Gray: "#F5F7FA",
    Light_Blue: "#ECF0FF",
    White: "#FFFFFF",
  }[BackgroundColor.BackgroundColor || "White"];

  const imageHeight = {
    Large: "965px",
    Medium: "520px",
  }[ImageSizing?.ImageSize ? ImageSizing.ImageSize : "Medium"];

  const imageOffset = {
    Large: "-24px",
    Medium: "0px",
  }[ImageSizing?.ImageSize ? ImageSizing.ImageSize : "Medium"];

  const ChecklistColor = {
    Avo_Green: "#018565",
    Deep_life_blue: "#3430E3",
  }[ChecklistIconColor?.IconColor ? ChecklistIconColor.IconColor : "Avo_Green"];

  const IconListBackgroundColor = {
    Blue_Gray: "#ECF0FF",
    Light_Blue: "#FFFFFF",
    White: "#ECF0FF",
  }[BackgroundColor.BackgroundColor || "White"];

  const IconListIcon: { [key: string]: IconNameT } = {
    Shield: "ShieldCheckIcon",
    Home: "HomeIcon",
    Heart: "HeartIcon",
    Calculator: "CalculatorOutlineIcon",
    Health: "HealthIcon",
    Money: "MoneyIcon",
    Check: "CheckedIcon",
  };

  let titleC = getTitleHTMLTag(
    <>
      <span className="font-DM font-bold md:text-[48px] text-[32px] md:leading-[48px] leading-[41.66px] tracking-[-0.02em]">
        {HeroSansTitle}
      </span>
      {HasSerifTitle ? (
        <span className="font-MW font-light md:text-[45px] text-[30px] md:leading-[56.57px] leading-[37.71px] tracking-[-0.02em]">
          {HeroSerifTitle}
        </span>
      ) : null}
    </>,
    HeroTitleHeading?.TitleTypeSelection,
    "text-[#424242] flex flex-col"
  );

  return (
    <div className="overflow-hidden" style={{ backgroundColor }}>
      <div className="grid md:grid-cols-12 gap-[32px] items-center max-w-content mx-auto xl:px-[0px] px-[24px]">
        <div className="md:col-span-5 max-w-[448px] md:max-w-none relative md:py-[80px] py-[40px]">
          {HasBreadcrumb ? (
            <div className="hidden md:flex flex-row mb-[32px]">
              {breadcrumbs.map((v, i) => {
                if (i < breadcrumbs.length - 1) {
                  return (
                    <div className="flex flex-row" key={i}>
                      <Link
                        to={v.link}
                        className={
                          "text-[14px] leading-[18px] font-normal text-placeholder mb-3 w-full md:w-auto no-underline"
                        }
                        key={i}
                      >
                        <span>{v.name}</span>
                      </Link>
                      <span
                        className={
                          "text-[14px] leading-[18px] px-2.5 text-placeholder font-normal"
                        }
                      >
                        |
                      </span>
                    </div>
                  );
                } else {
                  return (
                    <span
                      className={"text-[14px] leading-[18px] font-black"}
                      key={i}
                    >
                      {v.name}
                    </span>
                  );
                }
              })}
            </div>
          ) : null}
          {HasAboveTrustpilotRating ? (
            <TrustPilotDS
              CurrentRateLabel={AboveTrustpilotScore}
              OutOfLabel={AboveExcellentText}
              className="md:mb-[32px] mb-[24px] w-full"
              TrustpilotLink={TrustpilotLink}
            />
          ) : null}
          <div className="flex flex-col gap-[24px]">
            {titleC}
            <div className="flex flex-col gap-[24px]">
              {HasShortParagraphs ? (
                <div className="text-[#424242] font-normal text-[22px] leading-[28.64pxpx] tracking-[-0.02em] [&_p]:mb-0 md:[&_p:not(:last-child)]:mb-[24px] [&_p:not(:last-child)]:mb-[12px] [&_p]:text-[#424242] [&_p]:font-normal [&_p]:text-[22px] [&_p]:leading-[28.64px] [&_p]:tracking-[-0.02em]">
                  {ShortParagraphs.map((p) => {
                    const { Paragraph, id } = p;
                    return <p key={id}>{Paragraph}</p>;
                  })}
                </div>
              ) : null}
              {HasLongParagraph ? (
                <div className="text-[#0e0c0c] font-normal text-[18px] leading-[28.8px] tracking-[-0.02em] [&_p]:mb-0 md:[&_p:not(:last-child)]:mb-[24px] [&_p:not(:last-child)]:mb-[12px] [&_p]:text-[#424242] [&_p]:font-normal [&_p]:text-[18px] [&_p]:leading-[23.44px] [&_p]:tracking-[-0.02em]">
                  <SimpleMarkdown>{LongParagraph}</SimpleMarkdown>
                </div>
              ) : null}
              {HasChecklist ? (
                <ul className="text-[#424242] font-normal text-[18px] leading-[28.8px] tracking-[-0.02em] space-y-[16px]">
                  {CheckList.map((c) => {
                    const { CheckListItem, id } = c;
                    return (
                      <li
                        key={id}
                        className="flex flex-row items-center gap-[16px]"
                      >
                        <GlobalIcon
                          iconName="FilledCheckIcon"
                          size="XS"
                          color={ChecklistColor}
                        />
                        <span>{CheckListItem}</span>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
              {HasIconList ? (
                <ul className="text-[#424242] font-normal text-[18px] leading-[28.8px] tracking-[-0.02em] space-y-[16px]">
                  {IconList.map((i) => {
                    const { IconType, Text, id } = i;
                    return (
                      <li
                        key={id}
                        className="flex flex-row items-center gap-[16px]"
                      >
                        <span
                          className="rounded-full md:p-[8px] p-[4px] md:[&_svg]:w-[24px] md:[&_svg]:h-[24px] [&_svg]:w-[18px] [&_svg]:h-[18px]"
                          style={{ backgroundColor: IconListBackgroundColor }}
                        >
                          <GlobalIcon
                            iconName={IconListIcon[IconType]}
                            size="XS"
                            color={"#3430E3"}
                          />
                        </span>
                        <span>{Text}</span>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </div>
          <HeroPrimaryButtons data={data} />
          {HasBelowTrustpilotRating ? (
            <TrustPilotDS
              CurrentRateLabel={BelowTrustpilotScore}
              OutOfLabel={BelowExcellentText}
              className="md:mt-[32px] mt-[24px] w-full"
              TrustpilotLink={TrustpilotLink}
            />
          ) : null}
        </div>
        <div
          className={`heroCalculatorImage_ImageHeight md:col-start-7 md:col-end-13 relative`}
          style={
            {
              "--heroCalculatorImageHeight": imageHeight,
              "--heroCalculatorImageOffset": imageOffset,
            } as React.CSSProperties
          }
        >
          {DesktopImageURL ? (
            <img
              className={`h-full absolute w-[100vw] md:w-[50vw] overflow-hidden hidden md:block max-w-none object-cover object-left ${
                ImageHasRadius ? "rounded-s-full" : ""
              }`}
              src={DesktopImageURL}
              alt={DesktopImageAlt}
            />
          ) : (
            <GatsbyImage
              className={`h-full absolute w-[100vw] md:w-[50vw] overflow-hidden hidden md:block ${
                ImageHasRadius ? "rounded-s-full" : ""
              }`}
              image={DesktopImage?.file?.childImageSharp?.gatsbyImageData}
              alt={DesktopImageAlt}
              objectPosition={"left"}
            />
          )}
          {MobileImageURL ? (
            <img
              className={`h-full absolute w-[100vw] md:w-[50vw] overflow-hidden md:hidden max-w-none object-cover object-left ${
                ImageHasRadius ? "rounded-s-full" : ""
              }`}
              src={MobileImageURL}
              alt={MobileImageAlt}
            />
          ) : (
            <GatsbyImage
              className={`h-full absolute w-[100vw] md:w-[50vw] overflow-hidden md:hidden ${
                ImageHasRadius ? "rounded-s-full" : ""
              }`}
              image={MobileImage?.file?.childImageSharp?.gatsbyImageData}
              alt={MobileImageAlt}
              objectPosition={"left"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const HeroPrimaryButtons = ({ data }: HeroPrimaryI) => {
  const {
    HasPrimaryButton,
    HasSecondaryButton,
    PrimaryButtonID,
    PrimaryButtonLabel,
    PrimaryButtonNewTab,
    PrimaryButtonNoFollow,
    PrimaryButtonURL,
    SecondaryButtonID,
    SecondaryButtonIconTypes,
    SecondaryButtonTypes,
    SecondaryButtonURL,
    SecondaryButtonNoFollow,
    SecondaryButtonNewTab,
    SecondaryButtonLabel,
    ButtonsAreaLayout,
    SecondaryButtonHasIcon,
  } = data;

  const IconType: { [key: string]: IconNameT } = {
    Phone: "PhoneCallIcon",
    "Right-arrow": "ArrowRightIcon",
  };

  if (HasPrimaryButton || HasSecondaryButton)
    return (
      <div
        className="flex flex-wrap gap-[32px] md:pt-[24px] md:mt-[32px] mt-[24px] whitespace-nowrap"
        style={{
          flexDirection:
            ButtonsAreaLayout?.Layout === "Vertical" ? "column" : "row",
        }}
      >
        {HasPrimaryButton ? (
          <Button
            id={PrimaryButtonID}
            label={PrimaryButtonLabel}
            href={PrimaryButtonURL}
            noFollow={PrimaryButtonNoFollow}
            newTab={PrimaryButtonNewTab}
            rightIcon="ArrowRightIcon"
            className="!flex-1"
            wrapperClassname="!flex-1"
          />
        ) : null}
        {HasSecondaryButton ? (
          <Button
            secondary={SecondaryButtonTypes?.ButtonType === "Secondary_button"}
            ghost={SecondaryButtonTypes?.ButtonType === "Ghost_button"}
            id={SecondaryButtonID}
            label={SecondaryButtonLabel}
            href={SecondaryButtonURL}
            newTab={SecondaryButtonNewTab}
            noFollow={SecondaryButtonNoFollow}
            leftIcon={
              SecondaryButtonHasIcon
                ? (IconType[SecondaryButtonIconTypes.IconType] as IconNameT)
                : undefined
            }
            className="!flex-1"
            wrapperClassname="!flex-1"
          />
        ) : null}
      </div>
    );

  return null;
};

export default HeroPrimary;
