import React from "react";
import { BackgroundColorI, StrapiImage, TitleType } from "../Types";
import { getTitleHTMLTag } from "../../helpers/tools";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../Inputs/Button";
import SimpleMarkdown from "../SimpleMarkdown/SimpleMarkdown";

interface IconCardsI {
  data: {
    HasSubtitle: boolean;
    HasTitle: boolean;
    SansTitle?: string;
    SerifTitle?: string;
    Subtitle?: string;
    TitleHeading: {
      TitleTypeSelection: TitleType;
    };
    BackgroundColor: BackgroundColorI;
    CardsContent: {
      CardIcon: StrapiImage;
      CardIconAlt: string;
      CardIconURL: string;
      CardText: string;
      CardTitle: string;
      CardTitleHeading: {
        TitleTypeSelection: TitleType;
      };
      GhostButtonID: string;
      GhostButtonLabel: string;
      GhostButtonURL: string;
      GhostNewTab: boolean;
      GhostNoFollow: boolean;
      HasGhostButton: string;
      HasPrimaryButton: string;
      PrimaryButtonID: string;
      PrimaryButtonLabel: string;
      PrimaryButtonURL: string;
      PrimaryNewTab: boolean;
      PrimaryNoFollow: boolean;
      PrimaryHasArrow: boolean;
    }[];
  };
}

const IconCards = ({ data }: IconCardsI) => {
  const {
    HasSubtitle,
    HasTitle,
    SansTitle,
    SerifTitle,
    Subtitle,
    TitleHeading,
    BackgroundColor,
    CardsContent,
  } = data;

  const backgroundColor = {
    Blue_Gray: "#F5F7FA",
    Light_Blue: "#ECF0FF",
    White: "#FFFFFF",
  }[BackgroundColor.BackgroundColor || "White"];

  const cardBackgroundColor = {
    Blue_Gray: "#FFFFFF",
    Light_Blue: "#FFFFFF",
    White: "#F5F7FA",
  }[BackgroundColor.BackgroundColor || "White"];

  const cardIconBackgroundColor = {
    Blue_Gray: "#F5F7FA",
    Light_Blue: "#F5F7FA",
    White: "#FFFFFF",
  }[BackgroundColor.BackgroundColor || "White"];

  const titleC = HasTitle
    ? getTitleHTMLTag(
        <>
          <span className="font-DM font-bold md:text-[30px] text-[24px] md:leading-[36px] leading-[32px] md:tracking-[-0.018em] tracking-[-0.016em]">
            {SansTitle}{" "}
          </span>
          <span className="font-MW font-light md:text-[27px] text-[21px] md:leading-[36px] leading-[32px] md:tracking-[-0.018em] tracking-[-0.016em]">
            {SerifTitle}
          </span>
        </>,
        TitleHeading?.TitleTypeSelection,
        "text-[#424242] md:text-center"
      )
    : null;

  const subtitleC = HasSubtitle
    ? getTitleHTMLTag(
        <span className="font-DM font-normal md:text-[22px] text-[18px] md:leading-[28.64px] leading-[23.44px] tracking-[-0.02em]">
          {Subtitle}
        </span>,
        "p",
        "text-[#424242] md:text-center"
      )
    : null;

  return (
    <section className="overflow-hidden" style={{ backgroundColor }}>
      <div className="flex flex-col max-w-content mx-auto py-[64px] xl:px-[0px] px-[24px] md:gap-[64px] gap-[32px]">
        <div className="flex flex-col md:gap-[16px] gap-[8px]">
          {titleC}
          {subtitleC}
        </div>
        {CardsContent && CardsContent.length > 0 ? (
          <div className="flex flex-row flex-wrap gap-[32px] justify-center">
            {CardsContent.map((content, idx) => (
              <CardContent
                data={content}
                backgroundColor={cardBackgroundColor}
                iconBackgroundColor={cardIconBackgroundColor}
                key={`${content.CardTitle}_${idx}`}
              />
            ))}
          </div>
        ) : null}
      </div>
    </section>
  );
};

const CardContent = ({ data, backgroundColor, iconBackgroundColor }) => {
  const {
    CardIcon,
    CardIconAlt,
    CardIconURL,
    CardText,
    CardTitle,
    CardTitleHeading,
    GhostButtonID,
    GhostButtonLabel,
    GhostButtonURL,
    GhostNewTab,
    GhostNoFollow,
    HasGhostButton,
    HasPrimaryButton,
    PrimaryButtonID,
    PrimaryButtonLabel,
    PrimaryButtonURL,
    PrimaryNewTab,
    PrimaryNoFollow,
    PrimaryHasArrow
  } = data;

  const titleC = getTitleHTMLTag(
    CardTitle,
    CardTitleHeading?.TitleTypeSelection,
    "text-[#424242] text-center w-full font-DM font-bold md:text-[22px] text-[18px] md:leading-[28.64px] leading-[23.44px] tracking-[-0.02em]"
  );
  
  return (
    <div
      className="p-[24px] rounded-[24px] flex flex-col gap-[20px] items-center flex-[352px] max-w-[352px]"
      style={{ backgroundColor }}
    >
      <div
        className="w-[76px] h-[76px] rounded-full my-[12px] p-[8px]"
        style={{ backgroundColor: iconBackgroundColor }}
      >
        {CardIcon ? (
          <GatsbyImage
            image={CardIcon?.file?.childImageSharp?.gatsbyImageData}
            alt={CardIconAlt}
            className="w-[60px] h-[60px]"
          />
        ) : (
          <img
            src={CardIconURL}
            alt={CardIconAlt}
            className="w-[60px] h-[60px]"
          />
        )}
      </div>
      {titleC}
      <div className="text-[#555555] text-center w-full font-DM font-normal md:text-[18px] text-[16px] md:leading-[28.8px] leading-[25.6px] tracking-[-0.02em] [&_p]:mb-0 md:[&_p:not(:last-child)]:mb-[24px] [&_p:not(:last-child)]:mb-[12px] [&_p]:text-[#424242] [&_p]:font-normal [&_p]:md:text-[18px] [&_p]:text-[16px] [&_p]:md:leading-[28.8px] [&_p]:leading-[25.6px] [&_p]:tracking-[-0.02em]">
        <SimpleMarkdown>{CardText}</SimpleMarkdown>
      </div>
      <div className="flex flex-col gap-[8px] w-full mt-auto">
        {HasPrimaryButton ? (
          <Button
            label={PrimaryButtonLabel}
            href={PrimaryButtonURL}
            ghost={false}
            newTab={PrimaryNewTab}
            noFollow={PrimaryNoFollow}
            id={PrimaryButtonID}
            rightIcon={PrimaryHasArrow ? "ArrowRightIcon" : undefined}
            size="40"
          />
        ) : null}
        {HasGhostButton ? (
          <Button
            label={GhostButtonLabel}
            href={GhostButtonURL}
            ghost={true}
            newTab={GhostNewTab}
            noFollow={GhostNoFollow}
            id={GhostButtonID}
            size="40"
          />
        ) : null}
      </div>
    </div>
  );
};

export default IconCards;
