import React from "react";
import MaxContent from "../../layout/MaxContent";
import { IconNameT } from "get-life-storybook-ts/lib/components/Icons/GlobalIcon";
import Button from "../Inputs/Button";
import { BackgroundColorI } from "../Types";

interface ButtonsI {
  data: {
    BackgroundColor: BackgroundColorI;
    HasPrimaryButton: boolean;
    PrimaryButtonID: string;
    PrimaryButtonLabel: string;
    PrimaryButtonNewTab: boolean;
    PrimaryButtonNoFollow: boolean;
    PrimaryButtonURL: string;
    HasSecondaryButton: boolean;
    SecondaryButtonConfigurationIcon: "Right" | "Left";
    SecondaryButtonHasIcon: boolean;
    SecondaryButtonIconType: "Phone" | "Right-arrow";
    SecondaryButtonID: string;
    SecondaryButtonLabel: string;
    SecondaryButtonNewTab: boolean;
    SecondaryButtonNoFollow: boolean;
    SecondaryButtonType: "Ghost_button" | "Secondary_button";
    SecondaryButtonURL: string;
  };
}

const Buttons = ({ data }: ButtonsI) => {
  const {
    BackgroundColor,
    HasPrimaryButton,
    PrimaryButtonID,
    PrimaryButtonLabel,
    PrimaryButtonNewTab,
    PrimaryButtonNoFollow,
    PrimaryButtonURL,
    HasSecondaryButton,
    SecondaryButtonConfigurationIcon,
    SecondaryButtonHasIcon,
    SecondaryButtonIconType,
    SecondaryButtonID,
    SecondaryButtonLabel,
    SecondaryButtonNewTab,
    SecondaryButtonNoFollow,
    SecondaryButtonType,
    SecondaryButtonURL,
  } = data;

  const backgroundColor =
    {
      Blue_Gray: "#F5F7FA",
      Light_Blue: "#ECF0FF",
      White: "#FFFFFF",
    }[BackgroundColor.BackgroundColor || "White"];

  const IconType: { [key: string]: IconNameT } = {
    Phone: "PhoneCallIcon",
    "Right-arrow": "ArrowRightIcon",
  };

  const SecondaryType = {
    Ghost_button: {
      secondary: false,
      ghost: true,
    },
    Secondary_button: {
      secondary: true,
      ghost: false,
    },
  };

  const GetSecondaryIcon = (alignment = "Left") => {
    if (SecondaryButtonHasIcon && SecondaryButtonIconType) {
      if (SecondaryButtonConfigurationIcon === alignment)
        return IconType[SecondaryButtonIconType];
      if (SecondaryButtonConfigurationIcon === alignment)
        return IconType[SecondaryButtonIconType];
    }
    return undefined;
  };

  return (
    <MaxContent
      size="full"
      className={`py-[32px] flex justify-center items-center gap-[32px] bg-[${backgroundColor}]`}
    >
      <div className="max-w-content w-full px-content xl:px-0 flex flex-wrap justify-center items-center gap-[32px]">
        {HasPrimaryButton ? (
          <Button
            id={PrimaryButtonID}
            label={PrimaryButtonLabel}
            href={PrimaryButtonURL}
            noFollow={PrimaryButtonNoFollow}
            newTab={PrimaryButtonNewTab}
            rightIcon="ArrowRightIcon"
            className="!w-fit text-center"
            wrapperClassname="!w-fit"
          />
        ) : null}

        {HasSecondaryButton ? (
          <Button
            secondary={SecondaryType[SecondaryButtonType].secondary}
            ghost={SecondaryType[SecondaryButtonType].ghost}
            id={SecondaryButtonID}
            label={SecondaryButtonLabel}
            href={SecondaryButtonURL}
            newTab={SecondaryButtonNewTab}
            noFollow={SecondaryButtonNoFollow}
            leftIcon={GetSecondaryIcon("Left")}
            rightIcon={GetSecondaryIcon("Right")}
            className="!w-fit text-center"
            wrapperClassname="!w-fit"
          />
        ) : null}
      </div>
    </MaxContent>
  );
};

export default Buttons;
