import * as React from "react";
import * as ES from "../resources/i18n/es.json";
import * as FR from "../resources/i18n/fr.json";
import * as PT from "../resources/i18n/pt.json";
import * as IT from "../resources/i18n/it.json";

//Primera version del Contexto de Idioma

export const translations: any = {
  es: ES,
  fr: FR,
  pt: PT,
  it: IT
};

const userLanguage: string | undefined = process.env.GATSBY_APP_LOCALE;

export const translate = (key: string) => {
  return translations[userLanguage!].default[key] || key;
}