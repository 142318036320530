import React, { useState, ReactElement } from "react";
import { useSpringCarousel } from "react-spring-carousel";
import { useDevice } from "../Hooks";
import MaxContent from "../../layout/MaxContent";

interface CarouselProps {
  children?: Array<ReactElement>;
  onValueChange?: (v: any) => void;
  numberChildren?: number;
}

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const [activeItem, setActiveItem] = useState<number>(0);
  const { isMobileTablet } = useDevice();
  const cards = React.Children.toArray(children);
  const [itemSlide, setItemSlide] = useState<number>(cards.length);
  const mobileDotsNumberArray = Array.from(
    { length: cards.length },
    (_, i) => i
  );

  const { carouselFragment, getCurrentActiveItem, useListenToCustomEvent } =
    useSpringCarousel({
      slideType: "fixed",
      initialActiveItem: 0,
      shouldResizeOnWindowResize: true,
      itemsPerSlide: itemSlide,
      gutter: 10,
      // @ts-ignore
      items: cards.map((card, index) => ({ id: index, renderItem: card })),
    });

  useListenToCustomEvent((event) => {
    if (event.eventName === "onSlideChange") {
      let active = getCurrentActiveItem();
      setActiveItem(active.index);
    }
  });

  React.useEffect(() => {
    setItemSlide(isMobileTablet ? 1.9 : cards.length);
  }, [isMobileTablet]);

  return (
    <MaxContent size="max">
      {/* Mobile Carousel */}
      <div className={` flex flex-row w-full overflow-hidden hide-scroll`}>
        {carouselFragment}
      </div>

      {/* Mobile Dots */}

      <div
        className={`flex flex-row w-full justify-center ${
          isMobileTablet ? "" : "hidden"
        } p-5 space-x-1 transition`}
      >
        {mobileDotsNumberArray.map((key) => (
          <div
            className={`w-full h-[3px] ${
              activeItem === key ? "bg-purple" : "bg-[var(--primary-color)20]"
            }`}
            key={key + "Carousel"}
          />
        ))}
      </div>
    </MaxContent>
  );
};

export default Carousel;
