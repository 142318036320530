import React from "react";
import {
  BirthDateInput,
  ButtonDS,
  CheckboxAtom,
  DropDown,
  GlobalIcon,
  Input,
  SingleSelect,
  TextWithInfoIcon,
  Typography,
} from "get-life-storybook-ts";
import { getTitleHTMLTag } from "../../helpers/tools";
import { TitleType } from "../Types";
import CalculatorModuleController from "../CalculatorModule/CalculatorModule.controller";
import MaxContent from "../../layout/MaxContent";

interface EmbeddedCalculatorProps {
  data: {
    CalculatorTitle: string;
    SelectTitleType: {
      TitleTypeSelection: TitleType;
    };
    HasTitle: boolean;
    CalculatorUrl: string;
    BackgroundColor: "blueGray" | "lightBlue" | "white";
    CTAStepOneLabel: string;
    CTAStepOneIcon: boolean;
    CTAStepTwoLabel: string;
    CTAStepTwoIcon: boolean;
  };
}

const EmbeddedCalculator = ({ data }: EmbeddedCalculatorProps) => {
  const {
    actualStep,
    capitalOptions,
    formDataFirst,
    formDataSecond,
    formError,
    handleBlur,
    handleButton,
    handleChange,
    handleFocus,
    isMobile,
    loading,
    optionsIntention,
    setActualStep,
    setFormDataFirst,
    setFormDataSecond,
  } = CalculatorModuleController({ data: [{ Url: data.CalculatorUrl }] });

  const backgroundColors = {
    Blue_Gray: "#F5F7FA",
    Light_Blue: "#ECF0FF",
    White: "#FFFFFF",
  };

  const titleColors = {
    Blue_Gray: "var(--primary-color)",
    Light_Blue: "#424242",
    White: "var(--primary-color)",
  };

  const updateStep = (s: number) =>
    actualStep !== s ? setActualStep(s) : null;

  let title = getTitleHTMLTag(
    data.CalculatorTitle,
    data.SelectTitleType?.TitleTypeSelection || "p",
    `text-[22px] md:text-[32px] font-[700] tracking-[-0.64px] text-[${
      titleColors[data.BackgroundColor]
    }]`
  );

  return (
    <MaxContent size="content">
      <div
        className="px-[16px] pt-[24px] pb-[32px] md:px-[24px] md:pt-[32px] md:pb-[40px] rounded-[8px] shadow-[0px_6px_10px_0px_rgba(0,_0,_0,_0.07),_0px_1px_18px_0px_rgba(0,_0,_0,_0.06),_0px_3px_5px_0px_rgba(0,_0,_0,_0.10)]"
        style={{ backgroundColor: backgroundColors[data.BackgroundColor] }}
      >
        {data.HasTitle && title}
        <Typography
          variant="BodySmall"
          weight="500"
          text=""
          className="flex flex-row flex-wrap gap-[8px] text-[#7B7B7B] mt-[16px]"
        >
          <span
            role="button"
            onClick={() => updateStep(1)}
            onKeyDown={(e) => {
              if (e.key === " " || e.key === "Enter") {
                e.preventDefault();
                updateStep(1);
              }
            }}
          >
            <Typography
              variant="SpanSmall"
              weight="500"
              style={actualStep === 1 ? { color: "#3430E3" } : undefined}
              text=""
            >
              Paso 1
            </Typography>
          </span>
          <span>{">"}</span>
          <span
            role="button"
            onClick={() => updateStep(2)}
            onKeyDown={(e) => {
              if (e.key === " " || e.key === "Enter") {
                e.preventDefault();
                updateStep(2);
              }
            }}
          >
            <Typography
              variant="SpanSmall"
              weight="500"
              style={actualStep === 2 ? { color: "#3430E3" } : undefined}
              text=""
            >
              Paso 2
            </Typography>
          </span>
          <span>{">"}</span>
          <span>Tu precio</span>
        </Typography>
        <div className="mt-[32px] grid grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[24px] md:gap-y-[48px] [&_input]:bg-white">
          {actualStep === 1 && (
            <>
              <BirthDateInput
                label="Fecha de Nacimiento"
                initialValue={formDataFirst.birthDate}
                handleFocus={handleFocus}
                setter={(birthDate: string) =>
                  setFormDataFirst({
                    ...formDataFirst,
                    birthDate,
                  })
                }
                birthDateError={formError?.birthDate}
              />
              <div className="flex flex-col w-full">
                <Typography
                  variant="Body"
                  text="Capital de cobertura"
                  color="gray-text"
                />
                <div className="flex flex-col gap-4 [&_button]:border-[color:#e2e2e2]">
                  <DropDown
                    isSimple
                    bgWhite
                    initialValue={(formDataFirst.capital || "") as string}
                    onValueChange={(e: any) =>
                      handleChange({
                        setValue: setFormDataFirst,
                        value: e,
                        field: "capital",
                        formData: formDataFirst,
                      })
                    }
                    options={capitalOptions}
                    disabled={capitalOptions.length === 0}
                    className="w-full"
                    placeholder="Seleccione una opción"
                    initialError={formError?.capital}
                  />
                </div>
                <TextWithInfoIcon
                  content={"Aconsejamos 8 veces tu salario anual"}
                  overlayPrice={<OverlayCapital isMobile={isMobile} />}
                  overlayTitle={
                    <Typography
                      variant="Body"
                      weight="700"
                      text="¿Qué es el capital de cobertura?"
                      className="text-[18px] md:text-[22px] leading-[120%] tracking-[-0.02em]"
                    />
                  }
                  overlayWidth={false}
                  infoIcon
                  position="left"
                  color="ligth-gray"
                  weight="400"
                  className="mt-[8px] text-[color:#6F6F6F] [&_*]:text-left [&_*]:stroke-[#6F6F6F] [&_*]:m-0 [&_*]:gap-[4px] [&_svg>*]:stroke-[1.5px] md:[&_p]:text-[16px] [&_p]:text-[14px]"
                />
              </div>
              <div className="flex flex-col w-full">
                <Typography
                  variant="Body"
                  text="¿Qué quieres proteger?"
                  color="gray-text"
                />
                <div className="flex flex-col gap-4 [&_.selectButton]:min-h-[56px]">
                  <SingleSelect
                  name="intention_select"
                    initialValue={formDataFirst.intention}
                    onValueChange={(e: any) =>
                      handleChange({
                        setValue: setFormDataFirst,
                        value: e,
                        field: "intention",
                        formData: formDataFirst,
                      })
                    }
                    options={optionsIntention}
                    defaultVariant="Standard"
                    defaultSize="S"
                  />
                  {
                    formError?.intention?.message &&
                    <Typography text={formError?.intention?.message} variant="BodySmall" weight="400" className="text-red" />
                  }
                </div>
                <TextWithInfoIcon
                  content={"¿Por qué hacemos esta pregunta?"}
                  overlayPrice={<OverlayProtection isMobile={isMobile} />}
                  overlayTitle={
                    <Typography
                      variant="Body"
                      weight="700"
                      text="¿Por qué preguntamos sobre qué quieres proteger?"
                      className="text-[18px] md:text-[22px] leading-[120%] tracking-[-0.02em]"
                    />
                  }
                  overlayWidth={false}
                  infoIcon
                  position="left"
                  weight="400"
                  className="mt-[8px] text-[color:#6F6F6F] [&_*]:text-left [&_*]:stroke-[#6F6F6F] [&_*]:m-0 [&_*]:gap-[4px] [&_svg>*]:stroke-[1.5px] md:[&_p]:text-[16px] [&_p]:text-[14px]"
                />
              </div>
              <div className="flex flex-col w-full mt-[8px] md:m-0">
                <Typography
                  variant="Body"
                  text="¿Qué quieres proteger?"
                  color="gray-text"
                  className="hidden invisible md:block"
                />
                <ButtonDS
                  label={data.CTAStepOneLabel}
                  onClick={() => handleButton(false)}
                  disabled={loading}
                  rightIcon={data.CTAStepOneIcon ? "ArrowRightIcon" : undefined}
                  className="h-fit"
                />
              </div>
            </>
          )}
          {actualStep === 2 && (
            <>
              <div className="flex flex-col w-full">
                <Typography variant="Body" text="Email" color="gray-text" />
                <div className="flex flex-row gap-4">
                  <Input
                    initialValue={formDataSecond.email}
                    inputMode="email"
                    autoFocus
                    onBlur={() =>
                      handleBlur({ field: "email", formData: formDataSecond })
                    }
                    onValueChange={(e: any) =>
                      handleChange({
                        setValue: setFormDataSecond,
                        value: e,
                        field: "email",
                        formData: formDataSecond,
                      })
                    }
                    placeholder="Escribe tu correo electrónico"
                    initialError={formError?.email}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full">
                <Typography variant="Body" text="Teléfono" color="gray-text" />
                <div className="flex flex-row gap-4">
                  <Input
                    initialValue={formDataSecond.phone}
                    inputMode="number"
                    pattern="\d*"
                    onBlur={() =>
                      handleBlur({ field: "phone", formData: formDataSecond })
                    }
                    onValueChange={(e: any) =>
                      handleChange({
                        setValue: setFormDataSecond,
                        value: e,
                        field: "phone",
                        formData: formDataSecond,
                      })
                    }
                    placeholder="Escribe tu número de teléfono"
                    initialError={formError?.phone}
                  />
                </div>
                <TextWithInfoIcon
                  content={"¿Por qué te pedimos estos datos?"}
                  overlayPrice={<OverlayData isMobile={isMobile} />}
                  overlayTitle={
                    <Typography
                      variant="Body"
                      weight="700"
                      text="¿Por qué te pedimos estos datos?"
                      className="text-[18px] md:text-[22px] leading-[120%] tracking-[-0.02em]"
                    />
                  }
                  overlayWidth={false}
                  infoIcon
                  position="left"
                  weight="400"
                  className="mt-[8px] text-[color:#6F6F6F] [&_*]:text-left [&_*]:stroke-[#6F6F6F] [&_*]:m-0 [&_*]:gap-[4px] [&_svg>*]:stroke-[1.5px] md:[&_p]:text-[16px] [&_p]:text-[14px]"
                />
              </div>
              <div className="flex flex-col w-full [&_*]:!p-0">
                <CheckboxAtom
                  label={checkBoxQuery}
                  onValueChange={(checkedConditions: boolean) =>
                    handleChange({
                      setValue: setFormDataSecond,
                      value: checkedConditions,
                      field: "checkedConditions",
                      formData: formDataSecond,
                    })
                  }
                  isLegal
                  errorCheck={formError?.checkedConditions}
                  className="!p-0"
                />
              </div>
              <div className="flex flex-col w-full [&_*]:!p-0">
                <CheckboxAtom
                  label={checkBoxQuery2}
                  onValueChange={(checkedConditions2: boolean) =>
                    handleChange({
                      setValue: setFormDataSecond,
                      value: checkedConditions2,
                      field: "checkedConditions2",
                      formData: formDataSecond,
                    })
                  }
                  isLegal
                  errorCheck={formError?.checkedConditions2}
                  className="!p-0"
                />
              </div>
              <ButtonDS
                label={data.CTAStepTwoLabel}
                onClick={() => handleButton(false)}
                disabled={loading}
                rightIcon={data.CTAStepTwoIcon ? "ArrowRightIcon" : undefined}
                className="h-fit"
              />
            </>
          )}
        </div>
      </div>
    </MaxContent>
  );
};

const checkBoxQuery = (
  <>
    <div className="flex flex-col">
      <Typography
        variant="BodyMedium"
        text="Acepto el tratamiento de mis datos para recibir la información solicitada."
        color="ligth-gray"
      />
      <Typography
        variant="LinkMedium"
        text="Ver política de privacidad"
        color="ligth-gray"
        target="_blank"
        href="https://life5.es/politica-privacidad/"
      />
    </div>
  </>
);

const checkBoxQuery2 = (
  <>
    <div className="flex flex-col">
      <Typography
        variant="BodyMedium"
        text="Como persona asegurada voy a contestar veraz y detalladamente a cada pregunta de salud. No declarar todos mis antecedentes médicos podrá suponer la pérdida del derecho a la indemnización en caso de siniestro."
        color="ligth-gray"
      />
      <Typography
        variant="LinkMedium"
        text="Más información sobre dolo en seguros de vida"
        color="ligth-gray"
        target="_blank"
        href="https://life5.es/blog/seguros/dolo/"
      />
    </div>
  </>
);


interface OverlayI {
  isMobile?: boolean;
}

const OverlayCapital: React.FC<OverlayI> = ({ isMobile }) => {
  return (
    <div className="flex flex-col gap-4">
      <GlobalIcon iconName="CalculatorIcon" size={isMobile ? "M" : "L"} />
      <Typography
        variant="Body"
        text="El capital de cobertura es el dinero bajo el que te aseguras y el dinero que recibirá la persona o entidad que desees."
      />
      <Typography
        variant="Body"
        text="Puedes cambiarlo más adelante para que puedas comparar y elegir lo que más se ajuste a tus necesidades."
      />
    </div>
  );
};

const OverlayProtection: React.FC<OverlayI> = ({ isMobile }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <GlobalIcon iconName="PuzzlePieceIcon" size={isMobile ? "M" : "L"} />
        <Typography
          variant="Body"
          text="En Life5 tenemos distintos tipos de cobertura de acuerdo a tus necesidades:"
        />
      </div>
      <div className="flex flex-row gap-4">
        <GlobalIcon iconName="HumourIcon" size={isMobile ? "M" : "L"} />

        <div className="flex flex-col gap-1">
          <Typography
            variant="Body"
            className="text-[18px] md:text-[22px] leading-[120%] tracking-[-0.02em]"
            weight="700"
            text="Seguro de vida para familia"
            color="purple"
          />
          <Typography
            variant="Body"
            text="Enfocado en cubrir a tu familia si llegarás a faltar el día de mañana."
          />
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <GlobalIcon iconName="SaveMoneyIcon" size={isMobile ? "M" : "L"} />

        <div className="flex flex-col gap-1">
          <Typography
            variant="Body"
            className="text-[18px] md:text-[22px] leading-[120%] tracking-[-0.02em]"
            weight="700"
            text="Seguro de vida para hipotecas y/o préstamos"
            color="purple"
          />
          <Typography
            variant="Body"
            text="Enfocado en cubrir tus deudas si llegarás a faltar el día de mañana."
          />
        </div>
      </div>
    </div>
  );
};

const OverlayData: React.FC<OverlayI> = ({ isMobile }) => {
  return (
    <div className="flex flex-col gap-4">
      <Typography
        variant="Body"
        text="En Life5 tu información es confidencial, cuidamos tus datos y no los compartimos con otros."
      />
      <div className="flex flex-row gap-4">
        <GlobalIcon iconName="EmailIcon" size={isMobile ? "M" : "L"} />

        <div className="flex flex-col gap-1">
          <Typography
            variant="Body"
            className="text-[18px] md:text-[22px] leading-[120%] tracking-[-0.02em]"
            weight="700"
            text="Tu email"
            color="purple"
          />
          <Typography
            variant="Body"
            text="Para enviarte todos los pasos del proceso. Somos transparentes, queremos que tengas toda la documentación pertinente."
          />
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <GlobalIcon iconName="CallIcon" size={isMobile ? "M" : "L"} />

        <div className="flex flex-col gap-1">
          <Typography
            variant="Body"
            className="text-[18px] md:text-[22px] leading-[120%] tracking-[-0.02em]"
            weight="700"
            text="Tu teléfono"
            color="purple"
          />
          <Typography
            variant="Body"
            text="Para poder ayudarte y asesorarte, nuestro objetivo es que encuentres el mejor producto para ti."
          />
        </div>
      </div>
    </div>
  );
};

export default EmbeddedCalculator;
