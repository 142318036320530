import React from "react";
import { TitleType } from "../Types";
import { useBreadCrumbs } from "../Hooks";
import { Link } from "gatsby";
import { getTitleHTMLTag } from "../../helpers/tools";

interface HeroMinimalistI {
  data: {
    HasBreadcrumb: boolean;
    HasSerifTitle: boolean;
    HeroSansTitle: string;
    HeroSerifTitle: string;
    HeroTitleHeading: {
      TitleTypeSelection: TitleType;
    };
  };
}

const HeroMinimalist = ({ data }: HeroMinimalistI) => {
  const { breadcrumbs } = useBreadCrumbs();

  const {
    HasBreadcrumb,
    HasSerifTitle,
    HeroSansTitle,
    HeroSerifTitle,
    HeroTitleHeading,
  } = data;

  const sansTitle = getTitleHTMLTag(
    <span className="font-DM font-bold md:text-[48px] text-[32px] md:leading-[48px] leading-[41.66px] tracking-[-0.02em]">
      {HeroSansTitle}
    </span>,
    HeroTitleHeading?.TitleTypeSelection,
    "text-[#424242] flex flex-col"
  );

  const serifTitle = HasSerifTitle
    ? getTitleHTMLTag(
        <span className="font-MW font-light md:text-[45px] text-[30px] md:leading-[56.57px] leading-[37.71px] tracking-[-0.02em]">
          {HeroSerifTitle}
        </span>,
        HeroTitleHeading?.TitleTypeSelection,
        "text-[#424242] flex flex-col"
      )
    : null;

  return (
    <div className="overflow-hidden bg-[#F5F7FA]">
      <div className="flex flex-col max-w-content mx-auto md:py-[64px] pt-[32px] pb-[48px] xl:px-[0px] px-[24px] ">
        {HasBreadcrumb ? (
          <div className="flex flex-row md:mb-[40px] mb-[32px] font-medium">
            {breadcrumbs.map((v, i) => {
              if (i < breadcrumbs.length - 1) {
                return (
                  <div className="flex flex-row" key={i}>
                    <Link
                      to={v.link}
                      className={
                        "text-[14px] leading-[18px] font-normal text-[#7B7B7B] mb-3 md:w-auto no-underline"
                      }
                      key={i}
                    >
                      <span>{v.name}</span>
                    </Link>
                    <span
                      className={
                        "text-[14px] leading-[18px] px-2.5 text-[#7B7B7B] font-normal"
                      }
                    >
                      |
                    </span>
                  </div>
                );
              } else {
                return (
                  <span
                    className={
                      "text-[14px] leading-[18px] text-[#424242]"
                    }
                    key={i}
                  >
                    {v.name}
                  </span>
                );
              }
            })}
          </div>
        ) : null}
        <div className="flex flex-col gap-[8px] w-full max-w-[736px]">
        {sansTitle}
        {serifTitle}
        </div>
      </div>
    </div>
  );
};

export default HeroMinimalist;
