import React from "react";
import MaxContent from "../../layout/MaxContent";
import { GatsbyImage } from "gatsby-plugin-image";
import { StrapiImage, TitleType } from "../Types";
import TrustPilotStaticWidget from "../TrustPilot/TrustPilotStaticWidget";
import AnimatedBubble from "../AnimatedBubble/AnimatedBubble";
import { useDevice } from "../Hooks";
import CalculatorModuleExample from "../CalculatorModule/CalculatorExample";
import { getTitleHTMLTag } from "../../helpers/tools";

const tick =
  "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/blue-tick.svg";
interface StrapiData {
  data: HeaderProps;
}

interface HeaderProps {
  Title: string;
  SelectTitleType: {
    TitleTypeSelection: TitleType;
  }
  TrustPilotScore: string;
  Icon: StrapiImage;
  AnimatedBubbles: boolean;
  CalculatorModule: {
    Url: string
  } [];
  ImageURL?: string;
  AlternativeText?: string;
  noSignUp?: boolean;
}

const HeaderCalculator: React.FC<StrapiData> = ({ data }) => {
  const { isMobile } = useDevice();

  let title = getTitleHTMLTag(
    data.Title + " ",
    data.SelectTitleType?.TitleTypeSelection || "H1",
    "h1-default text-purple mb-3 w-full md:w-auto"
  );

  const prepareDataForCalculator = () => {
    return data?.CalculatorModule.map((cm) => {
      return { Url: cm.Url, noSignUp: data?.noSignUp };
    });
  };

  return (
    <MaxContent size="full">
      <div className="bg-white w-full overflow-hidden text-purple">
        <MaxContent size="content">
          <div className="flex flex-col-reverse md:grid-cols-2 md:grid bg-white">
            <div className="text-left mt-2 lg:mt-20 max-w-md">
              {data.Title ? title : null}
              <div className="flex flex-col mt-7">
                <CalculatorModuleExample data={prepareDataForCalculator()} />
              </div>

              {!isMobile && (
                <div className="flex flex-col items-center mt-10 mb-[60px] md:mb-[90px]">
                  {/* <TrustPilotWidget /> */}
                  <TrustPilotStaticWidget score={data?.TrustPilotScore} />
                </div>
              )}
            </div>

            {!isMobile && (
              <div className="relative flex items-center">
                {data.AnimatedBubbles && (
                  <>
                    <AnimatedBubble x={10} y={150} size={20} />
                    <AnimatedBubble x={50} y={-150} size={80} />
                    <AnimatedBubble x={100} y={90} size={40} />
                    <AnimatedBubble x={400} y={200} size={60} />
                    <AnimatedBubble x={400} y={-250} size={70} />
                    <AnimatedBubble x={570} y={-100} size={30} />
                    <AnimatedBubble x={530} y={220} size={80} />
                    <AnimatedBubble x={-30} y={200} size={40} />
                  </>
                )}
                {data?.Icon?.file.childImageSharp?.gatsbyImageData ? (
                  <GatsbyImage
                    image={data?.Icon?.file.childImageSharp?.gatsbyImageData}
                    alt={data?.Icon?.alternativeText}
                    className="header-mask h-[300px] md:h-[620px] max-w-[300px] mx-auto md:max-w-none"
                  />
                ) : data.ImageURL ? (
                  <div className="header-mask h-[300px] md:h-[620px] max-w-[300px] mx-auto md:max-w-none">
                    <img
                      src={data.ImageURL}
                      alt={data.AlternativeText}
                      className="object-cover object-center w-full h-full"
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </MaxContent>
      </div>
    </MaxContent>
  );
};

export interface HeaderPointProps {
  Point: string;
}

export const HeaderPoint: React.FC<HeaderPointProps> = ({ Point }) => {
  return (
    <li className="flex items-center">
      <img src={tick} alt="tick" className="inline-block w-[18px] h-[18px]" />
      <span className="ml-3 font-MW text-[16px] leading-[20px] md:text-[18px] md:leading-[22.63px]">
        {Point}
      </span>
    </li>
  );
};

export default HeaderCalculator;
