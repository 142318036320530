import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import MakeSlug from "../../helpers/makeSlug";

const logo = "https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.png";
const DOMAIN = process.env.GATSBY_APP_LOCALE;

interface SeoDataInterface {
  data: SEO;
  locale: string;
  prev?: string;
  next?: string;
  canonical?: any;
  customUrl?: string;
  title?: string;
}

type SeoLink = {
  Href: string;
  HrefLang: string;
};

export type SEO = {
  SeoTitle: string;
  SeoDescription: string;
  Schema: string;
  CanonicalTag: string;
  DoNotIndex?: boolean;
  DoNotFollow?: boolean;
  Links: Array<SeoLink>;
  Image?: any;
  ImageUrl?: string;
  TwitterImageUrl?: string;
  FacebookImageUrl?: string;
};

const SeoHelmet: React.FC<SeoDataInterface> = ({
  data,
  locale,
  prev,
  next,
  canonical,
  customUrl,
  title
}) => {
  const url = (() => {
    if (data?.CanonicalTag) return data?.CanonicalTag;
    if (customUrl) return `${process.env.GATSBY_SITE_URL}${customUrl}`;
    if (title) return `${process.env.GATSBY_SITE_URL}${MakeSlug(title)}`;
    return "";
  })();

  const getMetaRobots = () => {
    const content: string[] = [];

    if (data?.DoNotIndex !== null) {
      if (data.DoNotIndex) {
        content.push("noindex");
      } else {
        content.push("index");
      }
    }

    if (data?.DoNotFollow !== null) {
      if (data.DoNotFollow) {
        content.push("nofollow");
      } else {
        content.push("follow");
      }
    }

    if (data?.DoNotIndex === null && data?.DoNotFollow === null) {
      return <meta name="robots" content="index,follow"></meta>;
    } else {
      const contentString =
        content.length === 0 ? null : content.reduce((a, b) => a + "," + b);
      return contentString === null ? null : (
        <meta name="robots" content={contentString}></meta>
      );
    }
  };

  const MetaImage = data?.ImageUrl || logo;
  const TwitterMetaImage = data?.TwitterImageUrl || data?.ImageUrl || logo;
  const OpenGraphMetaImage = data?.FacebookImageUrl || data?.ImageUrl || logo;

  const setCSSTheme = () => {
    if (typeof window !== "undefined") {
      const themes = {
        it: {
          "--primary-color": "#003399",
          "--dark-primary-color": "#003781",
          "--light-primary-color": "#ECF0FF",
        },
      };

      if (themes[DOMAIN]) {
        const rootTheme: HTMLElement = document.querySelector("html");
        Object.keys(themes[DOMAIN]).forEach((property) => {
          const value = themes[DOMAIN][property];
          rootTheme.style.setProperty(property, value);
        });
      }
    }
  };

  setCSSTheme();

  return (
    <>
      <Helmet htmlAttributes={{ lang: locale }}>
        
        {data && !data.DoNotIndex && <link rel="canonical" href={url} />}

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {prev && <link rel="prev" href={prev} />}
        {next && <link rel="next" href={next} />}

        {data && getMetaRobots()}

        {data && data.SeoTitle ? (
          <title>{data.SeoTitle}</title>
        ) : (
          <title>Life5 Insurance</title>
        )}
        {data && data.SeoDescription ? (
          <meta name="description" content={data.SeoDescription} />
        ) : (
          <meta
            name="description"
            content="Retrouvez sur notre blog Life5 toutes les actualités et Informations sur l'assurance décès invalidité et la prévoyance en France."
          />
        )}
        <meta name="image" content={MetaImage} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        {data && data.SeoTitle ? (
          <meta name="twitter:title" content={data.SeoTitle} />
        ) : (
          <meta name="twitter:title" content="Life5 Insurance" />
        )}
        {data && data.SeoDescription ? (
          <meta name="twitter:description" content={data.SeoDescription} />
        ) : (
          <meta
            name="twitter:description"
            content="This is the Life5 website."
          />
        )}
        <meta name="twitter:image" content={TwitterMetaImage} />
        {/* FB & Instagram */}
        <meta name="og:card" content="summary_large_image" />
        {data && data.SeoTitle ? (
          <meta name="og:title" content={data.SeoTitle} />
        ) : (
          <meta name="og:title" content="Life5 Insurance" />
        )}
        {data && data.SeoDescription ? (
          <meta name="og:description" content={data.SeoDescription} />
        ) : (
          <meta name="og:description" content="This is the Life5 website." />
        )}
        <meta name="og:image" content={OpenGraphMetaImage} />
        <meta name="facebook-domain-verification" content="0chjdwo9wb62q1e5ym3g8a1gxdy6pu" />
        
        <script src="https://www.googleoptimize.com/optimize.js?id=OPT-T28R843"></script>
        {data && data.Schema && (
          <script type="application/ld+json">
            {JSON.stringify(data.Schema)}
          </script>
        )}

        {data &&
          data.Links &&
          data.Links.map((link: SeoLink) => (
            <link rel="alternative" hrefLang={link.HrefLang} href={link.Href} />
          ))}
      </Helmet>
    </>
  );
};

export default SeoHelmet;
