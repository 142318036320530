import React, { useState } from "react";
import MaxContent from "../../layout/MaxContent";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../Inputs/Button";
import { StrapiTitleSelect } from "../Types";
import { getTitleHTMLTag } from "../../helpers/tools";

interface StrapiData {
  data: CardsContainerProps;
}

interface CardsContainerProps extends StrapiTitleSelect {
  Header: string;
  SubTitle: string;
  Items: Array<FeaturedCardProps>;
}

const CardsContainer: React.FC<StrapiData> = ({ data }) => {
  const { Items } = data;

  let title = getTitleHTMLTag(
    data.Header,
    data.SelectTitleType?.TitleTypeSelection,
    "my-3 text-[25px] leading-[35px] lg:text-[35px] lg:leading-[45px] font-bold text-purple lg:text-center pt-5"
  );
  //cs removed p-4 from ul so cards are not so narrow bug 299
  return (
    <MaxContent size="content">
      <div className="max-w-[800px]  mx-auto">
        {data.Header && title}

        <p className="font-MW lg:text-center text-[16px] leading-[26px] lg:text-[20px] lg:leading-[36px]">
          {data.SubTitle}
        </p>
      </div>

      <ul className="grid grid-col md:grid-cols-2 lg:grid-cols-3 md:py-4 md:gap-5 mt-5 mb-5 md:mb-14">
        {Items.map((item) => (
          <FeaturedCard
            Text={item.Text}
            Title={item.Title}
            Colour={item.Colour}
            FeatureImage={item.FeatureImage}
            key={item.Title}
            ShowMoreLabel={item.ShowMoreLabel}
            ShowLessLabel={item.ShowLessLabel}
            FeatureIcon={item.FeatureIcon}
            CardNumber={item.CardNumber}
            PrimaryButtonLabel={item.PrimaryButtonLabel}
            PrimaryButtonLink={item.PrimaryButtonLink}
            SecondaryButtonLabel={item.SecondaryButtonLabel}
            SecondaryButtonLink={item.SecondaryButtonLink}
            CardTitleType={item.CardTitleType}
            PrimaryButtonNewTab={item.PrimaryButtonNewTab}
            SecondaryButtonNewTab={item.SecondaryButtonNewTab}
          />
        ))}
      </ul>
    </MaxContent>
  );
};

interface FeaturedCardProps {
  FeatureImage: {
    alternativeText: string;
    file: {
      childImageSharp: {
        gatsbyImageData: any;
      };
    };
  };

  Title: string;
  Text: string;
  Colour: string;
  ShowMoreLabel?: string;
  ShowLessLabel?: string;
  FeatureIcon?: {
    file: {
      url: any;
    };
  };
  CardNumber?: number;
  PrimaryButtonLabel?: string;
  PrimaryButtonLink?: string;
  SecondaryButtonLabel?: string;
  SecondaryButtonLink?: string;
  PrimaryButtonNoFollow?: boolean;
  SecondaryButtonNoFollow?: boolean;
  PrimaryButtonNewTab?: boolean;
  SecondaryButtonNewTab?: boolean;
  CardTitleType: string;
}

const FeaturedCard: React.FC<FeaturedCardProps> = ({
  FeatureImage,
  Title,
  Text,
  Colour,
  CardNumber,
  ShowMoreLabel,
  ShowLessLabel,
  FeatureIcon,
  PrimaryButtonLabel,
  PrimaryButtonLink,
  SecondaryButtonLabel,
  SecondaryButtonLink,
  PrimaryButtonNoFollow,
  PrimaryButtonNewTab,
  SecondaryButtonNoFollow,
  SecondaryButtonNewTab,
  CardTitleType,
}) => {
  let title = getTitleHTMLTag(
    Title,
    CardTitleType,
    "my-5 font-bold text-[18px]  leading-[28px] lg:text-[24px] lg:leading-[34px]"
  );
  let titleNolink = getTitleHTMLTag(
    Title,
    CardTitleType,
    "my-5 font-bold text-[18px] leading-[28px] lg:text-[24px] lg:leading-[34px] text-black"
  );

  const ColourMap: Record<string, string> = {
    yellow: "#FFDB33",
    gray: "#F5F7FA",
    orange: "#FF6B00",
    green: "#018565",
  };
  const ColourBMap: Record<string, string> = {
    yellow: "#FEFCF3",
    gray: "#F5F7FA",
    orange: "#FFF7F1",
    green: "#F5FFFB",
  };
  const [isOpen, setIsOpen] = useState(false);

  const Opacity25Hex = "40";
  /* https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
    Tailwind JIT wasnt working so we use raw CSS instead
    We add Opacity25Hex to the end of ColourMap[Colour] to reduce the opacity as HEX works the same as RGBA
  */
  return (
    //added div wrapper to give space verticle space between cards bug299
    // @ts-ignore
    <div className="h-full py-2 md:py-0">
      <div
        className={` rounded-md md:p-8 h-full mx-auto p-4 text-left  featuredCard--${Colour}
        border ${" "} `}
      >
        {CardNumber && <span className="text-7xl">{CardNumber}</span>}
        {FeatureIcon?.file?.url && (
          <img
            src={FeatureIcon?.file?.url}
            className="w-[60px] h-[60px] mx-auto rounded-full md:rounded-none md:w-auto md:h-auto"
          ></img>
        )}
        {FeatureImage?.file?.childImageSharp?.gatsbyImageData && (
          <div className=" w-full mx-auto">
            <GatsbyImage
              image={FeatureImage?.file?.childImageSharp?.gatsbyImageData}
              alt={FeatureImage?.alternativeText}
              className="  object-contain"
            />
          </div>
        )}

        {/* Link functionality in the title needs to be removed from the CMS
          Once removed the commented code below needs to be removed
        */}
        {/* {Title && Link && (
          <a href={Link} target="_blank" className="no-underline hover:underline" >
            {Title && title}
          </a>
        )} */}
        {/* {Title && !Link && titleNolink} */}
        {Title && titleNolink}

        {Text && (
          <p className="my-5 text-placeholder text-[16px] leading-[26px] lg:text-[18px] lg:leading-[28px]">
            {Text}
          </p>
        )}
        {/* {Text && Text.length >= 100 && (
          <a
            className="text-purple no-underline hover:underline cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? ShowLessLabel : ShowMoreLabel}
          </a>
        )} */}
        <div className="w-full">
          {PrimaryButtonLabel && (
            <Button
              label={PrimaryButtonLabel}
              href={PrimaryButtonLink || ""}
              noFollow={PrimaryButtonNoFollow}
              newTab={PrimaryButtonNewTab}
            />
          )}
          {SecondaryButtonLabel && (
            <Button
              label={SecondaryButtonLabel}
              href={SecondaryButtonLink || ""}
              secondary={true}
              noFollow={SecondaryButtonNoFollow}
              newTab={SecondaryButtonNewTab}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CardsContainer;
