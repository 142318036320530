import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";

const BlueArrow = "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/blue-arrow-down.svg";
const ES = "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/Locale/es.svg";
const FR = "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/Locale/fr.svg";
const IT = "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/Locale/it.svg";
const GB = "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/Locale/gb.svg";
const DOMAIN = process.env.GATSBY_APP_LOCALE;

interface region {
  flag: string;
  url: string
}

const regions: Record<string, any> = {
  // 'es': ES,
  "es": [{flag: ES, url: "https://life5.es/", label: "es"},{flag: GB, url: "https://life5.es/en/", label: "en"}],
  "fr": [{flag: FR, url: "https://life5.fr/", label: "fr"}, {flag: GB, url: "https://life5.fr/en/", label: "en"}],
  "it": [{flag: IT, url: "https://life5.it/", label: "it"}, {flag: GB, url: "https://life5.it/en/", label: "en"}],
  "en": [{flag: GB, url: "https://life5.es/en/", label: "en"}, {flag: ES, url: "https://life5.es/", label: "es"},{flag: FR, url: "https://life5.fr/", label: "fr"}],
}

const TranslationToggle = () => {
  const [region, setRegion] = useState<string>(DOMAIN);
  const [open, setOpen] = useState(false);
  const currentFlagImage = region === "" ? "" : regions[region][0]["flag"];

  useEffect(() => {
    if (typeof window !== "undefined") {
      let path = window.location.pathname;

      if (path === "/en/") {
        setRegion("en");
      } else {
        setRegion(region);
      }
    }
  }, []);

  const handleClick = (regionType: string) => {
    switch (regionType) {
      case "en":
        navigate("/en/");
        break;
      default:
        navigate("/");
        break;
    }
  };


  return (
    <div
      className="flex flex-row items-center relative cursor-pointer"
      onClick={() => setOpen(!open)}
    >
      <div
        className="w-[28px] h-[28px] rounded-full lg:ml-[40px]"
        style={{ backgroundImage: `url(${currentFlagImage})` }}
      >
        {open && (
          <div className="absolute bottom-0 translate-y-[100%]">
            {Object.entries(regions[DOMAIN]).map((r: any, index: number) => {
              let label = r[1].label;
              let flag = r[1].flag;
              let link = r[1].url;

              if (region === label) return null;
              return (
                <div
                  key={`region-flag-${index}`}
                  className="w-[28px] h-[28px] rounded-full mt-[5px]"
                  style={{ backgroundImage: `url(${flag})` }}
                  onClick={() => handleClick(label)}
                ><a href={link}></a></div>
              );
            })}
          </div>
        )}
      </div>
      <img src={BlueArrow} className="w-[12px] h-[7px] ml-[6px]"  alt="BlueArrow"/>
    </div>
  );
};

export default TranslationToggle;
