import React from "react";
import {
  BackgroundColorI,
  StrapiImage,
  TitleColorI,
  TitleType,
} from "../Types";
import { getTitleHTMLTag } from "../../helpers/tools";
import { GatsbyImage } from "gatsby-plugin-image";

interface BannerTitleImageI {
  data: {
    BackgroundColor: BackgroundColorI;
    DesktopImage: StrapiImage;
    DesktopImageAlt: string;
    DesktopImageURL: string;
    HasSubtitle: boolean;
    HasTitle: boolean;
    MobileImage: StrapiImage;
    MobileImageAlt: string;
    MobileImageURL: string;
    SansTitle: string;
    SerifTitle: string;
    Subtitle: string;
    TitleColor: TitleColorI;
    TitleHeading: {
      TitleTypeSelection: TitleType;
    };
    HasLink: boolean;
    LinkURL: string;
    HideModuleOnMobile: boolean;
    HideModuleOnDesktop: boolean;
  };
}

const BannerTitleImage = ({ data }: BannerTitleImageI) => {
  const {
    BackgroundColor,
    DesktopImage,
    DesktopImageAlt,
    DesktopImageURL,
    HasSubtitle,
    HasTitle,
    MobileImage,
    MobileImageAlt,
    MobileImageURL,
    SansTitle,
    SerifTitle,
    Subtitle,
    TitleColor,
    TitleHeading,
    HasLink,
    LinkURL,
    HideModuleOnMobile,
    HideModuleOnDesktop,
  } = data;

  const backgroundColor = {
    Blue_Gray: "#F5F7FA",
    Light_Blue: "#ECF0FF",
    White: "#FFFFFF",
  }[BackgroundColor?.BackgroundColor || "White"];

  const titleColor = {
    Dark_Gray: "#424242",
    Deep_Life_Blue: "#3430E3",
  }[TitleColor?.TitleColor || "Dark_Gray"];

  const titleC = HasTitle
    ? getTitleHTMLTag(
        <>
          <span className="font-DM font-bold md:text-[36px] text-[30px] md:leading-[41.66px] leading-[28.64px] tracking-[-0.02em]">
            {SansTitle}{" "}
          </span>
          <span className="font-MW font-light md:text-[32px] text-[27px] md:leading-[37.71px] leading-[25.14px] tracking-[-0.02em]">
            {SerifTitle}
          </span>
        </>,
        TitleHeading?.TitleTypeSelection,
        `text-[${titleColor}] md:text-center`
      )
    : null;

  const subtitleC = HasSubtitle
    ? getTitleHTMLTag(
        Subtitle,
        "p",
        "text-[#424242] md:text-center font-DM font-normal md:text-[22px] text-[18px] md:leading-[28.64px] leading-[23.44px] tracking-[-0.02em]"
      )
    : null;

  return (
    <section className="overflow-hidden" style={{ backgroundColor }}>
      <div
        className={`md:flex flex flex-col max-w-content mx-auto md:py-[40px] py-[32px] xl:px-[0px] px-[24px] md:gap-[64px] gap-[32px] ${
          HideModuleOnMobile ? "hidden" : ""
        } ${HideModuleOnDesktop ? "md:hidden" : ""}`}
      >
        {HasTitle || HasSubtitle ? (
          <div className="flex flex-col md:gap-[16px] gap-[8px]">
            {titleC}
            {subtitleC}
          </div>
        ) : null}
        {DesktopImage ? (
          <div className="hidden md:flex justify-center items-center">
            <ChildrenWithLink HasLink={HasLink} LinkURL={LinkURL}>
              <GatsbyImage
                image={DesktopImage?.file?.childImageSharp?.gatsbyImageData}
                alt={DesktopImageAlt}
                className="w-fit max-w-full"
              />
            </ChildrenWithLink>
          </div>
        ) : DesktopImageURL ? (
          <picture className="hidden md:flex justify-center items-center">
            <ChildrenWithLink HasLink={HasLink} LinkURL={LinkURL}>
              <img
                src={DesktopImageURL}
                alt={DesktopImageAlt}
                className="w-fit max-w-full"
              />
            </ChildrenWithLink>
          </picture>
        ) : null}
        {MobileImage ? (
          <div className="md:hidden flex justify-center items-center">
            <ChildrenWithLink HasLink={HasLink} LinkURL={LinkURL}>
              <GatsbyImage
                image={MobileImage?.file?.childImageSharp?.gatsbyImageData}
                alt={MobileImageAlt}
                className="w-fit max-w-full"
              />
            </ChildrenWithLink>
          </div>
        ) : MobileImageURL ? (
          <picture className="md:hidden flex justify-center items-center">
            <ChildrenWithLink HasLink={HasLink} LinkURL={LinkURL}>
              <img
                src={MobileImageURL}
                alt={MobileImageAlt}
                className="w-fit max-w-full"
              />
            </ChildrenWithLink>
          </picture>
        ) : null}
      </div>
    </section>
  );
};

const ChildrenWithLink = ({ children, HasLink, LinkURL }) => {
  if (HasLink) {
    return (
      <a href={LinkURL} target="_blank">
        {children}
      </a>
    );
  }
  return children;
};

export default BannerTitleImage;
