import React from "react";
import { ReactElement } from "react-markdown/lib/react-markdown";

export const getSeo = (data: any) => {
  const components = data.data.page.Components;

  let seoComponent;
  try {
    const findSeo = components.find((obj: any) => {
      return obj.__typename === "StrapiComponentComponentSeoHelmut";
    });
    seoComponent = findSeo;
    return seoComponent;
  } catch (error) {
    console.error(error);
  }
};

export const escapeHtml = function (raw: string) {
  const specialchars = [
    ['"', "&quot;"],
    [">", "&gt;"],
    ["<", "&lt;"],
    ["&", "&amp;"],
    ["'", "&rsquo;"],
    ["'", "&#039;"],
  ];

  // Our finalized string will start out as a copy of the initial string.
  let unescapedString = raw;

  for (var x = 0; x < specialchars.length; x++) {
    // Replace all instances of the entity with the special character.
    unescapedString = unescapedString.replace(
      new RegExp(specialchars[x][1], "g"),
      specialchars[x][0]
    );
  }

  // Return the unescaped string.
  return unescapedString;
};

export const reorderDate = (dateOfBirth: string) => {
  const datesArr = dateOfBirth.split("-");

  return datesArr[2] + "-" + datesArr[1] + "-" + datesArr[0];
};



export const dataLayer = (data: {
  event: string;
  page: string;
  leadId: string;
  intent: string;
  phone: string;
  email: string;
}) => {
  const { event, page, leadId, intent, phone, email } = data;
  if (typeof window !== "undefined") {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: event,
      page: page,
      leadId: leadId,
      intent: intent,
      phone: phone,
      email: email,
    });

    return true;
  } else {
    return false;
  }
};

export const limitChar = (text: string, limit = 117): string => {
  if (text === null) return "";
  if (text.length < limit + 3) return text;
  else return text.substring(0, limit) + "...";
};

export const splitChunkArray = (
  input: Array<any>,
  size: number
): Array<any> => {
  const result: Array<any> = [];

  if (input === null || typeof input === "undefined" || input?.length === 0) {
    return result;
  }

  for (let i = 0; i < input.length; i += size) {
    const chunk = input.slice(i, i + size);
    result.push(chunk);
  }
  return result;
};

export const getTitleHTMLTag = (
  title: string | JSX.Element,
  titleType: any,
  className: string
): ReactElement => {
  switch (titleType) {
    case "H1":
      return <h1 className={className}>{title}</h1>;
    case "H2":
      return <h2 className={className}>{title}</h2>;
    case "H3":
      return <h3 className={className}>{title}</h3>;
    case "H4":
      return <h4 className={className}>{title}</h4>;
    case "H5":
      return <h5 className={className}>{title}</h5>;
    case "H6":
      return <h6 className={className}>{title}</h6>;
    case "p":
      return <p className={className}>{title}</p>
    default:
      return <h3 className={className}>{title}</h3>;
  }
};

export const capitaliseFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const clickButton = ({
  href,
  navigate,
}: {
  href?: string;
  navigate: any;
}) => {
  if (href) {
    if (href.includes("http")) {
      window.open(href);
    } else {
      navigate(href);
    }
  } else {
    navigate("/");
  }
};
