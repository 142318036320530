import React from 'react'
import { useDevice } from '../Hooks'
import MaxContent from "../../layout/MaxContent";
import { GatsbyImage } from 'gatsby-plugin-image';
import { StrapiImage } from '../Types'

interface ImageBannerData {
  data: ImageBannerprops
}

interface ImageBannerprops {
  DesktopImage: StrapiImage;
  ImageURL?: string;
  AlternativeText?: string;
}

const ImageBanner= ({ data }: ImageBannerData) => {
  return (
    <MaxContent size="max">
      <div className="">
        <MaxContent size="max">
          <section className="md:h-full flex flex-col justify-around">
          {
            data?.DesktopImage?.file?.childImageSharp.gatsbyImageData ?
            <GatsbyImage 
              image= {data?.DesktopImage?.file?.childImageSharp.gatsbyImageData}
              alt={data?.DesktopImage?.alternativeText}
              className="" 
              />
              : data?.ImageURL ?
                <div className="w-full h-full">
                  <img
                    src={data.ImageURL}
                    alt={data.AlternativeText}
                    className="object-cover object-center w-full h-full"
                  />
                </div>
              : null
          }
          </section>
        </MaxContent>
      </div>
    </MaxContent>
  );}

export default ImageBanner;


