import {
  ErrorI,
  isEmail,
  isOver,
  isPhone,
  isUnder,
} from "get-life-storybook-ts/lib/components/Validations";
import { useEffect, useState } from "react";
import { useDevice } from "get-life-storybook-ts";
import * as FullStory from "@fullstory/browser";
import * as ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { getCookie, getReferrerUrl } from "../Hooks";
import prepareCapitals from "../../helpers/Capitals/prepareCapitals";


interface DataTrackerI {
  leadUlid?: string;
  campaign: string;
  utm_campaign?: string | null;
  utm_source?: string | null;
  utm_medium?: string | null;
  tid?: string | null;
  utm_content?: string | null;
  utm_term?: string | null;
  utm_test?: string | null;
  gclid?: string | null;
  fbclid?: string | null;
  entryPage?: string | null;
  referrerUrl?: string | null;
  ABTestVersion: string | null;
}

interface FsEventI {
  FsEvent: string;
  data?: {
    leadId?: string;
  };
}

interface IdentifyFSI {
  lead: string;
  data: {};
}
interface ContextProps {
  handleTrack: (value: HandleTrackerI) => void;
  identifyFS: (value: IdentifyFSI) => void;
}

interface HandleTrackerI {
  type: "FS" | "tracker" | "GA";
  data: any;
  event?: string;
}

export interface StrapiDataCalculator {
  data: {
    Url: string;
    noSignUp?: boolean;
    backgroundColor?: string;
  }[];
}
interface Validations extends ErrorI<string | number> { }

interface GlobalInfoI {
  defaultcap: string | null;
  gtf_campaign: string | null;
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_content?: string | null;
  utm_term?: string | null;
  utm_test?: string | null;
  gclid?: string | null;
  fbclid?: string | null;
  tid?: string | null;
  url: any;
  entryPage?: string | null;
  referrerUrl?: string | null;
  ABTestVersion: string | null;
}
interface FormDataFirstI {
  birthDate: string;
  capital: number | string;
  intention: string;
  redirect?: boolean;
}

interface FormDataSecondI {
  phone: string;
  email: string;
  checkedConditions: string | boolean;
  checkedConditions2: string | boolean;
}

const API_URL = process.env.GATSBY_API_URL;

const optionsIntention = [
  {
    label: "Mi familia",
    value: "family",
  },
  {
    label: "Mi hipoteca",
    value: "loan",
  },
];

const errorMessages: any = {
  birthDate: "Por favor, añade una fecha válida",
  intention: "Por favor seleccione una protección",
  capital: "Por favor seleccione un capital",
  phone: "Por favor inserte un número de teléfono correcto",
  email: "Por favor inserte un correo electrónico correcto",
  checkedConditions: "Debe aceptar el tratamiento de datos para continuar",
  checkedConditions2: "Debe aceptar las condiciones para continuar",
};

const CalculatorModuleController = ({ data }: StrapiDataCalculator) => {
  const [globalInfo, setGlobalInfo] = useState<GlobalInfoI>({
    defaultcap: null,
    // gtf_campaign: "calculator-cms",
    gtf_campaign: "3MESES1E",
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    tid: null,
    url: null,
    utm_content: null,
    utm_term: null,
    utm_test: null,
    gclid: null,
    fbclid: null,
    entryPage: null,
    referrerUrl: null,
    ABTestVersion: null
  });
  const [formDataFirst, setFormDataFirst] = useState<FormDataFirstI>({
    birthDate: "",
    capital: 0,
    intention: "",
    redirect: false
  });
  const [formDataSecond, setFormDataSecond] = useState<FormDataSecondI>({
    phone: "",
    email: "",
    checkedConditions: "",
    checkedConditions2: "",
  });
  const [formError, setFormError] = useState<any>();
  const [capitalOptions, setCapitalOptions] = useState<any>([]);
  const [actualStep, setActualStep] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [display, setDisplay] = useState<boolean>(false);
  const [leadId, setLeadId] = useState<string>("");
  const { isMobile, isDesktop, isTablet } = useDevice();
  const [cookies, setCookie] = useState("");
  const [goToAPP, setGoToAPP] = useState<boolean>(false);
  const [isFirstIncoming, setIsFirstIncoming] = useState<boolean>(false)
  const noSignUp = data[0]?.noSignUp ?? false;

  const handleFocus = () => {
    const dataInfo = {
      event: "virtualPageview",
      page: window.location.href + " - " + actualStep,
      pageTitle: `APP Calculator - Paso ${actualStep}`,
      answeredQ: "",
    };

    handleTrack({ type: "GA", data: dataInfo });

    handleTrack({
      type: "FS",
      event: `Calculator Step ${actualStep} ${actualStep === 1 ? "Interacted" : "Viewed"
        }`,
      data: {},
    });

  };

  const validationDateOfBirth = ({ value, min, max }: Validations) => {
    const errorYoung = isOver({
      value: value as string,
      message: "Debes ser mayor de $ para registrarte.".replace(
        "$",
        min!.toString()
      ),
      min,
    });
    const errorElder = isUnder({
      value: value as string,
      message: "Debes ser menor de $ para registrarte.".replace(
        "$",
        max!.toString()
      ),
      max,
    });

    if (errorYoung.error) {
      return errorYoung;
    } else {
      return errorElder;
    }
  };


  const fieldValidation = ({ data, key, newError }: any) => {
    if (key !== "redirect") {
      if (!data[key]) {
        newError = {
          ...newError,
          [key]: { error: true, message: errorMessages[key] },
        };
      } else if (key === "phone" && isPhone({ value: data[key] }).error) {
        newError = {
          ...newError,
          [key]: { error: true, message: errorMessages[key] },
        };
      } else if (key === "email" && isEmail({ value: data[key] }).error) {
        newError = {
          ...newError,
          [key]: { error: true, message: errorMessages[key] },
        };
      } else {
        newError = { ...newError, [key]: { error: false, message: "" } };
      }
    }
    return newError;
  };

  const validations = (data: any) => {
    let newError: any = {};
    Object.keys(data).forEach((key: any) => {
      newError = fieldValidation({ data, key, newError });
    });
    let sumValidate = 0;
    Object.keys(newError).forEach((key) => {
      if (!newError[key].error) {
        sumValidate += 1;
      }
    });
    return sumValidate === Object.keys(newError).length ? undefined : newError;
  };

  const coverageFormat = {
    basic: "F",
    premium: "FIPA"
  }

  const signupOrganic = async () => {
    let dataOrganic = {
      birthDate: formDataFirst.birthDate,
      campaign: "calculator-cms",
      capital:
        typeof formDataFirst.capital === "number"
          ? formDataFirst.capital
          : parseInt(formDataFirst.capital),
      email: formDataSecond.email,
      phone: formDataSecond.phone,
      intention: formDataFirst.intention,
    };

    const organic = await fetch(`${API_URL}/lead/organic/calculator`, {
      method: "POST",
      body: JSON.stringify(dataOrganic),
    })
      .then((response) => {
        if (response.status === 405) {
          window.location.href = `https://life5.es/capital-premium/`;
          return;
        }
        return response.json();
      })
      .then((resultData) => resultData);

    return organic;
  };

  const noSignupOrganic = async () => {
    let dataOrganic = {
      birthdate: formDataFirst.birthDate,
      campaign: "calculator-cms",
      capital:
        typeof formDataFirst.capital === "number"
          ? formDataFirst.capital
          : parseInt(formDataFirst.capital),
      intention: formDataFirst.intention,
    };

    const organic = await fetch(`${API_URL}/lead/organic/no-sign-up`, {
      method: "POST",
      body: JSON.stringify(dataOrganic),
    })
      .then((response) => response.json())
      .then((resultData) => resultData);

    return organic;
  };

  const fetchOrganic = async () => {
    setLoading(true);
    try {
      if (!noSignUp) {
        const secondStepErrors = validations(formDataSecond);
        if (secondStepErrors) {
          throw secondStepErrors;
        }
      }

      if (noSignUp) {
        const organic = await noSignupOrganic();
        setLeadId(organic.leadId);
        setIsFirstIncoming(true);
        setGoToAPP(true);
        setLoading(false);
        return;
      }

      const organic = await signupOrganic();
      const truncatedBasicPrice = Math.trunc(organic.basicPrice * 100) / 100;
      setIsFirstIncoming(noSignUp ? true : organic.isFirstIncoming);

      setLeadId(organic.leadId);

      identifyFS({
        lead: organic.leadId,
        data: {
          emailDomain: formDataSecond.email && formDataSecond.email.split("@")[1],
          email: formDataSecond.email,
          brokerId: null,
        },
      });

      await handleTrack({
        type: "tracker",
        data: {
          leadUlid: organic.leadId,
          campaign: globalInfo.gtf_campaign || "calculator-cms",
        },
      });

      const eventData = {
        page: globalInfo.url,
        leadId: organic.leadId,
        intent: formDataFirst.intention,
        phone: formDataSecond.phone,
        email: formDataSecond.email,
        calculatedCapital: organic.capital,
        calculatedPremium: truncatedBasicPrice,
        coverage: coverageFormat[organic.coverage],
        gender: null,
        postalCode: null,
        utm_campaign: globalInfo.utm_campaign,
        utm_medium: globalInfo.utm_medium,
        utm_source: globalInfo.utm_source,
        utm_content: globalInfo.utm_content,
        utm_term: globalInfo.utm_term,
        utm_test: globalInfo.utm_test,
        gclid: globalInfo.gclid,
        fbclid: globalInfo.fbclid,
        entryPage: globalInfo.entryPage,
        referrerUrl: globalInfo.referrerUrl,
        ABTestVersion: globalInfo.ABTestVersion,
        platform: "App"
      }

      if (organic.isFirstIncoming) {
        await handleTrack({
          type: "FS",
          event: "leadCreated",
          data: {
            birthDate: new Date(formDataFirst.birthDate),
            ...eventData,
          },
        });

        await handleTrack({
          type: "GA",
          data: {
            event: "leadCreated",
            birthDate: formDataFirst.birthDate,
            ...eventData,
          },
        });
      }

      await handleTrack({
        type: "FS",
        event: "calculatorQuoteGenerated",
        data: {
          birthDate: new Date(formDataFirst.birthDate),
          ...eventData,
        },
      });

      await handleTrack({
        type: "GA",
        data: {
          event: "calculatorQuoteGenerated",
          birthDate: formDataFirst.birthDate,
          ...eventData,
        },
      });
      if (typeof window !== undefined) {
        if (organic.isFirstIncoming) {
          window.location.href = `${data[0].Url}?lead=${organic.leadId}`;
        } else {
          if (typeof window !== "undefined") {
            window.location.href = `${data[1]?.Url ? data[1].Url : "https://app.life5.es/login"}`;
          }
        }
      }

      setLoading(false);
    } catch (error) {
      setFormError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (goToAPP && typeof window !== undefined) {
      if (formDataFirst.redirect) {
        if (typeof window !== "undefined") {
          window.location.href = `https://life5.es/capital-premium/`;
        }
      } else {
        if (isFirstIncoming) {
          window.location.href = `${data[0].Url}?lead=${leadId}`;
        } else {
          if (typeof window !== "undefined") {
            window.location.href = `${data[1]?.Url ? data[1].Url : "https://app.life5.es/login"}`;
          }
        }

      }
    }
  }, [goToAPP])

  const handleButton = async (scrollToTop = false) => {
    if (scrollToTop) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
    if (actualStep === 1) {
      const firstStepErrors = validations(formDataFirst);
      if (firstStepErrors) {
        setFormError(firstStepErrors);
      } else if (noSignUp) {
        await fetchOrganic();
      } else {
        setActualStep(2);
      }
    } else {
      const secondErrors = validations(formDataSecond);
      if (secondErrors) {
        setFormError(secondErrors);
      } else {
        await fetchOrganic();
      }
    }
  };

  const handleChange = ({ setValue, value, field, formData }: any) => {
    let newData = { ...formData, [field]: value };
    let newError: any = {};
    if (value && formError && formError[field] && formError[field].error) {
      newError = fieldValidation({ data: newData, key: field, newError });
      setFormError({ ...formError, ...newError });
    }
    setValue(newData);
  };

  const handleBlur = ({ field, formData }: any) => {
    let newError: any = {};
    if (!!formData[field]) {
      newError = fieldValidation({ data: formData, key: field, newError });
      setFormError({ ...formError, ...newError });
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const newQuery = new URLSearchParams(window.location.search);

      setGlobalInfo({
        defaultcap: newQuery.get("defaultcap"),
        gtf_campaign: getCookie("gtf_campaign"),
        utm_source: getCookie("utm_source"),
        utm_medium: getCookie("utm_medium"),
        utm_campaign: getCookie("utm_campaign"),
        tid: getCookie("tid"),
        utm_content: getCookie("utm_content"),
        utm_term: getCookie("utm_term"),
        utm_test: getCookie("utm_test"),
        gclid: getCookie("gclid"),
        fbclid: getCookie("fbclid"),
        entryPage: getCookie("entryPage"),
        referrerUrl: getReferrerUrl(),
        ABTestVersion: getCookie("ABTestVersion"),
        url: window.location.href,
      });
    }

    if (actualStep === 2) {
      handleFocus();
    }
  }, [cookies, actualStep]);

  useEffect(() => {
    (async () => {
      if (formDataFirst.birthDate) {
        if (typeof window !== undefined) {
          try {
            const errorBirth = validationDateOfBirth({
              value: formDataFirst.birthDate,
              min: 18,
              max: 75,
            });
            if (errorBirth.error) {
              throw errorBirth;
            }
            const premiumCapitals = !noSignUp;
            const capitals = await fetch(
              `${API_URL}/lead/capital/tarifier/${premiumCapitals ? "extendedBoundaries" : "boundaries"
              }/${formDataFirst.birthDate}`
            )
              .then((response) => response.json()) // parse JSON from request
              .then((resultData) => {
                return resultData;
              });

            const { options, indexPreSelected } = prepareCapitals({ capitals })

            setCapitalOptions(options);

            if (globalInfo.defaultcap) {
              let capital = options.find(element => element.value === parseInt(globalInfo.defaultcap!));

              if (capital) {
                setFormDataFirst({
                  ...formDataFirst,
                  capital: capital!.value,
                  redirect: capital!.redirect ? true : false
                });
                setFormError({ ...formError, birthDate: { error: false, message: "" } });
              } else {
                setFormDataFirst({
                  ...formDataFirst,
                  capital: options[indexPreSelected].value,
                  redirect: options[indexPreSelected].redirect ? true : false
                });
                setFormError({ ...formError, birthDate: { error: false, message: "" } });
              }
            } else {
              setFormDataFirst({
                ...formDataFirst,
                capital: options[indexPreSelected].value,
                redirect: options[indexPreSelected].redirect ? true : false
              });
              setFormError({ ...formError, birthDate: { error: false, message: "" } });
            }

          } catch (error: any) {
            if (error.error) {
              setFormError({ ...formError, birthDate: error });
            } else {
              setFormError({
                ...formError,
                birthDate: { error: true, message: errorMessages.birthDate },
              });
            }
            setCapitalOptions([]);
          }
        }
      }
    })();
  }, [formDataFirst.birthDate]);

  useEffect(() => {
    if (typeof document !== undefined) {
      setCookie(document.cookie)
    }
  }, [actualStep])


  const isNotDEV = process.env.REACT_APP_ENVIROMENT !== "DEV";
  if (isNotDEV && typeof window !== undefined) {
    //Se comenta para solo incluirlo a partir de /step/4
    ReactGA.initialize("UA-191842015-1");

  }

  const VWOID = getCookie("_vwo_uuid_v2");
  const GAID = getCookie("_ga");
  const FullStoryID = getCookie("fs_uid");
  const AT_GD = getCookie("at_gd");
  const API_URL = process.env.GATSBY_API_URL;

  const UTM_CAMPAIGN = getCookie("utm_campaign");
  const UTM_SOURCE = getCookie("utm_source");
  const UTM_MEDIUM = getCookie("utm_medium");
  const UTM_CONTENT = getCookie("utm_content");
  const UTM_TERM = getCookie("utm_term");
  const UTM_TEST = getCookie("utm_test");
  const GCLID = getCookie("gclid");
  const FBCLID = getCookie("fbclid");
  const TID = getCookie("tid");
  const ENTRYPAGE = getCookie("entryPage");
  const REFERRERURL = getReferrerUrl();
  const ABTESTVERSION = getCookie("ABTestVersion");

  const handleChangeDataTracker = async ({
    leadUlid,
    campaign
  }: DataTrackerI) => {

    let data = {
      leadUlid: leadUlid,
      googleAnalyticsId: GAID,
      fullstoryId: FullStoryID,
      vwoId: VWOID,
      utmCampaign: UTM_CAMPAIGN,
      utmSource: UTM_SOURCE,
      utmMedium: UTM_MEDIUM,
      utmContent: UTM_CONTENT,
      utmTerm: UTM_TERM,
      utmTest: UTM_TEST,
      gclid: GCLID,
      fbclid: FBCLID,
      tid: TID,
      entryPage: ENTRYPAGE,
      referrerUrl: REFERRERURL,
      aBTestVersion: ABTESTVERSION,
      campaign,
      atGd: AT_GD,
    }
    try {
      await fetch(`${API_URL}/lead/track/v2`, {
        method: "POST",
        body: JSON.stringify(data)
      })
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          return resultData
        });
    } catch (error) {
      console.log(error);
    }
  };

  const sendFullStoryEvent = async ({ FsEvent, data = {} }: FsEventI) => {
    FullStory.event(FsEvent, data);
    let dataEvent = {
      leadUlid: data.leadId,
      eventType: FsEvent,
      platform: "fullstory",
      createdAt: new Date(),
      payload: data
    }
    if (data.leadId) {
      await fetch(`${API_URL}/public/store/front/event`, {
        method: "POST",
        body: JSON.stringify(dataEvent)
      });
    }
  };

  const handleChangeDataLayer = async (newData: any) => {
    TagManager.dataLayer({ dataLayer: newData });
    let dataEvent = {
      leadUlid: newData.leadId,
      eventType: newData.event,
      platform: "ga4",
      createdAt: new Date(),
      payload: newData
    }
    if (newData.leadId) {
      await fetch(`${API_URL}/public/store/front/event`, {
        method: "POST",
        body: JSON.stringify(dataEvent)
      });
    }
  };

  const identifyFS = ({ lead, data }: IdentifyFSI) => {
    isNotDEV && FullStory.identify(lead, data);
  };

  const handleTrack = async ({ type, event, data }: HandleTrackerI) => {
    const trackers = {
      FS: () => sendFullStoryEvent({ FsEvent: event!, data }),
      tracker: () => handleChangeDataTracker(data),
      GA: () => handleChangeDataLayer(data),
    };

    return isNotDEV && trackers[type]();
  };

  return {
    actualStep,
    capitalOptions,
    display,
    formDataFirst,
    formDataSecond,
    formError,
    handleBlur,
    handleButton,
    handleChange,
    handleFocus,
    isDesktop,
    isMobile,
    isTablet,
    loading,
    optionsIntention,
    setActualStep,
    setDisplay,
    setFormDataFirst,
    setFormDataSecond,
    validations,
    noSignUp
  };
};

export default CalculatorModuleController;
