import React, { useContext } from "react";
import { GlobalIcon, Typography } from "get-life-storybook-ts";
import MaxContent from "../../layout/MaxContent";
import { LocationContext } from "../../helpers/locationContext";

interface ContentTableProps {
  data: {
    ShowUnfolded: boolean;
    ContentTableTitle: string;
    ContentItems: {
      AnchorId: string;
      ContentTableItemTitle: string;
    }[];
  };
}

const ContentTable = ({ data }: ContentTableProps) => {
  const [open, setOpen] = React.useState<boolean>(data.ShowUnfolded ?? true);

  const triggerContent = () => setOpen(!open);

  const scrollToElementById = (id: string) => {
    const element = document.getElementById(id);

    element.scrollIntoView({ behavior: "smooth" });
  };

  const location = useContext(LocationContext);

  return (
    <MaxContent size="content">
      <section className="bg-[#F5F7FA] px-[16px] py-[20px]">
        <div className="flex flex-row gap-0 justify-between items-center px-[8px] pb-[16px] border-b-2 border-[var(--primary-color)]">
          <p className="text-[18px] md:text-[22px] leading-[120%] tracking-[-0.02em] font-bold font-DM text-[#424242]">
            {data.ContentTableTitle}
          </p>
          <span
            role="button"
            tabIndex={0}
            onClick={triggerContent}
            onKeyDown={(e) => {
              if (e.key === " " || e.key === "Enter") {
                e.preventDefault();
                triggerContent();
              }
            }}
            className="p-[4px] transition-[transform]"
            style={{ transform: open ? "rotateX(0deg)" : "rotateX(180deg)" }}
          >
            <GlobalIcon iconName={"ArrowUpIcon"} size="XXS" />
          </span>
        </div>
        <li
          className="flex flex-col gap-[16px] px-[24px] overflow-hidden transition-[max-height]"
          style={{ maxHeight: open ? data?.ContentItems?.length * 110 : 0 }}
        >
          {data?.ContentItems.map((item) => {
            const { ContentTableItemTitle, AnchorId } = item;

            return (
              <ol className="first:mt-[16px]" key={AnchorId}>
                <a
                  href={`${process.env.GATSBY_SITE_URL}${location.pathname}#${AnchorId}`}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToElementById(AnchorId);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " " || e.key === "Enter") {
                      e.preventDefault();
                      scrollToElementById(AnchorId);
                    }
                  }}
                  className="text-[#424242] decoration-1 font-normal hover:!text-[#3430E3] hover:font-medium underline"
                >
                  <Typography
                    text={ContentTableItemTitle}
                    variant="Span"
                    weight={"0"}
                  />
                </a>
              </ol>
            );
          })}
        </li>
      </section>
    </MaxContent>
  );
};

export default ContentTable;
