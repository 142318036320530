import React from "react";
import IconsRow from "../components/IconsRow/IconsRow";
import ProductCardsRow from "../components/ProductCardsRow/ProductCardsRow";
import PromoBanner from "../components/Banners/PromoBanner";
import LegalTable from "../components/Tables/LegalTable/LegalTable";
import CompareTable from "../components/Tables/CompareTable/CompareTable";
import Accordion from "../components/Accordion/Accordion";
import TrustPilot from "../components/TrustPilot/TrustPilot";
import CompareWith from "../components/CompareWith/CompareWith";
import USPIconRow from "../components/USPIconRow/USPIconRow";
import ImageBanner from "../components/Banners/ImageBanner";
import CardsContainer from "../components/Featured_Cards/FeaturedCards";
import Feature_Content from "../components/Feature_Content/Feature_Content";
import Header from "../components/Headers/Header";
import ProductCarousel from "../components/Carousel/ProductCarousel";
import WhatsIncluded from "../components/Whats_Included/WhatsIncluded";
import FeaturedArticles from "../components/Articles/FeaturedArticles";
import Navigation_Header from "../components/Headers/Navigation_Header";
import PinkBanner from "../components/Banners/PinkBanner";
import PolicyCards from "../components/PolicyCards/PolicyCards";
import Faq from "../components/FAQ/Faq";
import AnimatedImageText from "../components/AnimatedImageText/AnimatedImageText";
import SecondaryHeader from "../components/Headers/SecondaryHeader";
import Text from "../components/Basic/Text";
import Html from "../components/Basic/Html";
import Title from "../components/Basic/Title";
import TitleText from "../components/Basic/TitleText";
import Image from "../components/Basic/Image";
import ButtonBasic from "../components/Basic/Button";
import TertiaryHeader from "../components/Headers/TertiaryHeader";
import Hubspot from "../components/Hubspot/Hubspot";
import HeaderCalculator from "../components/Headers/HeaderCalculator";
import CalculatorModule from "../components/CalculatorModule/CalculatorModule";
import SnackbarComponent from "../components/Snackbar/Snackbar";
import EmbeddedCalculator from "../components/EmbeddedCalculator/EmbeddedCalculator";
import ContentTable from "../components/ContentTable/ContentTable";
import Buttons from "../components/Buttons/Buttons";
import HeroCalculatorImage from "../components/HeroCalculatorImage/HeroCalculatorImage";
import HeroCalculatorContent from "../components/HeroCalculatorContent/HeroCalculatorContent";
import HeroPrimary from "../components/HeroPrimary/HeroPrimary";
import HeroMinimalist from "../components/HeroMinimalist/HeroMinimalist";
import BannerMinimalist from "../components/BannerMinimalist/BannerMinimalist";
import BannerRich from "../components/BannerRich/BannerRich";
import IconCards from "../components/IconCards/IconCards";
import ComparisonCards from "../components/ComparisonCards/ComparisonCards";
import BannerTitleImage from "../components/BannerTitleImage/BannerTitleImage";
import BannerImage from "../components/BannerImage/BannerImage";

const ComponentMap: any = {
  StrapiComponentComponentPromoBanner: (v: any) => (
    <PromoBanner data={v} key={v.id} />
  ),
  StrapiComponentComponentLegalTable: (v: any) => (
    <LegalTable data={v} key={v.id} />
  ),
  StrapiComponentComponentCompareTable: (v: any) => (
    <CompareTable data={v} key={v.id} />
  ),
  StrapiComponentComponentAccordion: (v: any) => (
    <Accordion data={v} key={v.id} />
  ),
  StrapiComponentComponentTrustPilotCarousel: (v: any) => (
    <TrustPilot data={v} key={v.id} />
  ),
  StrapiComponentComponentCompareWith: (v: any) => (
    <CompareWith data={v} key={v.id} />
  ),
  StrapiComponentComponentUspIcons: (v: any) => (
    <USPIconRow data={v} key={v.id} />
  ),
  StrapiComponentComponentIconsRow: (v: any) => (
    <IconsRow data={v} key={v.id} />
  ),
  StrapiComponentComponentProductCardsRow: (v: any) => (
    <ProductCardsRow data={v} key={v.id} />
  ),
  StrapiComponentComponentImageBanner: (v: any) => (
    <ImageBanner data={v} key={v.id} />
  ),
  StrapiComponentComponentFeaturedCards: (v: any) => (
    <CardsContainer data={v} key={v.id} />
  ),
  StrapiComponentComponentFeatureContent: (v: any) => (
    <Feature_Content data={v} key={v.id} />
  ),
  StrapiComponentComponentWhatsIncluded: (v: any) => (
    <WhatsIncluded data={v} key={v.id} />
  ),
  StrapiComponentComponentHeader: (v: any) => <Header data={v} key={v.id} />,
  StrapiComponentComponentSecondaryHeader: (v: any) => (
    <SecondaryHeader data={v} key={v.id} />
  ),
  StrapiComponentComponentTertiaryHeader: (v: any) => (
    <TertiaryHeader data={v} key={v.id} />
  ),
  StrapiComponentComponentAnimatedLines: (v: any) => (
    <AnimatedImageText data={v} key={v.id} />
  ),

  StrapiComponentComponentProductCarousel: (v: any) => (
    <ProductCarousel data={v} key={v.id} />
  ),
  StrapiComponentComponentNavigationHeader: (v: any) => (
    <Navigation_Header data={v} key={v.id} />
  ),
  StrapiComponentComponentFeaturedArticles: (v: any) => (
    <FeaturedArticles data={v} key={v.id} />
  ),
  StrapiComponentComponentPinkBanner: (v: any) => (
    <PinkBanner data={v} key={v.id} />
  ),
  StrapiComponentComponentPolicyCards: (v: any) => (
    <PolicyCards data={v} key={v.id} />
  ),
  StrapiComponentComponentFaqListings: (v: any) => <Faq data={v} key={v.id} />,
  StrapiComponentBasicTitle: (v: any) => <Title data={v} key={v.id} />,
  StrapiComponentBasicText: (v: any) => <Text data={v} key={v.id} />,
  StrapiComponentBasicHtml: (v: any) => <Html data={v} key={v.id} />,
  StrapiComponentBasicImage: (v: any) => <Image data={v} key={v.id} />,
  StrapiComponentBasicTitleText: (v: any) => <TitleText data={v} key={v.id} />,
  StrapiComponentBasicButton: (v: any) => <ButtonBasic data={v} key={v.id} />,
  StrapiComponentComponentHubspotForm: (v: any) => <Hubspot data={v} key={v.id} />,
  StrapiComponentComponentHeaderCalculator: (v: any) => <HeaderCalculator data={v} key={v.id} />,
  StrapiComponentComponentHeaderCalculatorNoSignUp: (v: any) => <HeaderCalculator data={{...v, noSignUp: true}} key={v.id} />,
  StrapiComponentComponentCalculatorModule: (v: any) => <CalculatorModule data={v} key={v.id} />,
  StrapiComponentComponentSnackbar: (v: any) => <SnackbarComponent data={v} key={v.id} />,
  StrapiComponentComponentEmbeddedCalculator: (v: any) => <EmbeddedCalculator data={v} key={v.id} />,
  StrapiComponentComponentContentTable: (v: any) => <ContentTable data={v} key={v.id} />,
  StrapiComponentComponentButtons: (v: any) => <Buttons data={v} key={v.id} />,
  StrapiComponentComponentHeroCalculatorImage: (v: any) => <HeroCalculatorImage data={v} key={v.id} />,
  StrapiComponentComponentHeroCalculatorContent: (v: any) => <HeroCalculatorContent data={v} key={v.id} />,
  StrapiComponentComponentHeroPrimary: (v: any) => <HeroPrimary data={v} key={v.id} />,
  StrapiComponentComponentHeroMinimalist: (v: any) => <HeroMinimalist data={v} key={v.id} />,
  StrapiComponentComponentBannerMinimalist: (v: any) => <BannerMinimalist data={v} key={v.id} />,
  StrapiComponentComponentBannerRich: (v: any) => <BannerRich data={v} key={v.id} />,
  StrapiComponentComponentIconCards: (v: any) => <IconCards data={v} key={v.id} />,
  StrapiComponentComponentComparisonCards: (v: any) => <ComparisonCards data={v} key={v.id} />,
  StrapiComponentComponentBannerTitleImage: (v: any) => <BannerTitleImage data={v} key={v.id} />,
  StrapiComponentComponentBannerImage: (v: any) => <BannerImage data={v} key={v.id} />,
};

export const componentBuilder = (strapiComponents: any[]) => {
  const components = strapiComponents.map((component) => {
    let itemsResult = undefined;

    try {
      // find matching component type
      let builderFn = ComponentMap[component.__typename];
      // call build function to a JSX component
      itemsResult = builderFn(component);
    } catch {
      (e: any) => console.error(e);
    }

    if (itemsResult === undefined) {
      return <></>;
    }
    return itemsResult;
  });
  return components;
};
