import React from "react";
import { BannerBackgroundColorI, StrapiImage, TitleType } from "../Types";
import TrustPilotDS from "../TrustPilot/TrustPilotDS";
import { getTitleHTMLTag } from "../../helpers/tools";
import Button from "../Inputs/Button";
import { GatsbyImage } from "gatsby-plugin-image";

interface BannerRichI {
  data: {
    BannerBackgroundColor: BannerBackgroundColorI;
    BannerConfiguration: {
      Position: "Left" | "Right";
    };
    TrustpilotScore: string;
    DesktopImage?: StrapiImage;
    DesktopImageAlt: string;
    DesktopImageURL: string;
    HasSubtitle: boolean;
    HasTrustpilot: boolean;
    MobileImage?: StrapiImage;
    MobileImageAlt: string;
    MobileImageURL: string;
    ExcellentText: string;
    Subtitle: string;
    Title: string;
    TitleHeading: {
      TitleTypeSelection: TitleType;
    };
    NewTab: boolean;
    NoFollow: boolean;
    ButtonId: string;
    ButtonLabel: string;
    ButtonUrl: string;
    HasText: boolean;
    Text: string;
    TrustpilotLink?: string;
  };
}

const BannerRich = ({ data }: BannerRichI) => {
  const {
    BannerBackgroundColor,
    BannerConfiguration,
    TrustpilotScore,
    DesktopImage,
    DesktopImageAlt,
    DesktopImageURL,
    HasSubtitle,
    HasTrustpilot,
    MobileImage,
    MobileImageAlt,
    MobileImageURL,
    ExcellentText,
    Subtitle,
    Title,
    TitleHeading,
    NewTab,
    NoFollow,
    ButtonId,
    ButtonLabel,
    ButtonUrl,
    HasText,
    Text,
    TrustpilotLink
  } = data;

  const backgroundColor = {
    Blue_Gray: "#F5F7FA",
    Light_Blue: "#ECF0FF",
    White: "#FFFFFF",
    Deep_Life_Blue: "#3430E3",
  }[BannerBackgroundColor?.BackgroundColor || "White"];

  const titleColor =
    {
      Deep_Life_Blue: "#FFFFFF",
    }[BannerBackgroundColor?.BackgroundColor] || "#3430E3";

  const textColor =
    {
      Deep_Life_Blue: "#FFFFFF",
    }[BannerBackgroundColor?.BackgroundColor] || "#424242";

  const buttonIsSecondary =
    BannerBackgroundColor?.BackgroundColor === "Deep_Life_Blue";
  const isRight = BannerConfiguration.Position === "Right";
  const isLeft = BannerConfiguration.Position === "Left";

  const titleC = getTitleHTMLTag(
    <span style={{ color: titleColor }}>{Title}</span>,
    TitleHeading?.TitleTypeSelection || "H2",
    `text-center font-bold md:text-[36px] text-[30px] md:leading-[42px] leading-[36px] tracking-[-0.018em]`
  );

  const subtitleC = HasSubtitle
    ? getTitleHTMLTag(
        <span style={{ color: textColor }}>{Subtitle}</span>,
        "p",
        `text-center font-normal md:text-[20px] text-[18px] md:leading-[28px] leading-[26px] md:tracking-[-0.014em] tracking-[-0.012em]`
      )
    : null;

  const textC = HasText
    ? getTitleHTMLTag(
        <span style={{ color: textColor }}>{Text}</span>,
        "p",
        `text-center font-normal md:text-[24px] text-[20px] md:leading-[32px] leading-[28px] md:tracking-[-0.016em] tracking-[-0.014em]`
      )
    : null;

  return (
    <div className="xl:px-[0px] md:px-[24px] py-[40px] px-[32px]">
      <div
        className="grid md:grid-cols-12 grid-cols-1 md:gap-[32px] min-h-[230px] items-center max-w-content mx-auto border-3 border-[#423EF4] rounded-[12px] overflow-hidden"
        style={{ backgroundColor }}
      >
        {isLeft ? (
          <>
            <div className="md:col-start-1 md:col-end-9 md:row-start-1 h-full">
              {DesktopImageURL ? (
                <img
                  className={`h-[230px] min-h-full w-full object-cover object-center hidden md:block`}
                  src={DesktopImageURL}
                  alt={DesktopImageAlt}
                />
              ) : (
                <GatsbyImage
                  className={`h-[230px] min-h-full w-full hidden md:block`}
                  image={DesktopImage?.file?.childImageSharp?.gatsbyImageData}
                  alt={DesktopImageAlt}
                />
              )}
              {MobileImageURL ? (
                <img
                  className={`h-[230px] min-h-full w-full object-cover object-center md:hidden`}
                  src={MobileImageURL}
                  alt={MobileImageAlt}
                />
              ) : (
                <GatsbyImage
                  className={`h-[230px] min-h-full w-full md:hidden`}
                  image={MobileImage?.file?.childImageSharp?.gatsbyImageData}
                  alt={MobileImageAlt}
                />
              )}
            </div>
            <div
              className="md:col-start-6 md:col-end-13 md:row-start-1 md:h-full h-fit md:rounded-s-full rounded-t-[42px] md:mt-0 mt-[-68px] z-10"
              style={{ backgroundColor }}
            >
              <div className="lg:mx-[100px] md:ml-[56px] h-full flex items-center justify-center flex-col md:gap-[6px] gap-[4px] py-[24px] px-[16px]">
                <div className="flex flex-col w-full md:gap-[0px] gap-[4px]">
                  {titleC}
                  {subtitleC}
                </div>
                {textC}
                {HasTrustpilot ? (
                  <TrustPilotDS
                    CurrentRateLabel={TrustpilotScore}
                    OutOfLabel={ExcellentText}
                    className={`md:!text-left !text-center md:mt-[8px]`}
                    color={textColor}
                    TrustpilotLink={TrustpilotLink}
                  />
                ) : null}
                <Button
                  id={ButtonId}
                  label={ButtonLabel}
                  href={ButtonUrl}
                  noFollow={NoFollow}
                  newTab={NewTab}
                  rightIcon="ArrowRightIcon"
                  wrapperClassname="!w-fit md:mt-[6px] mt-[12px]"
                  className="text-center"
                  secondary={buttonIsSecondary}
                />
              </div>
            </div>
          </>
        ) : null}
        {isRight ? (
          <>
            <div
              className="md:col-start-1 md:col-end-6 md:row-start-1 row-start-2 h-full"
              style={{ backgroundColor }}
            >
              <div className="h-full flex items-center justify-center flex-col md:gap-[6px] gap-[4px] py-[24px] md:pr-[0px] px-[16px]">
                <div className="flex flex-col w-full md:gap-[0px] gap-[4px]">
                  {titleC}
                  {subtitleC}
                </div>
                {textC}
                {HasTrustpilot ? (
                  <TrustPilotDS
                    CurrentRateLabel={TrustpilotScore}
                    OutOfLabel={ExcellentText}
                    className={`md:!text-left !text-center md:mt-[8px]`}
                    color={textColor}
                    TrustpilotLink={TrustpilotLink}
                  />
                ) : null}
                <Button
                  id={ButtonId}
                  label={ButtonLabel}
                  href={ButtonUrl}
                  noFollow={NoFollow}
                  newTab={NewTab}
                  rightIcon="ArrowRightIcon"
                  wrapperClassname="!w-fit md:mt-[6px] mt-[12px]"
                  className="text-center"
                  secondary={buttonIsSecondary}
                />
              </div>
            </div>
            <div className="md:col-start-6 md:col-end-13 row-start-1 md:h-full h-fit rounded-s-full md:ml-0 ml-[24px] md:mt-0 mt-[16px] overflow-hidden">
              {DesktopImageURL ? (
                <img
                  className={`h-[230px] min-h-full w-full object-cover object-center hidden md:block`}
                  src={DesktopImageURL}
                  alt={DesktopImageAlt}
                />
              ) : (
                <GatsbyImage
                  className={`h-[230px] min-h-full w-full hidden md:block`}
                  image={DesktopImage?.file?.childImageSharp?.gatsbyImageData}
                  alt={DesktopImageAlt}
                />
              )}
              {MobileImageURL ? (
                <img
                  className={`h-[230px] min-h-full w-full object-cover object-center md:hidden`}
                  src={MobileImageURL}
                  alt={MobileImageAlt}
                />
              ) : (
                <GatsbyImage
                  className={`h-[230px] min-h-full w-full md:hidden`}
                  image={MobileImage?.file?.childImageSharp?.gatsbyImageData}
                  alt={MobileImageAlt}
                />
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default BannerRich;
