import React from 'react'
import MaxContent from '../../layout/MaxContent'
import { TitleType } from '../Types';
import { getTitleHTMLTag } from '../../helpers/tools';


interface StrapiData {
  data: {
    Title: string;
    SelectTitleType: {
      TitleTypeSelection: TitleType;
    };
  };
}

const Title: React.FC<StrapiData> = ({ data }) => {

  let title = getTitleHTMLTag(
    data.Title,
    data.SelectTitleType?.TitleTypeSelection || "H1",
    "h3-default text-purple mb-[20px]"
  );

  return <MaxContent size={"content"}>{data.Title && title}</MaxContent>;
}

export default Title