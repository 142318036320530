import React from "react";
import { Link } from "gatsby";
import { Typography } from "get-life-storybook-ts";
import TrustPilotLogoBlack from "./TrustPilotLogoBlack";
import { translate } from "../../contexts/i18n.context";

const TrustPilotStaticWidget = ({ score }: { score: string }) => {
  
  return (
    <Link
      to={translate("truspilot.link")}
      className="no-underline"
      target="_blank"
    >
      <div className="flex items-center justify-center flex-row gap-1 font-DM font-normal text-black">
        <Typography variant="BodyMedium" weight="700" text={translate("truspilot.excellent")} />
        <Typography variant="BodyMedium" text=" | " />
        {score && <Typography variant="BodyMedium" text={score} weight="700" />}
        <Typography variant="BodyMedium" text={translate("truspilot.truspilot")} />

      </div>
      <div className="flex items-center justify-center flex-row gap-1 ">
        <TrustPilotLogoBlack />
      </div>
    </Link>
  );
};

export default TrustPilotStaticWidget;
