import React, { useState, useRef } from "react";
import { a, useSpring, config } from 'react-spring'
import { useOnScreen } from '../Hooks'

interface BubbleProps {
  x: number,
  y: number,
  size: number,
  selected?: boolean
}

const AnimatedBubble: React.FC<BubbleProps> = ({
  x,
  y,
  size,
  selected,
}) => {
  const [ reverse, setReverse ] = useState<boolean>(false)

  const getRand = () => {
    // 50% chance negative, clamped between -50 & 50
    return Math.floor((Math.random() - 0.5) * 50)
  }
  
  const spring = useSpring({
    config: { ...config.molasses, duration: 2000 + (getRand() * 2)},
    from: { transform: `translate3d(${x}px,${y}px,0)`},
    to: [
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)`},
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)`},
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)`},
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)`},
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)`},
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)`},
      { transform: `translate3d(${x + getRand()}px,${y + getRand()}px,0)`},
      { transform: `translate3d(${x}px,${y}px,0)`},
    ],
    onRest: () => {setReverse(!reverse)},
    loop: true,
    reverse: reverse,
  })

  return (
    <a.div className={`absolute rounded-full opacity-20 z-10 ${ selected ? 'bg-red' : 'bg-purple' }`} style={{...spring, width: size, height: size}} />
  )
}

export default AnimatedBubble