import React from "react";
import MaxContent from "../../../layout/MaxContent";
import Markdown from "../../Markdown/Markdown";

interface LegalTableData {
  data: LegalTableProps;
}

type Col = { Title: string };
type Row = { Text: string };
interface LegalTableProps {
  Columns: Array<Col>;
  Rows: Array<Row>;
}

const LegalTable: React.FC<LegalTableData> = ({ data }) => {
  let { Rows } = data;
  const cellStyle =
    "text-start align-top w-fit break-words text-body-md p-[10px] md:px-[20px] md:py-[20px] border border-[#EDEDED] text-placeholder";
  const headerStyle =
    "h6-default font-bold text-left break-words text-white bg-purple border-white border-r p-5";

  const getRows = () => {
    const MAX = data.Columns.length ?? 2

    const result = Rows.reduce((acc, item, index) => {
      const subIndex = Math.floor(index / MAX);
      if (!acc[subIndex]) {
        // @ts-ignore
        acc[subIndex] = [];
      }
      // @ts-ignore
      acc[subIndex].push(item.Text);

      return acc;
    }, []);

    const rows = result.map((row, index): any => {
      return (
        <tr key={`row-${index}`} className="">
          {/* @ts-ignore */}
          {row.map((cell: string) => {
            return (
              <td className={cellStyle}>
                <Markdown>{cell}</Markdown>
              </td>
            );
          })}
        </tr>
      );
    });

    return rows;
  };

  return (
    //cs added my-10 to table to add space bug 330
    <MaxContent size="content">
      <div className="overflow-x-auto my-[30px] md:my-10">
        <table className="table-fixed w-full min-w-[400px]">
          <thead>
            <tr>
              {data.Columns.map((col: any, i: number) => (
                <th key={`legal-header-${i}`} className={headerStyle}>
                  {col?.Title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>{getRows()}</tbody>
        </table>
      </div>
    </MaxContent>
  );
};

export default LegalTable;
