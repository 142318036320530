import React from "react";
import { Snackbar } from "get-life-storybook-ts";

interface SnackbarInterface {
  data: {
    TitleSnackbar: string;
    Subtitle?: string | null;
    CTA: string;
    URL: string;
    Target?: "_self" | "_blank" | "_parent" | "_top";
  };
}

const SnackbarComponent: React.FC<SnackbarInterface> = ({
  data,
}: SnackbarInterface) => {
  return (
    <>
      {/* Fix to avoid mobile navbar pushing sticky snackbar */}
      <div style={{ position: "fixed" }} />
      <Snackbar
        title={data.TitleSnackbar}
        subtitle={data.Subtitle}
        CTA={data.CTA}
        url={data.URL}
        target={data.Target}
      />
    </>
  );
};

export default SnackbarComponent;
