import React , { useEffect } from "react";
import MaxContent from "../../layout/MaxContent";


interface StrapiData {
  data: HubspotProps
}

type HubspotProps = {
  region: string
  portalId: string
  formId: string
}

const Hubspot: React.FC<StrapiData> = ({ data }) => {
  const targetId = 'hubspot-' + data.formId

  useEffect(() => {
    const form: HTMLElement | null = document.getElementById(targetId)
    
    const interval = setInterval(() => {
      // console.log('This will be called every 1 seconds');

      // @ts-ignore
      if (typeof window.hbspt !== 'undefined') {

        if(form) {          
          // @ts-ignore
          window.hbspt.forms.create({
            region: data.region,
            portalId: data.portalId,
            formId: data.formId,
            target: '#' + targetId,
          })

          clearInterval(interval);
        }
      }

    }, 1000);
    return () => clearInterval(interval);
  }, []);
  
  
  
  return (
    <MaxContent size="content">
      <div id={targetId} className='py-10'/>
    </MaxContent>
  )
}

export default Hubspot