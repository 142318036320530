import React, { useEffect, useState, useRef } from "react";
import { ILink } from "../Interfaces";
import { useDevice } from "../Hooks";
import Button from "../Inputs/Button";
import GatsbyLink from "gatsby-link";
import { StrapiImage } from "../Types";
import { ButtonDS, GlobalIcon } from "get-life-storybook-ts";

interface StrapiData {
  data: NavProps;
}

interface NavProps {
  DropdownLabel: string;
  DropdownLinks: Array<ILink>;
  Links: Array<ILink>;
  Phone?: string;
  HideAccount?: boolean;
  AccountIcon: StrapiImage;
  AccountUrl: string;
  AccountLabel: string;
  AccountNoFollow?: boolean;
  AccountNewTab?: boolean;
  ButtonLabel: string;
  ButtonUrl: string;
  ButtonNoFollow?: boolean;
  ButtonNewTab?: boolean;
  idButton?: string;
  HidePhone?: boolean;
}

const Navbar: React.FC<StrapiData> = ({ data }) => {
  const [openDropdown, setDropdown] = useState<boolean>(false);
  const [openMoreDropdown, setOpenMoreDropdown] = useState<boolean>(false);
  const [openMobileMenu, setMobileMenu] = useState<boolean>(false);
  const { isMobileTablet, isTabletLandscape } = useDevice();
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    let slug = window.location.pathname;
    setUrl(slug);
  });

  const {
    DropdownLabel,
    DropdownLinks,
    Links,
    Phone,
    HideAccount,
    AccountIcon,
    AccountUrl,
    AccountLabel,
    AccountNoFollow,
    AccountNewTab,
    HidePhone = true,
  } = data;

  return (
    <nav className="sticky top-0 bg-white  w-full gap-3  lg:drop-shadow-lg border-b border-b-neutral-200 z-50">
      <div className="nav-bar flex flex-row mx-auto h-[80px] px-[24px] md:px-[64px] gap-[20px]">
        <div className="flex items-center mr-[8px] md:mr-0">
          <GatsbyLink to="/" className="flex items-center outline-offset-2">
            <img
              src={
                "https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.svg"
              }
              className={"md:w-[95px] md:h-[40px] w-[80px] h-[34px]"}
            />
          </GatsbyLink>
        </div>

        {/* Desktop */}
        {DropdownLabel && DropdownLabel !== " " && (
          <div className="hidden lg:flex items-center overflow-hidden flex-wrap flex-1 lg:gap-[8px]">
            {/* Dropdown */}
            <div
              className="flex items-center h-full outline-none"
              onMouseOver={() => setDropdown(true)}
              onMouseOut={() => setDropdown(false)}
              onFocus={() => setDropdown(true)}
              onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                  setDropdown(false);
                }
              }}
              tabIndex={0}
              role="button"
            >
              <div className="flex items-center relative border-purple h-full cursor-pointer">
                <span
                  className={`flex flex-row items-center gap-3 px-5 no-underline whitespace-nowrap text-link-sm font-medium h-full text-[#424242]
                ${openDropdown ? "text-purple" : ""}`}
                >
                  {DropdownLabel}
                  <GlobalIcon
                    iconName="ArrowDownIcon"
                    className={`${openDropdown ? "rotate-180" : ""}`}
                    color="currentColor"
                    size="XXS"
                  />
                </span>
              </div>

              {/* Dropdown Body */}
              {openDropdown && (
                <div className="absolute top-full z-100">
                  <div className="flex flex-col bg-white rounded-3xl mt-[-8px] overflow-hidden">
                    {DropdownLinks.map((link) => (
                      <GatsbyLink
                        to={link.Url}
                        className="no-underline text-[#424242] p-[16px] text-link-sm font-medium hover:bg-[#F5F7FA] focus:bg-[#F5F7FA] active:text-purple active:bg-white outline-none"
                        key={link.Url + "dropdownLinks"}
                      >
                        {link.Label}
                      </GatsbyLink>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Links */}

            {!isTabletLandscape &&
              Links.map((link, i) => (
                <NavItem link={link} url={url} key={i + "Links"} />
              ))}

            {isTabletLandscape && DropdownLinks.length > 0 && (
              <div
                className="flex items-center h-full outline-none"
                onMouseOver={() => setOpenMoreDropdown(true)}
                onMouseOut={() => setOpenMoreDropdown(false)}
                onFocus={() => setOpenMoreDropdown(true)}
                onBlur={(e) => {
                  if (!e.currentTarget.contains(e.relatedTarget)) {
                    setOpenMoreDropdown(false);
                  }
                }}
                tabIndex={0}
                role="button"
              >
                <div
                  className={`flex flex-row items-center gap-3 px-5 no-underline whitespace-nowrap text-link-sm font-medium h-full text-[#424242] ${
                    openMoreDropdown ? "text-purple" : ""
                  }`}
                >
                  <span className="flex flex-row items-center gap-3 text-[16px] leading-[26px] font-medium h-full no-underline m-0">
                    Suite
                    <GlobalIcon
                      iconName="ArrowDownIcon"
                      className={`${openMoreDropdown ? "rotate-180" : ""}`}
                      color="currentColor"
                      size="XXS"
                    />
                  </span>
                </div>
                {openMoreDropdown && DropdownLinks.length > 0 && (
                  <div className="absolute top-full z-100">
                    <div className="flex flex-col bg-white rounded-3xl mt-[-8px] overflow-hidden">
                      {Links.map((link) => (
                        <GatsbyLink
                          to={link.Url}
                          className="no-underline text-[#424242] p-[16px] text-link-sm font-medium hover:bg-[#F5F7FA] focus:bg-[#F5F7FA] active:text-purple active:bg-white outline-none"
                          key={link.Url + "dropdownLinks"}
                        >
                          {link.Label}
                        </GatsbyLink>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {/* CTA Section */}
        <div className="flex items-center xl:gap-[24px] gap-[16px] shrink min-w-fit ml-auto">
          {AccountIcon && AccountUrl && !isMobileTablet && !HideAccount && (
            <Button
              ghost={true}
              label={AccountLabel || ""}
              href={AccountUrl || "/"}
              noFollow={AccountNoFollow}
              newTab={AccountNewTab}
              className="whitespace-nowrap"
              size="40"
            />
          )}

          {data.ButtonLabel && (
            <Button
              label={data.ButtonLabel}
              href={data.ButtonUrl}
              noFollow={data.ButtonNoFollow}
              newTab={data.ButtonNewTab}
              className="whitespace-nowrap"
              wrapperClassname="hidden lg:flex"
              id={data.idButton ? data.idButton : "cta-navbar"}
              size="40"
            />
          )}

          {Phone && (
            <ButtonDS
              label={Phone}
              href={`tel:/${Phone}`}
              leftIcon="PhoneCallIcon"
              ghost={true}
              buttonType="primary"
              className={`lg:flex whitespace-nowrap ${
                HidePhone === false ? "flex" : "hidden"
              }`}
              size="40"
            />
          )}

          {DropdownLinks.length > 0 && (
            <span
              className="lg:hidden w-[24px] h-[24px] flex justify-center items-center"
              onClick={() => setMobileMenu(!openMobileMenu)}
              onKeyDown={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  e.preventDefault();
                  setMobileMenu(!openMobileMenu);
                }
              }}
              tabIndex={0}
              role="button"
            >
              <GlobalIcon
                iconName={openMobileMenu ? "CloseIcon" : "MenuIcon"}
                color="#000000"
              />
            </span>
          )}
        </div>

        {/* Mobile Menu Overlay */}
        {isMobileTablet && openMobileMenu && DropdownLinks.length > 0 && (
          <div className="fixed top-0 left-0 w-full h-[calc(100%_-_228px)] z-10 translate-y-[80px] bg-white rounded-b-[24px] overflow-hidden shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.07),_0px_3px_3px_0px_rgba(0,_0,_0,_0.06),_0px_8px_8px_0px_rgba(0,_0,_0,_0.10)]">
            <div className="flex flex-col overflow-y-auto overscroll-contain h-full px-[24px] pb-[24px] pt-[8px]">
              <div
                className={`flex flex-row gap-[12px] py-[24px] items-center no-underline font-bold text-[16px] leading-[26px] ${
                  openDropdown ? "text-purple" : "text-[#424242]"
                }`}
                onClick={() => setDropdown(!openDropdown)}
                onKeyDown={(e) => {
                  if (e.key === " " || e.key === "Enter") {
                    e.preventDefault();
                    setDropdown(!openDropdown);
                  }
                }}
                tabIndex={0}
                role="button"
              >
                {DropdownLabel}
                <GlobalIcon
                  iconName="ArrowDownIcon"
                  className={`${openDropdown ? "rotate-180" : ""}`}
                  color="currentColor"
                  size="XXS"
                />
              </div>

              {/* Mobile Dropdown Body */}
              {openDropdown && DropdownLinks.length > 0 && (
                <div className="flex flex-col">
                  {DropdownLinks.map((link) => (
                    <GatsbyLink
                      to={link.Url}
                      className="no-underline font-medium text-[16px] leading-[26px] p-[16px] text-[#686868]"
                      key={link.Url + "opendropdown"}
                    >
                      {link.Label}
                    </GatsbyLink>
                  ))}
                </div>
              )}

              {Links.map((link) => (
                <GatsbyLink
                  to={link.Url}
                  className="no-underline font-medium text-[16px] leading-[26px] text-[#424242] py-[24px]"
                  key={link.Label}
                >
                  {link.Label}
                </GatsbyLink>
              ))}

              <div className="flex flex-col gap-[16px] mt-auto">
                {Phone && (
                  <ButtonDS
                    label={Phone}
                    href={`tel:/${Phone}`}
                    leftIcon="PhoneCallIcon"
                    ghost={true}
                    buttonType="primary"
                    size="40"
                  />
                )}
                {data.ButtonLabel && (
                  <Button
                    label={data.ButtonLabel}
                    href={data.ButtonUrl}
                    noFollow={data.ButtonNoFollow}
                    newTab={data.ButtonNewTab}
                    size="40"
                  />
                )}
                {!HideAccount && (
                  <Button
                    label={AccountLabel || ""}
                    href={AccountUrl || "/"}
                    noFollow={AccountNoFollow}
                    newTab={AccountNewTab}
                    ghost={true}
                    size="40"
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

const NavItem = ({ link, url }: { link: ILink; url: string }) => {
  return (
    <div className={`flex items-center h-full`}>
      <GatsbyLink
        to={link.Url}
        className="px-2 xl:px-5 text-link-sm no-underline font-medium whitespace-nowrap cursor-pointer h-full flex items-center hover:text-purple focus:text-purple text-[#424242] outline-none"
      >
        {link.Label}
      </GatsbyLink>
    </div>
  );
};

export default Navbar;
