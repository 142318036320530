
import React from "react";
import LogoBlack from '../../assets/TrustPilot/logo-black.png'


const TrustPilotStarLogo = () => {
  return (
    <div className={"flex items-end mb-1"}>
      <img
        src={LogoBlack}
        className={"w-[75px] h-full"}
        alt={"Trust pilot logo"}
      />
    </div>
  );
};

export default TrustPilotStarLogo;