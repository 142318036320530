import React from "react";
import Carousel from "./Carousel";
import MaxContent from "../../layout/MaxContent";
import Button from "../Inputs/Button";
import Markdown from "../Markdown/Markdown";
import { getTitleHTMLTag } from "../../helpers/tools";
import { StrapiImage } from "../Types";

interface StrapiData {
  data: ProductProps;
}

interface ProductProps {
  Cards: InsuranceCardProps[];
  Title: string;
  SubTitle?: string;
  SelectTitleType: {
    TitleTypeSelection: string;
  };
}

const ProductCarousel: React.FC<StrapiData> = ({ data }) => {
  let { Title, SubTitle, Cards } = data;

  let title = getTitleHTMLTag(
    Title,
    data.SelectTitleType?.TitleTypeSelection,
    "my-3 text-[25px] leading-[35px] lg:text-[35px] lg:leading-[45px] font-bold text-purple md:text-center text-left"
  );

  return (
    <MaxContent size="content">
      <div className="pt-[60px] pb-[60px]">
        {Title && title}

        {SubTitle && (
          <div className="h3-default font-MW text-black md:text-center text-left text-[16px] leading-[26px] md:text-[20px] md:leading-[36px] mb-8 md-content">
            <Markdown>{SubTitle}</Markdown>
          </div>
        )}

        {/* needs to have minimum 2 cards */}
        {Cards.length > 1 && (
          <Carousel>
            {Cards.map((card) => (
              <InsuranceCard {...card} size={Cards.length} key={card.Title} />
            ))}
          </Carousel>
        )}
      </div>
    </MaxContent>
  );
};

interface InsuranceCardProps {
  Title: string;
  Text: string;
  Price: string;
  PriceFromLabel: string;
  PricePeriodLabel: string;
  ImageURL?: string;
  AlternativeText?: string;
  SingleImage: StrapiImage;
  Colour: string;
  size: number;
  PrimaryButtonLabel: string;
  PrimaryButtonUrl: string;
  SecondaryButtonLabel: string;
  SecondaryButtonUrl: string;
  PrimaryButtonNoFollow?: boolean;
  PrimaryButtonNewTab?: boolean;
  SecondaryButtonNoFollow?: boolean;
  SecondaryButtonNewTab?: boolean;
  ProductTitleType?: string;
}

export const InsuranceCard: React.FC<InsuranceCardProps> = ({
  Title,
  Text,
  Price,
  PriceFromLabel,
  PricePeriodLabel,
  ImageURL,
  AlternativeText,
  SingleImage,
  Colour,
  size,
  PrimaryButtonLabel,
  PrimaryButtonUrl,
  SecondaryButtonLabel,
  SecondaryButtonUrl,
  PrimaryButtonNoFollow,
  PrimaryButtonNewTab,
  SecondaryButtonNoFollow,
  SecondaryButtonNewTab,
  ProductTitleType
}) => {
  const getInnerCardWidth = () => {
    /* Cards are different sizes depending on how many there are
     * this function helps differentiate them using the size prop
     */
    switch (size) {
      case 2:
        return "max-w-[300px] md:max-w-[360px]";
      case 3:
        return "max-w-[300px] md:max-w-[250px]";
      default:
        return "w-full"; //TODO: something here
    }
  };

  /* https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
    Tailwind JIT wasnt working so we use raw CSS instead
    We add OpacityPercent to the end of ColourMap[Colour] to reduce the opacity as HEX works the same as RGBA
    eg. #FFDB33 = R(FF)G(DB)B(33) + A(OpacityPercent)
  */

    let CardTitle = getTitleHTMLTag(
      Title,
      ProductTitleType || "H3",
      "my-5 font-bold h4-default text-black select-none"
    );

  return (
    <div
      className={`flex flex-col p-[25px]  justify-between items-center border-t-[5px] border-b-2 w-[300px] md:w-full insuranceCard--${Colour}`}
    >
      <div
        className={`flex flex-col h-full w-full md:p-0  ${getInnerCardWidth()}`}
      >
        {SingleImage?.file?.url ? (
          <div className="flex flex-col     items-center my-[50px]">
            <img
              loading="lazy"
              className="select-none h-44 w-full object-contain insuranceCard--image"
              src={SingleImage?.file?.url}
              alt={SingleImage?.alternativeText}
            />
          </div>
        ) : ImageURL ? (
          <div className="flex flex-col     items-center my-[50px]">
            <img
              loading="lazy"
              className="select-none h-44 w-full object-contain insuranceCard--image"
              src={ImageURL}
              alt={AlternativeText}
            />
          </div>
        ) : null}
        <div className="flex-2">
          {CardTitle}
          {Price && (
            <div>
              <span className="select-none text-body-lg">{PriceFromLabel} </span>
              <span className="text-[70px] leading-[70px] font-bold text-black select-none">
                {Price}
              </span>
              <span className="text-[30px] leading-[70px] select-none">€</span>
              <span className="select-none text-body-lg">
                {" "}
                {PricePeriodLabel}
              </span>
            </div>
          )}

          <div className="my-5 font-MW text-placeholder text-[20px] leading-[30px] select-none md-content">
            <Markdown>{Text}</Markdown>
          </div>
        </div>
      </div>

      <div
        className={`flex flex-col w-full flex-1 items-center mt-3 mb-[20px] md:mb-[70px] gap-3 ${getInnerCardWidth()}`}
      >
        {PrimaryButtonLabel && (
          <Button
            label={PrimaryButtonLabel}
            href={PrimaryButtonUrl || ""}
            noFollow={PrimaryButtonNoFollow ?? false}
            newTab={PrimaryButtonNewTab ?? false}
          />
        )}
        {SecondaryButtonLabel && (
          <Button
            label={SecondaryButtonLabel}
            href={SecondaryButtonUrl || ""}
            secondary={true}
            noFollow={SecondaryButtonNoFollow ?? false}
            newTab={SecondaryButtonNewTab ?? false}
          />
        )}
      </div>
    </div>
  );
};

export default ProductCarousel;
