import React from "react";
import { BackgroundColorI, StrapiImage, TitleType } from "../Types";
import CalculatorModuleExample from "../CalculatorModule/CalculatorExample";
import { GatsbyImage } from "gatsby-plugin-image";
import { getTitleHTMLTag } from "../../helpers/tools";
import { GlobalIcon, Typography } from "get-life-storybook-ts";
import TrustPilotDS from "../TrustPilot/TrustPilotDS";

interface HeroCalculatorImageI {
  data: {
    BackgroundColor: BackgroundColorI;
    CalculatorModule: {
      Url: string;
    }[];
    TrustpilotScore: string;
    DesktopImage?: StrapiImage;
    DesktopImageURL?: string;
    DesktopImageAlt: string;
    HasArrow: boolean;
    HasSerifTitle: boolean;
    HasSubtitle: boolean;
    HasTrustpilotRating: boolean;
    HeroSansTitle: string;
    HeroSerifTitle: string;
    HeroTitleHeading: {
      TitleTypeSelection: TitleType;
    };
    MobileImage?: StrapiImage;
    MobileImageURL?: string;
    MobileImageAlt: string;
    ExcellentText: string;
    Subtitle: string;
    SubtitleHeading: {
      TitleTypeSelection: TitleType;
    };
    noSignUp?: boolean;
    ImageHasRadius: boolean;
    ImageSize: "Large" | "Medium";
    TrustpilotLink?: string;
  };
}

const HeroCalculatorImage = ({ data }: HeroCalculatorImageI) => {
  const {
    BackgroundColor,
    TrustpilotScore,
    DesktopImage,
    DesktopImageURL,
    DesktopImageAlt,
    HasArrow,
    HasSerifTitle,
    HasSubtitle,
    HasTrustpilotRating,
    HeroSansTitle,
    HeroSerifTitle,
    HeroTitleHeading,
    MobileImage,
    MobileImageURL,
    MobileImageAlt,
    ExcellentText,
    Subtitle,
    SubtitleHeading,
    ImageHasRadius,
    ImageSize,
    TrustpilotLink
  } = data;

  const backgroundColor = {
    Blue_Gray: "#F5F7FA",
    Light_Blue: "#ECF0FF",
    White: "#FFFFFF",
  }[BackgroundColor.BackgroundColor || "White"];

  const calculatorBackgroundColor = {
    Blue_Gray: "#FFFFFF",
    Light_Blue: "#F5F7FA",
    White: "#F5F7FA",
  }[BackgroundColor.BackgroundColor || "White"];

  const imageHeight = {
    Large: "965px",
    Medium: "520px",
  }[ImageSize];

  const imageOffset = {
    Large: "-24px",
    Medium: "0px",
  }[ImageSize];

  const prepareDataForCalculator = () => {
    return data?.CalculatorModule.map((cm) => {
      return {
        Url: cm.Url,
        noSignUp: data?.noSignUp,
        backgroundColor: calculatorBackgroundColor,
      };
    });
  };

  let titleC = getTitleHTMLTag(
    <>
      <span className="font-DM font-bold md:text-[48px] text-[32px] md:leading-[48px] leading-[41.66px] tracking-[-0.02em]">
        {HeroSansTitle}
      </span>
      {HasSerifTitle ? (
        <span className="font-MW font-light md:text-[45px] text-[30px] md:leading-[56.57px] leading-[37.71px] tracking-[-0.02em]">
          {HeroSerifTitle}
        </span>
      ) : null}
    </>,
    HeroTitleHeading?.TitleTypeSelection,
    "text-[#424242] flex flex-col"
  );

  let subtitleC = getTitleHTMLTag(
    Subtitle,
    SubtitleHeading?.TitleTypeSelection,
    "text-[var(--primary-color)] font-medium md:text-[22px] text-[18px] md:leading-[28.64px] leading-[23.44px] tracking-[-0.02em] md:mt-[16px] mt-[8px]"
  );

  return (
    <div className="overflow-hidden" style={{ backgroundColor }}>
      <div className="grid md:grid-cols-12 gap-[32px] items-center max-w-content mx-auto py-[80px] xl:px-[0px] px-[24px]">
        <div className="md:col-span-5 max-w-[448px] md:max-w-none relative">
          <div className="mb-[24px]">
            {titleC}
            {HasSubtitle ? subtitleC : null}
          </div>
          <CalculatorModuleExample data={prepareDataForCalculator()} />
          {HasTrustpilotRating ? (
            <TrustPilotDS
              CurrentRateLabel={TrustpilotScore}
              OutOfLabel={ExcellentText}
              className="text-center mt-[32px] w-full"
              TrustpilotLink={TrustpilotLink}
            />
          ) : null}
          {HasArrow ? (
            <GlobalIcon
              iconName="LoopingArrow"
              className="hidden md:block [&>svg]:w-[303px] [&>svg]:h-[303px] absolute bottom-[80px] right-[-270px] z-[1]"
            />
          ) : null}
        </div>
        <div
          className={`heroCalculatorImage_ImageHeight md:col-start-7 md:col-end-13 relative`}
          style={
            {
              "--heroCalculatorImageHeight": imageHeight,
              "--heroCalculatorImageOffset": imageOffset,
            } as React.CSSProperties
          }
        >
          {DesktopImageURL ? (
            <img
              className={`h-full absolute w-[100vw] md:w-[50vw] overflow-hidden hidden md:block max-w-none object-cover object-left ${
                ImageHasRadius ? "rounded-s-full" : ""
              }`}
              src={DesktopImageURL}
              alt={DesktopImageAlt}
            />
          ) : (
            <GatsbyImage
              className={`h-full absolute w-[100vw] md:w-[50vw] overflow-hidden hidden md:block ${
                ImageHasRadius ? "rounded-s-full" : ""
              }`}
              image={DesktopImage?.file?.childImageSharp?.gatsbyImageData}
              alt={DesktopImageAlt}
              objectPosition={"left"}
            />
          )}
          {MobileImageURL ? (
            <img
              className={`h-full absolute w-[100vw] md:w-[50vw] overflow-hidden md:hidden max-w-none object-cover object-left ${
                ImageHasRadius ? "rounded-s-full" : ""
              }`}
              src={MobileImageURL}
              alt={MobileImageAlt}
            />
          ) : (
            <GatsbyImage
              className={`h-full absolute w-[100vw] md:w-[50vw] overflow-hidden md:hidden ${
                ImageHasRadius ? "rounded-s-full" : ""
              }`}
              image={MobileImage?.file?.childImageSharp?.gatsbyImageData}
              alt={MobileImageAlt}
              objectPosition={"left"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroCalculatorImage;
