import * as JsSearch from "js-search";
import { IFAQ } from '../components/FAQ/Faq'

type ReturnType = {
  search: (q: string) => IFAQ[];
};

export default function useJsSearch(blogs: IFAQ[]): ReturnType {
  // Search configuration
  const dataToSearch = new JsSearch.Search("id");
  dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
  dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
  dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id");

  // Fields to search
  dataToSearch.addIndex('Title');
  dataToSearch.addIndex('Category');

  // create nodes
  dataToSearch.addDocuments(blogs);

  const search = (query: string) => dataToSearch.search(query) as IFAQ[]

  return { search };
}