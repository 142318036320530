import { useContext, useEffect, useState } from "react";
import { useDevice } from "get-life-storybook-ts";
import {
  ErrorI,
  isOver,
  isUnder,
  isPhone,
  isEmail
} from "get-life-storybook-ts/lib/components/Validations";
import prepareCapitals from "../../helpers/Capitals/prepareCapitals";
import { getCapitals } from "../../helpers/sendJourneyData";
export interface StrapiDataCalculator {
  data: { Url: string }[];
}

interface Validations extends ErrorI<string | number> {}

interface FormDataFirstI {
  birthDate: string;
  capital: number | string;
  intention: string;
  redirect?: boolean;
}

interface GlobalInfoI {
  defaultcap: string | null;
  gtf_campaign: string | null;
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  tid: string | null;
  url: any;
}

interface FormDataSecondI {
  phone: string;
  email: string;
  checkedConditions: string | boolean;
  checkedConditions2: string | boolean;
}

const optionsIntention = [
  {
    label: "Mi familia",
    value: "family",
  },
  {
    label: "Mi hipoteca",
    value: "loan",
  },
];
const errorMessages: any = {
  birthDate: "Por favor, añade una fecha válida",
  intention: "Por favor seleccione una protección",
  capital: "Por favor seleccione un capital",
  phone: "Por favor inserte un número de teléfono correcto",
  email: "Por favor inserte un correo electrónico correcto",
  checkedConditions: "Debe aceptar el tratamiento de datos para continuar",
  checkedConditions2: "Debe aceptar las condiciones para continuar",
};

const API_URL = process.env.GATSBY_API_URL;

const CalculatorModuleController = ({ data }: StrapiDataCalculator) => {

  const [globalInfo, setGlobalInfo] = useState<GlobalInfoI>({
      defaultcap: "",
      // gtf_campaign: "calculator-cms",
      gtf_campaign: "3MESES1E",
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      tid: "",
      url: "",
    });
  const [formError, setFormError] = useState<any>();
  const [capitalOptions, setCapitalOptions] = useState<any>([]);
  const [actualStep, setActualStep] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [display, setDisplay] = useState<boolean>(false);
  const [leadId, setLeadId] = useState<string>("");
  const { isMobile, isDesktop, isTablet } = useDevice();

  const [formDataFirst, setFormDataFirst] = useState<FormDataFirstI>({
    birthDate: "",
    capital: 0,
    intention: "",
    redirect: false,
  });

  const [formDataSecond, setFormDataSecond] = useState<FormDataSecondI>({
    phone: "",
    email: "",
    checkedConditions: "",
    checkedConditions2: ""
  });

  useEffect(() => {
    (async () => {
      if (formDataFirst.birthDate) {
        if (typeof window !== undefined) {
          try {
            const errorBirth = validationDateOfBirth({
              value: formDataFirst.birthDate,
              min: 18,
              max: 75,
            });
            if (errorBirth.error) {
              throw errorBirth;
            }
            const capitals = await fetch(
              `${API_URL}/lead/capital/tarifier/extendedBoundaries/${formDataFirst.birthDate}`
            )
              .then((response) => response.json()) // parse JSON from request
              .then((resultData) => {
                return resultData;
              });

            const { options, indexPreSelected } = prepareCapitals({ capitals });

            setCapitalOptions(options);

            if (globalInfo.defaultcap) {
              let capital = options.find(
                (element) => element.value === parseInt(globalInfo.defaultcap!)
              );

              if (capital) {
                setFormDataFirst({
                  ...formDataFirst,
                  capital: capital!.value,
                  redirect: capital!.redirect ? true : false,
                });
                setFormError({
                  ...formError,
                  birthDate: { error: false, message: "" },
                });
              } else {
                setFormDataFirst({
                  ...formDataFirst,
                  capital: options[indexPreSelected].value,
                  redirect: options[indexPreSelected].redirect ? true : false,
                });
                setFormError({
                  ...formError,
                  birthDate: { error: false, message: "" },
                });
              }
            } else {
              setFormDataFirst({
                ...formDataFirst,
                capital: options[indexPreSelected].value,
                redirect: options[indexPreSelected].redirect ? true : false,
              });
              setFormError({
                ...formError,
                birthDate: { error: false, message: "" },
              });
            }
          } catch (error: any) {
            if (error.error) {
              setFormError({ ...formError, birthDate: error });
            } else {
              setFormError({
                ...formError,
                birthDate: { error: true, message: errorMessages.birthDate },
              });
            }
            setCapitalOptions([]);
          }
        }
      }
    })();
  }, [formDataFirst.birthDate]);

  const fieldValidation = ({ data, key, newError }: any) => {
    if (key !== "redirect") {
      if (!data[key]) {
        newError = {
          ...newError,
          [key]: { error: true, message: errorMessages[key] },
        };
      } else if (key === "phone" && isPhone({ value: data[key] }).error) {
        newError = {
          ...newError,
          [key]: { error: true, message: errorMessages[key] },
        };
      } else if (key === "email" && isEmail({ value: data[key] }).error) {
        newError = {
          ...newError,
          [key]: { error: true, message: errorMessages[key] },
        };
      } else {
        newError = { ...newError, [key]: { error: false, message: "" } };
      }
    }
    return newError;
  };

  const handleChange = ({ setValue, value, field, formData }: any) => {
    let newData = { ...formData, [field]: value };
    setValue(newData);
  };

  const handleBlur = ({ field, formData }: any) => {
    let newError: any = {};
    if (!!formData[field]) {
      newError = fieldValidation({ data: formData, key: field, newError });
      setFormError({ ...formError, ...newError });
    }
  };

  const handleButton = () => {}

  const validationDateOfBirth = ({ value, min, max }: Validations) => {
    const errorYoung = isOver({
      value: value as string,
      message: "Debes ser mayor de $ para registrarte.".replace(
        "$",
        min!.toString()
      ),
      min,
    });
    const errorElder = isUnder({
      value: value as string,
      message: "Debes ser menor de $ para registrarte.".replace(
        "$",
        max!.toString()
      ),
      max,
    });

    if (errorYoung.error) {
      return errorYoung;
    } else {
      return errorElder;
    }
  };

  return {
    actualStep,
    capitalOptions,
    display,
    formDataFirst,
    formDataSecond,
    formError,
    handleBlur,
    handleButton,
    handleChange,
    isMobile,
    loading,
    optionsIntention,
    setActualStep,
    setDisplay,
    setFormDataFirst,
    setFormDataSecond,
  };
};

export default CalculatorModuleController;
