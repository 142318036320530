import React , { useState } from "react";
import Markdown from '../Markdown/Markdown'
import MaxContent from "../../layout/MaxContent";



type TitleType = 'H2' | 'H3' | 'H4' | 'H5' 

interface StrapiData {
  data: {
    Title: string;
    Text: string;
    TitleType: TitleType
  };
}

const TitleText: React.FC<StrapiData> = ({ data }) => {
  let Title
  
  switch(data.TitleType) {
    case 'H2':
      Title = <h2 className="h2-default text-purple mb-[10px]">{data.Title}</h2>
      break;
    case 'H3':
      Title = <h3 className="h3-default text-purple mb-[10px]">{data.Title}</h3>
      break;
    case 'H4':
      Title = <h4 className="h4-default text-purple mb-[10px]">{data.Title}</h4>
      break;
    case 'H5':
      Title = <h5 className="h5-default text-purple mb-[10px]">{data.Title}</h5>
      break; 
    default:
      Title = <h3 className="h5-default text-purple mb-[10px]">{data.Title}</h3>
  }

  return (
    <MaxContent size={"content"}>
      <div className=" w-full md:max-w-[800px] ">
        { Title }
        <div className="font-DM text-body-md text-placeholder mb-[20px] ">
          <Markdown>{data.Text}</Markdown>
        </div>
      </div>
    </MaxContent>
  );
};

export default TitleText;
