import { createOptionsRange } from "./createoptionsRangeCapital";

export interface CapitalsI {
  minCapital: number;
  maxCapital: number;
  availableTraits: {
    DEATH: boolean;
    DISABILITY: boolean;
  };
}

export type PrepareCapitalsProps = {
  capitals: CapitalsI;
};

const prepareCapitals = ({ capitals }: PrepareCapitalsProps) => {
  const stepper = capitals.maxCapital === 50000 ? 5000 : 50000;
  const options = createOptionsRange({
    maxCapital: capitals.maxCapital,
    minCapital: capitals.minCapital,
    step: stepper,
  });

  const indexPreSelected =
    capitals.maxCapital === 50000
      ? Math.round(options.length / 2) - 2
      : Math.round(options.length / 2) - 1;

  return { options, indexPreSelected };
};

export default prepareCapitals;
