import React from "react";
import { Link } from "gatsby";
import MaxContent from "../../layout/MaxContent";
import LocaleToggle from "../LocaleToggle/LocaleToggle";
import { ILink } from "../Interfaces";

const fbLogo = "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/SocialMedia/facebook.svg";
const instaLogo = "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/SocialMedia/instagram.svg";
const twitterLogo = "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/SocialMedia/twitter.svg";
const youtubeLogo = "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/SocialMedia/youtube.svg";
const linkedinLogo = "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/SocialMedia/linkedin.svg";
const  mobilelogo = "https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.svg";

interface StrapiData {
  data: FooterData;
}

interface FooterData {
  group_title_1: string;
  group_links_1: ILink[];
  group_title_2: string;
  group_links_2: ILink[];
  group_title_3: string;
  group_links_3: ILink[];
  group_title_4: string;
  group_links_4: ILink[];
  group_links_bottom: ILink[];
  contact_title: string;
  contact_address: string;
  contact_map_link: ILink;
  contact_phone_subtitle: string;
  contact_phone_number: string;
  facebook_url: string;
  instagram_url: string;
  twitter_url: string;
  youtube_url: string;
  linkedin_url: string;
  form_header: string;
  form_submit_button_link: ILink;
  form_text_placeholder: string;
}

const Footer: React.FC<StrapiData> = ({ data }) => {
  const aStyle =
    "font-DM font-normal text-placeholder text-[14px] lg:text-[16px] leading-[30px] lg:leading-[30px] no-underline hover:text-purple hover:underline hover:decoration-2 hover:underline-offset-2 cursor-pointer";
  const titleStyle =
    "font-MW text-legal-gray text-[13px] lg:text-[14px] leading[22px] lg:leading[22px] mt-5 md:mt-2 mb-3";

  let {
    group_title_1,
    group_links_1,
    group_title_2,
    group_links_2,
    group_title_3,
    group_links_3,
    group_title_4,
    group_links_4,
    group_links_bottom,
    contact_title,
    contact_address,
    contact_map_link,
    contact_phone_subtitle,
    contact_phone_number,
    facebook_url,
    instagram_url,
    twitter_url,
    youtube_url,
    linkedin_url,
    form_header,
    form_submit_button_link,
    form_text_placeholder,
  } = data;

  return (
    <footer className="py-[70px]">
      <MaxContent size="content">
        <div className="flex flex-col lg:flex-row justify-between border-secondaryIron lg:border-b-[1px]  pb-8">
          
          {/* Logo */}
          <div className="flex flex-row justify-between">
            <img src={mobilelogo} className="w-[80px] lg:w-[95px]" alt="mobilelogo"/>

            <div className="block lg:hidden">
              <LocaleToggle />
            </div>
          </div>

          <div className="block lg:hidden border-b w-full my-[30px]" />

          {/* Social Media Icons */}
          <div className="flex flex-row space-x-2 items-center">
            <a href={facebook_url} target='_blank'>
              <img src={fbLogo} className="w-[30px] h-[30px]" alt="fblogo"/>
            </a>
            <a href={instagram_url} target='_blank'>
              <img src={instaLogo} className="w-[30px] h-[30px]" alt="instaLogo"/>
            </a>
            <a href={twitter_url} target='_blank'>
              <img src={twitterLogo} className="w-[30px] h-[30px]" alt="twitterLogo"/>
            </a>
            <a href={youtube_url} target='_blank'>
              <img src={youtubeLogo} className="w-[30px] h-[30px]" alt="youtubeLogo" />
            </a>
            <a href={linkedin_url} target='_blank'>
              <img src={linkedinLogo} className="w-[30px] h-[30px]" alt="linkednLogo"/>
            </a>
            
            <div className="hidden lg:block">
              <LocaleToggle />
            </div>
          </div>
        </div>

        {/* Footer Body */}
        <div className="grid grid-cols-1 lg:grid-cols-2">
          {/* Half Links Section */}
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="flex flex-col justify-between">
              <div className="flex flex-col">
                <p className={titleStyle}>{group_title_1}</p>
                {group_links_1 &&
                  group_links_1.map((item) => {
                    return (
                      <Link
                        className={aStyle}
                        to={item.Url}
                        key={item.Url + "group1"}
                      >
                        {item.Label}
                      </Link>
                    );
                  })}
              </div>
              <div className="flex flex-col">
                <p className={titleStyle}>{group_title_2}</p>
                {group_links_2 &&
                  group_links_2.map((item) => {
                    return (
                      <Link
                        className={aStyle}
                        to={item.Url}
                        key={item.Url + "group2"}
                      >
                        {item.Label}
                      </Link>
                    );
                  })}
              </div>
            </div>

            <div className="flex flex-col justify-between">
              <div className="flex flex-col">
                <p className={titleStyle}>{group_title_3}</p>
                {group_links_3 &&
                  group_links_3.map((item) => {
                    return (
                      <Link
                        className={aStyle}
                        to={item.Url}
                        key={item.Url + "group3"}
                      >
                        {item.Label}
                      </Link>
                    );
                  })}
              </div>

              <div className="flex flex-col">
                <p className={titleStyle}>{group_title_4}</p>
                {group_links_4 &&
                  group_links_4.map((item) => {
                    return (
                      <Link
                        className={aStyle}
                        to={item.Url}
                        key={item.Url + "group4"}
                      >
                        {item.Label}
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* Half Get in Contact  */}
          <div className="">
            <div>
              {contact_title && <p className={titleStyle}>{contact_title}</p>}
              <p className="font-DM font-medium text-placeholder text-[14px] lg:text-[16px] leading-[24px] ">
                {contact_address}
              </p>
              <a
                href={contact_map_link?.Url}
                className="text-[14px] lg:text-[16px] leading-[24px] lg:leading-[34px] no-underline hover:underline hover:decoration-2 hover:underline-offset-2"
                target="_blank"
              >
                {contact_map_link?.Label}
              </a>
            </div>

            <div>
              <p className={titleStyle}>{contact_phone_subtitle}</p>
              <a
                className="font-bold text-purple text-[40px] leading-[50px] no-underline"
                href={`tel:${contact_phone_number}`}
              >
                {contact_phone_number}
              </a>
            </div>
          </div>
        </div>

        <hr className="mt-7 md:mt-10" />

        <div className="flex flex-col lg:flex-row gap-4 mt-5 md:mt-10">
          {group_links_bottom &&
            group_links_bottom.map((link) => {
              return (
                <Link
                  to={link.Url}
                  className="text-[14px] leading-[30px] lg:text-[16px] lg:leading-[26px] text-placeholder no-underline hover:text-purple hover:underline hover:decoration-2 hover:underline-offset-2 cursor-pointer"
                  key={link.Url + "groupbottom"}
                >
                  {link.Label}
                </Link>
              );
            })}
        </div>
      </MaxContent>
    </footer>
  );
};

export default Footer;
